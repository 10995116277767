import { Typography } from '@mui/material';
import Spacer from '../Spacer';
import Modal from '../Modal';

interface DeleteModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

  modalText: {
    title: string;
    primaryText: string;
    secondaryText?: string;
    toastMessage: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
  };
  onSave: () => void;
  activeData: any;
}

export const DataTableDeleteModal = ({
  open,
  setOpen,
  modalText,
  onSave,
  activeData,
}: DeleteModalProps) => (
  <Modal
    title={modalText?.title ?? ''}
    handleSave={onSave}
    data={activeData}
    open={open}
    setOpen={setOpen}
    primaryButtonText={modalText?.primaryButtonText}
    secondaryButtonText={modalText?.secondaryButtonText}>
    <Typography variant='p14' color='secondary.main' gutterBottom>
      {modalText?.primaryText}
    </Typography>
    <Spacer height='sm' />
    {modalText?.secondaryText && (
      <>
        <Typography variant='p14' color='secondary.main' gutterBottom>
          {modalText.secondaryText}
        </Typography>
        <Spacer height='sm' />
      </>
    )}
  </Modal>
);
