import { TeamData } from './../../models/TeamData';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { UserRole } from '../../models/UserData';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { TeamCompartments, teamStorageKey } from '../team';
import { GroupData } from '../../models/GroupData';
import { UserCompartments, userStorageKey } from '../user';
import { GroupCompartments, groupStorageKey } from '../group';
import { TeamMetricsApi } from '../../api/apis/TeamMetricsApi';
import { GroupMetricsData, TeamMetricsData } from '../../models/MetricsData';
import { GroupMetricsApi } from '../../api/apis/GroupMetricsApi';
import { GroupSelected } from '../../predicates/GroupSelected';
import { TeamSelected } from '../../predicates/TeamSelected';

export interface MetricsCompartments {
  groupMetrics: DataCompartmentOptions<GroupMetricsData | undefined>;
  teamMetrics: DataCompartmentOptions<TeamMetricsData | undefined>;
}

export const metricsStorageKey = 'data/metrics';

export const withMetricsData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<MetricsCompartments>({
      groupMetrics: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(GroupSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
            const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);

            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            if (roles.some(x => x.role?.includes(`GROUP_ADMIN_${group.id}`))) {
              const groupMetricsApi = container.get<GroupMetricsApi>(ApiKeys.GroupMetrics);
              const { data } = await groupMetricsApi.get();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      teamMetrics: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(TeamSelected) },
        source: new ConfiguredDataSource(async () => {
          try {
            const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
            const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
            const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);

            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            const team = (
              teamDataCache.findCompartment('team') as DataCompartment<TeamData>
            ).getData() as TeamData;
            if (
              roles.some(
                x =>
                  x.role?.includes(`TEAM_ADMIN_${team.id}`) ||
                  x.role?.includes(`GROUP_ADMIN_${group.id}`),
              )
            ) {
              const teamMetricsApi = container.get<TeamMetricsApi>(ApiKeys.TeamMetrics);
              const { data } = await teamMetricsApi.get();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(metricsStorageKey, cache);
  },
);
