import { DataCache, IRelayCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
export class ReloadCourses implements IRelayCommand {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
  ) {}

  async execute(): Promise<void> {
    await this.cache.reload('courses');
  }
}
