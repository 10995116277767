import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseApi } from '../../../api/apis/CourseApi';
import { CourseData } from '../../../models/CourseData';

export class RemoveCourse implements IDataCommand<number> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Course) private readonly api: CourseApi,
  ) {}

  async execute(id: number): Promise<void> {
    await this.cache.modify<CourseData[]>('courses', async courses => {
      await this.api.deleteCourse(id);
      return courses.filter(x => x.courseId !== id);
    });
  }
}
