import { Box, Drawer, Typography } from '@mui/material';
import { Spacer } from '.';

export interface RightDrawerProps extends React.PropsWithChildren {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  innerStyles?: React.CSSProperties;
  smallTitle?: string;
  title?: string;
  footer?: React.ReactNode;
  preventScroll?: boolean;
}

const RightDrawer: React.FC<RightDrawerProps> = props => {
  const { open, setOpen, smallTitle, title, preventScroll, footer } = props;

  const handleClose = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
  };

  return (
    <Drawer
      sx={{
        'zIndex': `${20} !important` /* if you touch this, make sure you haven't broken the edit org info drawer */,
        '& .MuiDrawer-paper': {
          overflow: preventScroll ? 'hidden' : 'auto',
        },
      }}
      anchor='right'
      open={open}
      onClose={handleClose}>
      <Box
        role='presentation'
        sx={{
          width: '700px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',

          ...props.innerStyles,
        }}>
        <Box
          sx={{
            padding: '20px',
            paddingBottom: footer ? '370px' : 0,
            flex: 1,
            overflow: preventScroll ? 'hidden' : 'auto',
            ...props.innerStyles,
          }}>
          {smallTitle ? (
            <>
              <Typography color='info.main' variant='p14'>
                {smallTitle}
              </Typography>
              <Spacer height='xxs' />
            </>
          ) : null}
          {title ? (
            <>
              <Typography color='secondary.main' variant='p30'>
                {title}
              </Typography>
              <Spacer height='xxs' />
            </>
          ) : null}
          {props.children}
        </Box>
        {footer && (
          <Box
            sx={{
              padding: '20px',
              borderTop: '1px solid',
              borderColor: 'divider',
              flexShrink: 0,
              backgroundColor: 'background.paper',
            }}>
            {footer}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default RightDrawer;
