import { Button, Card, CardContent, Grid, IconButton } from '@mui/material';
import { Svgs } from '../../../../../assets/svg';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { ContentInputProps } from './ContentInput';
import ContentTitleInput from './ContentTitleInput';
import {
  contentCopyKeyString,
  contentIconKeyString,
  contentTitleKeyString,
} from './ContentEditView';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import { useLoading } from '../../../../../hooks/useLoading';
import { useCommands } from '../../../../../helpers/useCommands';
import { AddOrUpdateLessonContent } from '../../../../../data/learning/commands/AddOrUpdateLessonContent';
import ContentCopyInput from './ContentCopyInput';
import RemoveContentModal from './RemoveContentModal';
import MediaEditView from '../../media/MediaEditView';
import { MediaData, MediaType } from '../../../../../models/MediaData';

interface ContentInputEditableProps extends ContentInputProps {
  removeContent: () => Promise<void>;
}

const ContentInputEditable = forwardRef<HTMLElement, ContentInputEditableProps>(
  ({ content, setEditing, disableDrag, removeContent, dragProps, dragHandleProps }, ref) => {
    const { setLoading } = useLoading();
    const commands = useCommands();
    const {
      getPreviewContentByKey,
      setPreviewContent,
      getPreviewMedia,
      setPreviewMedia,
      getPreviewInputError,
      setPreviewInputError,
    } = useLivePreviewContext();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const displayContent = useMemo(() => {
      const contentId = content.contentId;

      return {
        title: getPreviewContentByKey(`${contentTitleKeyString}/${contentId}`),
        contentCopy: getPreviewContentByKey(`${contentCopyKeyString}/${contentId}`),
        icon: getPreviewMedia(`${contentIconKeyString}/${contentId}`),
      };
    }, [content.contentId, getPreviewContentByKey, getPreviewMedia]);

    const onCancel = useCallback(async () => {
      setPreviewContent(`${contentTitleKeyString}/${content.contentId}`, content.title ?? '');
      setPreviewContent(`${contentCopyKeyString}/${content.contentId}`, content.contentCopy ?? '');
      setPreviewMedia(`${contentIconKeyString}/${content.contentId}`, content.icon ?? null);
      if (setEditing) {
        setEditing(undefined);
      }
    }, [
      content.contentCopy,
      content.contentId,
      content.title,
      content.icon,
      setEditing,
      setPreviewContent,
      setPreviewMedia,
    ]);

    const removeContentWithGuard = useCallback(async () => {
      const previewTitle = getPreviewContentByKey(`${contentTitleKeyString}/${content.contentId}`);
      const previewCopy = getPreviewContentByKey(`${contentCopyKeyString}/${content.contentId}`);
      if (
        (previewTitle?.length ?? 0) === 0 &&
        (previewCopy?.length ?? 0) === 0 &&
        !content.contentId
      ) {
        await onCancel();
      } else {
        setIsVisible(true);
      }
    }, [content.contentId, getPreviewContentByKey, onCancel]);

    const onSave = useCallback(async () => {
      try {
        setLoading(true);
        const { title, contentCopy, icon } = displayContent;
        const titleError = getPreviewInputError(`${contentTitleKeyString}/${content.contentId}`);
        const copyError = getPreviewInputError(`${contentCopyKeyString}/${content.contentId}`);

        if (!title || !contentCopy || titleError || copyError) {
          if (!title) {
            setPreviewInputError(`${contentTitleKeyString}/${content.contentId}`, 'Required');
          }
          if (!contentCopy) {
            setPreviewInputError(`${contentCopyKeyString}/${content.contentId}`, 'Required');
          }
          return;
        }

        await commands.execute(AddOrUpdateLessonContent, {
          ...content,
          title,
          contentCopy,
          icon,
        });

        if (setEditing) {
          setEditing(undefined);
        }
      } catch (error) {
        console.log('error');
      } finally {
        setLoading(false);
      }
    }, [
      commands,
      content,
      displayContent,
      getPreviewInputError,
      setEditing,
      setLoading,
      setPreviewInputError,
    ]);

    const handleMediaChange = (media: MediaData | null) => {
      setPreviewMedia(`${contentIconKeyString}/${content.contentId}`, media);
    };

    useEffect(() => {
      if (!content.contentId) {
        setPreviewMedia(`${contentIconKeyString}/undefined`, null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Grid ref={ref as any} {...dragProps}>
        <RemoveContentModal
          open={isVisible}
          setOpen={setIsVisible}
          removeContent={content.contentId ? removeContent : onCancel}
        />
        <Card id={content.contentId?.toString()} variant='ghost' color='primary'>
          <CardContent className='pad20'>
            <Grid display='flex' flexDirection='column' rowGap='30px'>
              <Grid display='flex' justifyContent='space-between' alignItems='center'>
                <IconButton
                  {...dragHandleProps}
                  style={{ padding: '0px', ...(disableDrag ? { cursor: 'default' } : undefined) }}
                  disableRipple>
                  <Svgs.IconGrip
                    className={disableDrag ? 'grip-disabled' : 'grip'}
                    style={{ opacity: '100 !important' }}
                  />
                </IconButton>

                <IconButton style={{ padding: '0px' }} onClick={removeContentWithGuard}>
                  <Svgs.IconDelete />
                </IconButton>
              </Grid>

              <ContentTitleInput
                editorKey={`${contentTitleKeyString}/${content.contentId}`}
                content={content.title}
              />

              <MediaEditView
                mediaType={MediaType.ICON}
                initialMedia={displayContent.icon ?? null}
                onMediaChange={handleMediaChange}
              />

              <ContentCopyInput
                editorKey={`${contentCopyKeyString}/${content.contentId}`}
                content={content.contentCopy}
              />

              <Grid display='flex' justifyContent='flex-end' flexGrow={1} columnGap='10px'>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={setEditing ? onCancel : undefined}>
                  Cancel
                </Button>
                <Button color='primary' onClick={onSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  },
);

export default ContentInputEditable;
