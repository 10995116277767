import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { organizationStorageKey, OrganizationCompartments } from '../data/organization';
import { Predicates } from './Types';
import { OrganizationData } from '../models/OrganizationData';

@predicate(Predicates.UserInitialized)
@predicate(Predicates.UserRolesInitialized)
export class OrganizationSelected implements Predicate {
  constructor(
    @injectDataCache(organizationStorageKey)
    private readonly organizationDataCache: DataCache<OrganizationCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.organizationDataCache
      .observe$<OrganizationData>('organization')
      .pipe(map(organizationData => typeof organizationData !== 'undefined'));
  }
}
