import { inject } from '@aesop-fables/containr';
import { Predicate } from '@aesop-fables/scrinium';
import { authContextKey } from '../services/authentication';
import type { IAuthenticationContext } from '../services/authentication';
import { Observable, map } from 'rxjs';

export class IsAuthenticated implements Predicate {
  constructor(@inject(authContextKey) private readonly authContext: IAuthenticationContext) {}

  createObservable(): Observable<boolean> {
    return this.authContext.isAuthenticated$.pipe(map(x => x === true));
  }
}
