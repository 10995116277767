import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { RightDrawer, Row } from '../../../../components';
import { useCommands } from '../../../../helpers/useCommands';
import { useLoading } from '../../../../hooks/useLoading';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { UploadMedia } from '../../../../data/learning/commands/UploadMedia';
import { useMediaLibrary } from '../../../../hooks/useMediaLibrary';
import { MediaData, MediaType } from '../../../../models/MediaData';
import MediaDrawer from './MediaDrawer';
import MediaPreview from './MediaPreview';
import { formatValidationErrors, ValidationMessages } from '../../../../helpers/validation';
import { AxiosError } from 'axios';

interface MediaEditViewProps {
  initialMedia: MediaData | null;
  mediaType: MediaType;
  onMediaChange: (media: MediaData | null) => void;
  variant?: 'light' | 'dark';
}

const MediaEditView: React.FC<MediaEditViewProps> = ({
  initialMedia,
  mediaType,
  variant = 'light',
  onMediaChange,
}) => {
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const media = useMediaLibrary();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<MediaData | null>(null);
  const [currentMedia, setCurrentMedia] = useState<MediaData | null>(initialMedia);
  const [uploadedPreview, setUploadedPreview] = useState<string | null>(null);

  useEffect(() => {
    setCurrentMedia(initialMedia);
  }, [initialMedia]);

  const handleFileUpload = async (file: File) => {
    try {
      setLoading(true);

      const uploadedMedia = await commands.execute(UploadMedia, {
        file,
        type: mediaType,
      });

      setToast(
        new Toast({
          message: 'Image uploaded!',
          severity: 'success',
          open: true,
        }),
      );

      const previewUrl = URL.createObjectURL(file);
      setUploadedPreview(previewUrl);
      if (uploadedMedia) {
        setSelectedMedia(uploadedMedia);
      }
    } catch (err) {
      const error = err as AxiosError;
      const message = (error.response?.data as Record<string, any>)?.message?.includes(
        'name already exists',
      )
        ? 'File already uploaded'
        : formatValidationErrors([error.message], ValidationMessages.FILE_REQUIREMENTS);

      setToast(
        new Toast({
          message,
          severity: 'error',
          open: true,
          autoHideDuration: 5000,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMediaSelection = async () => {
    if (!selectedMedia) return;

    try {
      setLoading(true);
      setCurrentMedia(selectedMedia);
      onMediaChange(selectedMedia);
      setDrawerOpen(false);
    } catch (err) {
      console.error('Failed to update content:', err);
      setToast(
        new Toast({
          message: 'Failed to update. Please try again.',
          severity: 'error',
          open: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMedia = () => {
    setCurrentMedia(null);
    onMediaChange(null);
  };

  useEffect(() => {
    return () => {
      if (uploadedPreview) {
        URL.revokeObjectURL(uploadedPreview);
      }
    };
  }, [uploadedPreview]);

  const isBackground = mediaType === MediaType.BACKGROUND;
  const prompt = `Choose ${isBackground ? 'a background image' : 'an icon'} (optional)`;
  const header = `Choose ${isBackground ? 'background image' : 'icon'}`;

  return (
    <Grid container flexDirection='column' rowGap='15px'>
      <Grid container alignItems='center' columnGap='5px'>
        <Typography variant='p16' color='info.main'>
          {prompt}
        </Typography>
      </Grid>

      <MediaPreview
        media={currentMedia}
        variant={variant}
        onRemove={handleRemoveMedia}
        onChoose={() => setDrawerOpen(true)}
      />

      <RightDrawer
        title={header}
        open={drawerOpen}
        setOpen={setDrawerOpen}
        preventScroll
        footer={
          <Row style={{ justifyContent: 'space-between' }}>
            <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
              Cancel
            </Button>
            <Button color='primary' onClick={handleMediaSelection} disabled={!selectedMedia}>
              Choose
            </Button>
          </Row>
        }>
        <MediaDrawer
          selectedMedia={selectedMedia}
          media={media?.filter(m => m.type === mediaType) ?? null}
          mediaType={mediaType}
          handleFileUpload={handleFileUpload}
          setSelectedMedia={setSelectedMedia}
        />
      </RightDrawer>
    </Grid>
  );
};

export default MediaEditView;
