import { QuizQuestion } from '../../../../../models/CourseData';
import {
  EnvironmentEnum,
  useLivePreviewContext,
} from '../../../../../components/LivePreviewContext';
import QuizOutputDesktop from './QuizOutputDesktop';
import QuizOutputMobile from './QuizOutputMobile';
import QuizQuestionReadonly from './QuizQuestionReadonly';

export interface QuizOutputProps {
  index: number;
  question: QuizQuestion;
  publishedQuestion?: QuizQuestion;
  empty?: boolean;
}

const QuizOutput: React.FC<QuizOutputProps> = ({ index, question, publishedQuestion, empty }) => {
  const { environment } = useLivePreviewContext();

  if (environment === EnvironmentEnum.COMPARE)
    return <QuizQuestionReadonly question={publishedQuestion ?? {}} />;

  if (environment === EnvironmentEnum.MOBILE)
    return <QuizOutputMobile index={index} question={question} empty={empty} />;

  return <QuizOutputDesktop index={index} question={question} empty={empty} />;
};

export default QuizOutput;
