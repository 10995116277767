import { TeamData } from './../../models/TeamData';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { UserData, UserRole } from '../../models/UserData';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { UserApi } from '../../api/apis/UserApi';
import { TeamCompartments, teamStorageKey } from '../team';
import { GroupData } from '../../models/GroupData';
import { UserCompartments, userStorageKey } from '../user';
import { GroupCompartments, groupStorageKey } from '../group';
import { GroupSelected } from '../../predicates/GroupSelected';
import { TeamSelected } from '../../predicates/TeamSelected';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface AdminCompartments {
  groupAdmins: DataCompartmentOptions<UserData[] | undefined>;
  teamAdmins: DataCompartmentOptions<UserData[] | undefined>;
  orgAdmins: DataCompartmentOptions<UserData[] | undefined>;
}

export const adminStorageKey = 'data/admin';

export const withAdminData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<AdminCompartments>({
      groupAdmins: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(GroupSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
            const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
            const userApi = container.get<UserApi>(ApiKeys.User);

            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            if (roles.some(x => x.role?.includes(`GROUP_ADMIN_${group.id}`))) {
              const { data } = await userApi.getGroupUsers();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      teamAdmins: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(TeamSelected) },
        source: new ConfiguredDataSource(async () => {
          try {
            const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
            const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
            const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
            const userApi = container.get<UserApi>(ApiKeys.User);

            const roles = (
              userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
            ).getData() as UserRole[];
            const group = (
              groupDataCache.findCompartment('group') as DataCompartment<GroupData>
            ).getData() as GroupData;
            const team = (
              teamDataCache.findCompartment('team') as DataCompartment<TeamData>
            ).getData() as TeamData;
            if (
              roles.some(
                x =>
                  x.role?.includes(`TEAM_ADMIN_${team.id}`) ||
                  x.role?.includes(`GROUP_ADMIN_${group.id}`),
              )
            ) {
              const { data } = await userApi.getTeamUsers();
              if (!data) {
                return undefined;
              }
              return data;
            }
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      orgAdmins: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(OrganizationSelected) },
        source: new ConfiguredDataSource(async () => {
          const userApi = container.get<UserApi>(ApiKeys.User);

          const { data } = await userApi.getOrgUsers();
          if (!data) {
            return undefined;
          }
          return data;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(adminStorageKey, cache);
  },
);
