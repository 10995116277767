import { HelpOutline } from '@mui/icons-material';
import { Grid, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { UploadDropZone } from '../../../../components';
import BulletedList from '../../../../components/BulletedList';
import { ValidationResult } from '../../../../helpers/validation';
import { MediaType } from '../../../../models/MediaData';
import { Bold } from '../../../../components/Bold';
interface MediaUploaderProps {
  handleFileUpload: (file: File) => void;
  mediaType: MediaType;
}

const MediaUploader: React.FC<MediaUploaderProps> = ({ handleFileUpload, mediaType }) => {
  const theme = useTheme();

  return (
    <>
      <Grid container alignItems='center' columnGap='5px'>
        <Typography variant='p16Bold' color='info.main'>
          Upload a new image
        </Typography>
        <Tippy
          placement='right'
          content={<UploadImageHelpText mediaType={mediaType} />}
          theme='tippy-rounded'>
          <HelpOutline style={{ color: theme.palette.info.main }} />
        </Tippy>
      </Grid>
      <UploadDropZone
        setData={file => {
          if (file instanceof File) {
            handleFileUpload(file);
          }
        }}
        validate={file => validateDimensions(file, mediaType)}
        uploadTypes={[mediaType === MediaType.ICON ? 'icon' : 'background']}
        sx={{
          'width': '100%',
          'backgroundColor': 'transparent',
          'borderRadius': '10px',
          'border': '2px dashed #9eacb0',
          '&:hover': {
            backgroundColor: '#e7f3f9',
          },
        }}
        dropzoneContent={
          <Typography variant='p16' color='secondary.dark'>
            Drag and drop your image here, or <br />
            <Bold style={{ color: theme.palette.primary.main }}>choose an image to upload</Bold>.
          </Typography>
        }
      />
    </>
  );
};

const UploadImageHelpText = ({ mediaType }: { mediaType: MediaType }) => {
  const requirements =
    mediaType === MediaType.BACKGROUND
      ? [
          'Must be a PNG, JPG or SVG file',
          'Aspect ratio must be 3:1 (width must be 3 times height)',
          'Recommended dimensions are 1350px (w) by 450px (h)',
          'Minimum dimensions are 900px (w) by 300px (h)',
          'Maximum file size is 1MB',
        ]
      : [
          'Must be a PNG, JPG or SVG file',
          'Aspect ratio must be 1:1 (width must equal height)',
          'Minimum dimensions are 150px by 150px',
          'Maximum file size is 1MB',
        ];

  return (
    <Grid container rowGap='5px'>
      <Typography variant='p18Bold' color='secondary.dark'>
        Image upload requirements
      </Typography>
      <BulletedList children={requirements} />
    </Grid>
  );
};

const validateDimensions = (file: File, mediaType: MediaType): Promise<ValidationResult> => {
  return new Promise(resolve => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(objectUrl);
      const errors: string[] = [];
      const aspectRatio = img.width / img.height;

      if (mediaType === MediaType.ICON) {
        if (img.width < 150 || img.height < 150) {
          errors.push(
            `Image dimensions (${img.width}px by ${img.height}px) are too small. Minimum size is 150px by 150px.`,
          );
        }

        if (Math.abs(aspectRatio - 1) > 0.01) {
          errors.push(
            `Image must be square (1:1 aspect ratio). Current ratio is ${aspectRatio.toFixed(
              2,
            )}:1.`,
          );
        }
      } else {
        if (img.width < 900 || img.height < 300) {
          errors.push(
            `Image dimensions (${img.width}px by ${img.height}px) are too small. Minimum size is 900px by 300px.`,
          );
        }

        if (Math.abs(aspectRatio - 3) > 0.01) {
          errors.push(
            `Image must have 3:1 aspect ratio. Current ratio is ${aspectRatio.toFixed(2)}:1.`,
          );
        }
      }

      resolve({
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined,
      });
    };

    img.src = objectUrl;
  });
};

export default MediaUploader;
