import {
  DataCache,
  IDataCommand,
  appendCommandMiddleware,
  injectDataCache,
} from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, LessonData } from '../../../models/CourseData';
import { LessonApi } from '../../../api/apis/LessonApi';
import { LearningServices } from '../LearningServiceModule';

@appendCommandMiddleware(LearningServices.ReloadCourses)
export class UnpublishLesson implements IDataCommand<LessonData, LessonData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Lesson) private readonly api: LessonApi,
  ) {}

  async execute(lesson: LessonData): Promise<LessonData> {
    let response: LessonData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (lesson.unitId && lesson.lessonId) {
        const contents = lesson.contents;
        const quiz = lesson.quiz;
        const { data: updatedLesson } = await this.api.unpublishLesson(lesson);
        updatedLesson.contents = contents;
        updatedLesson.quiz = quiz;
        response = updatedLesson;

        // no more logic bc we reload courses
      }

      return courses;
    });

    return response as LessonData;
  }
}
