import LivePreviewEditor, {
  LivePreviewEditorProps,
} from '../../../../../components/LivePreviewEditor';

const formatHtml = (html: string) => {
  const formattedHtml = `<div style="font-size: 16px; font-family: 'Roboto'; color: #1e2969; overflow-wrap: anywhere;">${html}</div>`;
  return formattedHtml;
};

const ContentCopyInput: React.FC<LivePreviewEditorProps> = ({ editorKey, content }) => {
  return (
    <LivePreviewEditor
      editorKey={editorKey}
      label='Content copy'
      content={content}
      placeholder='Add body copy'
      formatHtml={formatHtml}
      characterLimit={3500}
    />
  );
};

export default ContentCopyInput;
