import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { GroupFeatureMapApi } from '../../api/apis/GroupFeatureMapApi';
import { GroupSelected } from '../../predicates/GroupSelected';

export interface GroupFeatureMapCompartments {
  groupFeatureMap: DataCompartmentOptions<GroupFeatureMapData | undefined>;
}

export const groupFeatureMapStorageKey = 'data/groupFeatureMap';

export const withGroupFeatureMapData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<GroupFeatureMapCompartments>({
      groupFeatureMap: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(GroupSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const groupFeatureMapApi = container.get<GroupFeatureMapApi>(ApiKeys.GroupFeatureMap);
            const { data } = await groupFeatureMapApi.get();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(groupFeatureMapStorageKey, cache);
  },
);
