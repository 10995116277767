import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import {
  CourseData,
  LessonData,
  PublicationStatusEnum,
  UnitData,
} from '../../../models/CourseData';
import { LessonApi } from '../../../api/apis/LessonApi';
import { findLessonSource, updateCourses } from './utils';

export class CreateLessonDraft implements IDataCommand<LessonData, LessonData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Lesson) private readonly api: LessonApi,
  ) {}

  async execute(lesson: LessonData): Promise<LessonData> {
    let response: LessonData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex } = findLessonSource(lesson, courses);
      if (courseIndex !== -1 && typeof unitIndex !== 'undefined') {
        if (lesson.lessonId) {
          const newUnits = [...(courses[courseIndex].units as UnitData[])];
          const newUnit = newUnits[unitIndex];
          // if lesson is published, explicitly create a draft
          if (lesson.status === PublicationStatusEnum.PUBLISHED && lesson.lessonId) {
            const { data: draftLesson } = await this.api.createDraft(lesson.lessonId);
            response = draftLesson;
            newUnit.lessons?.push(draftLesson);
          }
          const newCourses = updateCourses(newUnit, courses);

          return newCourses;
        }
      }

      return courses;
    });

    return response as LessonData;
  }
}
