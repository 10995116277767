import { Card, CardContent, Grid, Link, useTheme } from '@mui/material';
import { Svgs } from '../assets/svg';

interface AddButtonProps {
  content: string;
  onClick: () => void;
}

const AddButton: React.FC<AddButtonProps> = ({ onClick, content }) => {
  const theme = useTheme();

  return (
    <Card variant='ghost' color='primary'>
      <CardContent className='pad10'>
        <Grid
          onClick={onClick}
          display='flex'
          justifyContent='center'
          columnGap='10px'
          sx={{ '&:hover': { cursor: 'pointer' } }}>
          <Svgs.IconAdd fill={theme.palette.secondary.hover} />
          <Link variant='p16SemiBold'>{content}</Link>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddButton;
