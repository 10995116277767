import {
  DataCache,
  IDataCommand,
  appendCommandMiddleware,
  injectDataCache,
} from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, UnitData } from '../../../models/CourseData';
import { UnitApi } from '../../../api/apis/UnitApi';
import { LearningServices } from '../LearningServiceModule';

@appendCommandMiddleware(LearningServices.ReloadCourses)
export class UnpublishUnit implements IDataCommand<UnitData, UnitData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Unit) private readonly api: UnitApi,
  ) {}

  async execute(unit: UnitData): Promise<UnitData> {
    let response: UnitData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (unit.sourceCourseId && unit.unitId) {
        const lessons = unit.lessons;
        const { data: updatedUnit } = await this.api.unpublishUnit(unit);
        updatedUnit.lessons = lessons;
        response = updatedUnit;

        // no more logic since we reload the course
      }
      return courses;
    });

    return response as UnitData;
  }
}
