import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { QuizData } from '../../models/CourseData';

export class QuizApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getQuiz(id: number): Promise<AxiosResponse<QuizData>> {
    return this.axios.get(`quiz/${id}`);
  }

  getQuizByLessonId(lessonId: number): Promise<AxiosResponse<QuizData>> {
    return this.axios.get(`quiz/by-lesson?lessonId=${lessonId}`);
  }

  createQuiz(quiz: QuizData): Promise<AxiosResponse<QuizData>> {
    return this.axios.post('quiz', quiz);
  }

  publishQuiz(id: number): Promise<AxiosResponse<QuizData>> {
    return this.axios.get(`quiz/publish/${id}`);
  }

  updateQuiz(quiz: QuizData): Promise<AxiosResponse<QuizData>> {
    return this.axios.patch('quiz', quiz);
  }

  deleteQuiz(id: number): Promise<AxiosResponse<QuizData>> {
    return this.axios.delete(`quiz/${id}`);
  }
}
