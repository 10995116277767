import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, UnitData } from '../../../models/CourseData';
import { UnitApi } from '../../../api/apis/UnitApi';
import { findUnitSource, updateCourses } from './utils';

interface UpdateUnitPositionParams {
  unit: UnitData;
  orderedUnits: UnitData[];
}

export class UpdateUnitPosition implements IDataCommand<UpdateUnitPositionParams, UnitData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Unit) private readonly api: UnitApi,
  ) {}

  async execute({ unit, orderedUnits }: UpdateUnitPositionParams): Promise<UnitData> {
    let response: UnitData | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (unit.unitId && unit.position) {
        const courseIndex = findUnitSource(unit, courses);

        if (courseIndex !== -1) {
          const newCourses = [...courses];
          const course = newCourses[courseIndex];

          const { data: updatedUnits } = await this.api.updateUnitOrder(
            orderedUnits,
            course.courseId as number,
          );

          course.units = course.units?.map(x => {
            const updatedUnit = updatedUnits.find(y => y.originalUnitId === x.originalUnitId);
            if (typeof updatedUnit !== 'undefined') {
              return { ...x, position: updatedUnit.position };
            }
            return x;
          });

          response = course.units?.find(x => x.unitId === unit.unitId);

          return newCourses;
        }
      }
      return courses;
    });

    return response as UnitData;
  }
}
