import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { UserApi } from '../../../api/apis/UserApi';
import { AdminCompartments, adminStorageKey } from '../../admin';

export class ChangePrimaryBillingAdmin implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const userApi = container.get<UserApi>(ApiKeys.User);
    await userApi.changePrimaryBillingUser(data);

    const cache = storage.retrieve<AdminCompartments>(adminStorageKey);
    await cache.reload('orgAdmins');
  }
}
