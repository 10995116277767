import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  DataCompartmentOptions,
  ConfiguredDataSource,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { firstValueFrom } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationCompartments, organizationStorageKey } from '../organization';
import { CustomerOrganizationApi } from '../../api/apis/CustomerOrganizationApi';
import { CustomerOrganizationData } from '../../models/CustomerOrganizationData';
import { OrganizationData } from '../../models/OrganizationData';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface CustomerOrganizationCompartments {
  customerOrganization: DataCompartmentOptions<CustomerOrganizationData | undefined>;
}

export const customerOrganizationStorageKey = 'data/customerOrganization';

export const withCustomerOrganizationData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<CustomerOrganizationCompartments>({
      customerOrganization: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const customerOrganizationApi = container.get<CustomerOrganizationApi>(
              ApiKeys.CustomerOrganization,
            );
            const organizationDataCache =
              appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
            const organization = await firstValueFrom(
              organizationDataCache.observe$<OrganizationData>('organization'),
            );
            if (organization.id) {
              const { data } = await customerOrganizationApi.getCustomerOrganization();
              return data;
            }
            return undefined;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(customerOrganizationStorageKey, cache);
  },
);
