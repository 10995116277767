import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, LessonContent, UnitData } from '../../../models/CourseData';
import { LessonContentApi } from '../../../api/apis/LessonContentApi';
import { findLessonContentSource, updateCourses } from './utils';

interface UpdateContentPositionParams {
  content: LessonContent;
  orderedContents: LessonContent[];
}

export class UpdateContentPosition
  implements IDataCommand<UpdateContentPositionParams, LessonContent>
{
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.LessonContent) private readonly api: LessonContentApi,
  ) {}

  async execute({ content, orderedContents }: UpdateContentPositionParams): Promise<LessonContent> {
    let response: LessonContent | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (content.lessonId && content.contentId && content.position) {
        const { courseIndex, unitIndex, lessonIndex } = findLessonContentSource(content, courses);
        if (
          typeof courseIndex !== 'undefined' &&
          typeof unitIndex !== 'undefined' &&
          typeof lessonIndex !== 'undefined'
        ) {
          const newUnits = [...(courses[courseIndex].units as UnitData[])];
          const newUnit = newUnits[unitIndex];
          if (newUnit.lessons) {
            const newLessons = [...newUnit.lessons];
            const newLesson = newLessons[lessonIndex];
            const { data: updatedContents } = await this.api.updateLessonContentOrder(
              orderedContents,
              content.lessonId,
            );
            newLesson.contents = newLesson.contents?.map(x => {
              const updatedContent = updatedContents.find(y => y.contentId === x.contentId);
              if (typeof updatedContent !== 'undefined') {
                return { ...x, position: updatedContent.position };
              }

              return x;
            });
            updateCourses(newUnit, [...courses]);
          }
        }
      }

      return courses;
    });

    return response as LessonContent;
  }
}
