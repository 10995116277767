import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Column } from '../components';
import { Main } from '../dashboard';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData } from '../models/SignUpData';
import { SignUpWizard, signUpWizardKey } from '../services/signUp';
import { CancellationPolicy } from '../sign-up';
import { OnboardingRoutes } from '.';
import { PaymentCompartments, paymentStorageKey } from '../data/payment';
import { StripeSessionStatus } from '../api/apis/AdminPaymentApi';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { OrganizationData } from '../models/OrganizationData';
import MultiTierAdminRoutes from './MultiTierAdminRoutes';
import DrillDownRoutes from './DrillDownRoutes';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { GroupCompartments, groupStorageKey } from '../data/group';
import { TeamCompartments, teamStorageKey } from '../data/team';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../data/groupFeatureMap';
import { PaymentTypeEnum, PricebookBundle } from '../models/PricebookData';
import Congrats from '../sign-up/Congrats';

export const Loading = () => {
  return (
    <Column style={{ margin: '15px 100px', alignItems: 'center' }}>
      <CircularProgress />
    </Column>
  );
};

const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appStorage = useAppStorage();
  const [needsRedirect, setNeedsRedirect] = useState<boolean>(true);
  const [selectingOrg, setSelectingOrg] = useState<boolean>(false);
  const [selectingTeam, setSelectingTeam] = useState<boolean>(false);
  const [selectingGroup, setSelectingGroup] = useState<boolean>(false);

  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);

  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);

  const signUpInitialized = useObservable(signUpDataCache.initialized$);
  const orgSignUpStateInitialized = useObservable(
    signUpDataCache.findCompartment('orgSignUpState').initialized$,
  );
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const paymentInitialized = useObservable(
    paymentDataCache.findCompartment('stripeSessionStatus').initialized$,
  );
  const stripeSessionStatus = useObservable(
    paymentDataCache.observe$<StripeSessionStatus>('stripeSessionStatus'),
  );
  const paidStatus = useObservable(paymentDataCache.observe$<boolean>('paidStatus'));
  const allOrganizations = useObservable(
    organizationDataCache.observe$<OrganizationData[]>('allOrganizations'),
  );
  const organizationData = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const organizationInitialized = useObservable(
    organizationDataCache.findCompartment('organization').initialized$,
  );
  const selectedOrganization = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const orgsAreLoaded = useObservable(
    organizationDataCache.findCompartment('allOrganizations').initialized$,
  );
  const teamsAreLoaded = useObservable(teamDataCache.findCompartment('allTeams').initialized$);
  const groupsAreLoaded = useObservable(groupDataCache.findCompartment('allGroups').initialized$);
  const groupFeatureMapInitialized = useObservable(
    groupFeatureMapDataCache.findCompartment('groupFeatureMap').initialized$,
  );
  const paymentMethods = useObservable(
    paymentDataCache.observe$<PaymentMethodData[]>('paymentMethods'),
  );
  const checkedOut =
    orgSignUpState?.state?.checkedOut && JSON.parse(orgSignUpState?.state?.checkedOut);
  const selectedPricebook: PricebookBundle | undefined =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state?.pricebook);
  const isADP = selectedPricebook?.prices.some(price => price.processor.name === 'ADP');
  const autoPay = organizationData?.automaticPayment;
  const prePaid = selectedPricebook?.pricebook?.paymentType === PaymentTypeEnum.Prepaid;
  const meteredPayment = selectedPricebook?.pricebook?.paymentType === PaymentTypeEnum.Metered;

  const pending = useMemo(() => {
    return isADP || !autoPay
      ? false
      : prePaid && stripeSessionStatus
      ? paidStatus === false
      : meteredPayment && paymentMethods?.length === 0 && !checkedOut;
  }, [
    isADP,
    autoPay,
    prePaid,
    stripeSessionStatus,
    paidStatus,
    meteredPayment,
    paymentMethods,
    checkedOut,
  ]);

  const drillDownWizardInitialized = useObservable(drillDownWizard.initialized$) ?? false;
  const origin = useObservable(drillDownWizard.origin$);
  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const tier = useObservable(drillDownWizard.tier$);
  const numOrgs = allOrganizations?.length ?? 0;

  useEffect(() => {
    if (location.pathname === '/cancellation-policy') {
      setNeedsRedirect(false);
      return;
    }

    if (!drillDownWizardInitialized) return;

    if (groupFeatureMapInitialized && !tier) {
      if (!location.pathname.includes('onboarding')) {
        setNeedsRedirect(true);
      }
      if (needsRedirect && orgSignUpStateInitialized) {
        if (!orgSignUpState?.id) {
          const step = signUpWizard.resumeOnboarding(orgSignUpState?.lastCompletedStep ?? '');
          navigate(step?.route ?? '', { replace: true });
          setNeedsRedirect(false);
          return;
        } else if (paymentInitialized) {
          if (!stripeSessionStatus && !orgSignUpState?.onboardingComplete) {
            const step = signUpWizard.resumeOnboarding(orgSignUpState?.lastCompletedStep ?? '');
            navigate(step?.route ?? '', { replace: true });
          }
          setNeedsRedirect(false);
          return;
        }
      }
    }

    if (tier === AdminTierTypeEnum.Group) {
      if (needsRedirect) {
        if (!groupsAreLoaded) return;

        if (origin === tier) {
          navigate('/drill-down/choose-group');
          setSelectingGroup(true);
          setNeedsRedirect(false);
          return;
        } else {
          navigate('/controller/teams', { replace: true });
          setNeedsRedirect(false);
          return;
        }
      }
    }

    if (tier === AdminTierTypeEnum.Team) {
      if (needsRedirect) {
        if (!teamsAreLoaded) return;

        if (origin === tier) {
          navigate('/drill-down/choose-team');
          setSelectingTeam(true);
          setNeedsRedirect(false);
          return;
        } else {
          navigate('/controller/teams', { replace: true });
          setNeedsRedirect(false);
          return;
        }
      }
    }

    if (tier === AdminTierTypeEnum.Organization) {
      if (needsRedirect && organizationInitialized) {
        if (!orgsAreLoaded) {
          return;
        }
        if (origin === tier) {
          navigate('/drill-down/choose-organization');
          setSelectingOrg(true);
          setNeedsRedirect(false);
          return;
        } else {
          navigate('/controller/organizations', { replace: true });
          setSelectingOrg(true);
          setNeedsRedirect(false);
        }
        return;
      }
    }

    if (tier === AdminTierTypeEnum.Root) {
      if (needsRedirect && signUpInitialized && organizationInitialized) {
        if (!orgsAreLoaded) {
          return;
        }

        if (!selectedOrganization) {
          navigate('/drill-down/choose-organization', { replace: true });
          setSelectingOrg(true);
          setNeedsRedirect(false);
          return;
        }

        if (!orgSignUpState?.id) {
          const step = signUpWizard.resumeOnboarding(orgSignUpState?.lastCompletedStep ?? '');
          navigate(step?.route ?? '', { replace: true });
          setNeedsRedirect(false);
          return;
        } else if (paymentInitialized) {
          if (!pending && !orgSignUpState.onboardingComplete) {
            const step = signUpWizard.resumeOnboarding(orgSignUpState?.lastCompletedStep ?? '');
            navigate(step?.route ?? '', { replace: true });
          }
          setNeedsRedirect(false);
          return;
        }
      }
    }
  }, [
    orgsAreLoaded,
    selectedOrganization,
    location.pathname,
    needsRedirect,
    orgSignUpStateInitialized,
    orgSignUpState?.id,
    orgSignUpState?.lastCompletedStep,
    orgSignUpState?.onboardingComplete,
    signUpInitialized,
    paymentInitialized,
    signUpWizard,
    stripeSessionStatus,
    selectingOrg,
    navigate,
    organizationInitialized,
    selectingTeam,
    selectingGroup,
    numOrgs,
    tier,
    drillDownWizard,
    drillDownWizardInitialized,
    highestAccess,
    groupsAreLoaded,
    teamsAreLoaded,
    origin,
    groupFeatureMapInitialized,
    pending,
  ]);

  return (
    <Routes>
      {/* Cancellation policy does not have header */}
      <Route path='/cancellation-policy' element={<CancellationPolicy />} />
      {/* Congrats screen does not have BG */}
      <Route
        path='/congrats'
        element={
          typeof pending === 'undefined' ? (
            <Loading />
          ) : pending ? (
            <Navigate to='/' replace />
          ) : (
            <Congrats />
          )
        }
      />

      <Route path='/onboarding/*' element={<OnboardingRoutes />} />

      <Route path='/drill-down/*' element={needsRedirect ? <Loading /> : <DrillDownRoutes />} />

      <Route
        path='/controller/*'
        element={needsRedirect ? <Loading /> : <MultiTierAdminRoutes />}
      />

      {/* Screens off dashboard that do have BGLeft: */}
      <Route path='/*' element={needsRedirect ? <Loading /> : <Main />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
