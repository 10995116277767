import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export interface LmsPermission {
  ipmUserId?: number;
  lmsPermission?: 'READ' | 'WRITE';
}

export class LmsPermissionApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<LmsPermission>> {
    return this.axios.get('lms-permission');
  }
}
