import { StarterKitOptions } from '@tiptap/starter-kit';
import LivePreviewEditor, {
  LivePreviewEditorProps,
} from '../../../../../components/LivePreviewEditor';

export const formatQuestionHtml = (html: string) => {
  const formattedHtml = `<div style="font-size: 18px; font-family: 'Roboto'; font-weight: bold; color: #1e2969; overflow-wrap: anywhere;">${html}</div>`;
  return formattedHtml;
};

const starterKitOptions: Partial<StarterKitOptions> = { bold: false };

const QuizQuestionInput: React.FC<LivePreviewEditorProps> = ({ editorKey, content }) => {
  return (
    <LivePreviewEditor
      editorKey={editorKey}
      label='Question'
      content={content}
      placeholder='Add a question'
      formatHtml={formatQuestionHtml}
      starterKitOptions={starterKitOptions}
      characterLimit={500}
    />
  );
};

export default QuizQuestionInput;
