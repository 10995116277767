import { Grid } from '@mui/material';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import { useMemo } from 'react';
import {
  contentCopyKeyString,
  contentIconKeyString,
  contentTitleKeyString,
} from './ContentEditView';
import { ContentOutputProps } from './ContentOutput';
import ContentOutputEmpty from './ContentOutputEmpty';
import MediaImage from '../../../../../components/MediaImage';

const ContentOutputMobile: React.FC<ContentOutputProps> = ({ content, empty }) => {
  const { getPreviewContentByKey } = useLivePreviewContext();
  const previewTitle = useMemo(
    () => getPreviewContentByKey(`${contentTitleKeyString}/${content.contentId}`),
    [content.contentId, getPreviewContentByKey],
  );
  const previewCopy = useMemo(
    () => getPreviewContentByKey(`${contentCopyKeyString}/${content.contentId}`),
    [content.contentId, getPreviewContentByKey],
  );
  const previewIcon = useMemo(() => {
    const iconStr = getPreviewContentByKey(`${contentIconKeyString}/${content.contentId}`);
    if (!iconStr) return null;

    try {
      return iconStr ? JSON.parse(iconStr) : content.icon;
    } catch {
      return content.icon;
    }
  }, [content.contentId, content.icon, getPreviewContentByKey]);

  // should also check if there is an icon once that's wired up
  if (!previewTitle && !previewCopy && empty) return <ContentOutputEmpty />;

  if (!previewTitle && !previewCopy) return undefined;

  return (
    <Grid display='flex' justifyContent='center'>
      <Grid
        display='flex'
        flexDirection='column'
        rowGap='20px'
        pt='10px'
        alignItems='center'
        maxWidth='300px'>
        {typeof previewTitle !== 'undefined' && previewTitle.length > 0 && (
          <div
            style={{ width: '100%', textAlign: 'center' }}
            dangerouslySetInnerHTML={{ __html: previewTitle }}
          />
        )}
        {previewIcon && (
          <MediaImage
            media={previewIcon}
            imgStyle={{
              maxWidth: '80px',
              maxHeight: '80px',
            }}
          />
        )}
        {typeof previewCopy !== 'undefined' && previewCopy.length > 0 && (
          <div dangerouslySetInnerHTML={{ __html: previewCopy }} />
        )}
      </Grid>
    </Grid>
  );
};

export default ContentOutputMobile;
