import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import { useMemo } from 'react';
import { QuizOutputProps } from './QuizOutput';
import { quizQuestionKeyString } from './QuizEditView';
import QuizOutputEmpty from './QuizOutputEmpty';
import { formatQuestionHtml } from './QuizQuestionInput';
import { useQuizQuestionContext } from './QuizQuestionContext';
import { Svgs } from '../../../../../assets/svg';
import { formatAnswerHtml } from './QuizAnswerInput';

const QuizOutputDesktop: React.FC<QuizOutputProps> = ({ index, question, empty }) => {
  const { getPreviewContentByKey } = useLivePreviewContext();
  const { activeAnswerKeys, correctAnswerKeys } = useQuizQuestionContext();

  const previewQuestion = useMemo(
    () => getPreviewContentByKey(`${quizQuestionKeyString}/${question.questionId}`),
    [getPreviewContentByKey, question.questionId],
  );

  const previewAnswers = useMemo(
    () => [...activeAnswerKeys].map(x => getPreviewContentByKey(x)),
    [activeAnswerKeys, getPreviewContentByKey],
  );

  if (
    !previewQuestion &&
    previewAnswers.findIndex(x => typeof x === 'string' && x.length > 0) === -1 &&
    empty
  )
    return <QuizOutputEmpty />;

  if (!previewQuestion && previewAnswers.findIndex(x => typeof x === 'string') === -1)
    return undefined;

  return (
    <Grid display='flex' flexDirection='column' rowGap='20px'>
      {typeof previewQuestion !== 'undefined' && previewQuestion.length > 0 && (
        <Grid display='flex' flexDirection='column' rowGap='10px'>
          <Grid display='flex' justifyContent='start' columnGap='5px' width='100%'>
            <div
              style={{ minWidth: 'fit-content' }}
              dangerouslySetInnerHTML={{ __html: formatQuestionHtml(`${index + 1}.`) }}
            />
            <div dangerouslySetInnerHTML={{ __html: previewQuestion }} />
          </Grid>
          {[...correctAnswerKeys].length > 1 && (
            <Typography fontFamily='Roboto' fontSize='16px' color='#1b7690'>
              Select all that apply
            </Typography>
          )}
        </Grid>
      )}
      {[...activeAnswerKeys].map((key, keyIndex) => {
        const previewAnswer = getPreviewContentByKey(key);
        const correct = correctAnswerKeys.has(key);
        if (!previewAnswer) return null;
        return (
          <Card
            key={key}
            variant='outlined'
            style={
              correct
                ? { backgroundColor: '#e8f1f3', borderColor: 'transparent' }
                : { borderColor: '#e8f1f3' }
            }>
            <CardContent className='pad20'>
              <Grid
                display='grid'
                alignItems='center'
                {...(correct
                  ? { gridTemplateColumns: 'auto auto', columnGap: '10px' }
                  : { gridTemplateColumns: 'auto ' })}>
                <div
                  style={{ flexGrow: 1, display: 'flex', columnGap: '5px', alignItems: 'start' }}
                  dangerouslySetInnerHTML={{
                    __html: `${formatAnswerHtml(
                      '<strong>' + String.fromCharCode(97 + keyIndex).toUpperCase() + '.</strong>',
                    )}${previewAnswer}`,
                  }}
                />
                {correct && <Svgs.IconCheckmark style={{ justifySelf: 'end' }} />}
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Grid>
  );
};

export default QuizOutputDesktop;
