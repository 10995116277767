import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData } from '../../../models/CourseData';
import { CourseApi } from '../../../api/apis/CourseApi';

export class PublishCourse implements IDataCommand<CourseData, CourseData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Course) private readonly api: CourseApi,
  ) {}

  async execute(course: CourseData): Promise<CourseData> {
    let response: CourseData | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (course.courseId) {
        const newCourses = [...courses];
        const courseIndex = newCourses.findIndex(x => x.courseId === course.courseId);
        if (courseIndex !== -1) {
          const units = course.units;
          const { data: publishedCourse } = await this.api.publishCourse(course);
          publishedCourse.units = units;
          response = publishedCourse;

          newCourses[courseIndex] = publishedCourse;

          return newCourses;
        }
      }
      return courses;
    });

    return response as CourseData;
  }
}
