import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { PricebookBundle } from '../../models/PricebookData';
import { PricebookCompartments, pricebookStorageKey } from '../pricebook';
import { PricebooksSelected } from '../../predicates/PricebooksSelected';

export interface ProductTypeCompartments {
  productType: DataCompartmentOptions<string>;
  paidBy: DataCompartmentOptions<string | undefined>;
}

export const productTypeStorageKey = 'data/productType';

export const withProductTypeData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<ProductTypeCompartments>({
      productType: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(PricebooksSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const pricebookDataCache =
            appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
          const pricebookSelections = (
            pricebookDataCache.findCompartment('pricebookSelections') as DataCompartment<
              PricebookBundle[]
            >
          ).getData() as PricebookBundle[];

          return pricebookSelections[0].pricebook.productType;
        }),
        defaultValue: '',
      },
      paidBy: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(PricebooksSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const pricebookDataCache =
            appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
          const pricebookSelections = (
            pricebookDataCache.findCompartment('pricebookSelections') as DataCompartment<
              PricebookBundle[]
            >
          ).getData() as PricebookBundle[];

          if (pricebookSelections.length > 0) {
            return pricebookSelections[0]?.pricebook.paidBy;
          }
          return undefined;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(productTypeStorageKey, cache);
  },
);
