import { ReactNode, useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Svgs } from '../assets/svg';

interface ClickableListProps<T> {
  items: T[];
  initialSelectedId?: string | number;
  renderItem: (item: T) => ReactNode;
  getItemKey: (item: T) => any;
  onItemClick: (val: any) => Promise<boolean>;
  divider?: boolean;
  size?: 'compact' | 'standard';
  selectionIndicator?: 'checkmark' | 'none';
}

function ClickableList<T>({
  items,
  initialSelectedId,
  renderItem,
  getItemKey,
  onItemClick,
  divider = false,
  size = 'standard',
  selectionIndicator = 'none',
}: ClickableListProps<T>) {
  const padding =
    size === 'compact'
      ? {
          list: { py: 0.5 },
          item: { p: 0 },
          button: { px: 1, py: 0.25 },
        }
      : {
          list: { py: 1 },
          item: { py: 0 },
          button: { px: 2, py: 1 },
        };
  const [selectedItemId, setSelectedItemId] = useState(initialSelectedId);
  const [loadingItemId, setLoadingItemId] = useState<string | number | null>(null);

  const handleItemClick = async (itemId: string | number) => {
    if (loadingItemId !== null) return;

    setLoadingItemId(itemId);
    try {
      const success = await onItemClick(itemId);
      if (success) {
        setSelectedItemId(itemId);
      }
    } catch (error) {
      console.error('Error selecting item:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  return (
    <List sx={{ width: '100%', ...padding.list }}>
      {items.map(item => {
        const itemId = getItemKey(item);
        const isSelected = selectedItemId === itemId;
        const isLoading = loadingItemId === itemId;

        return (
          <ListItem key={itemId} className='clickableListItem' divider={divider} sx={padding.item}>
            <ListItemButton onClick={() => handleItemClick(itemId)} sx={padding.button}>
              <ListItemText
                primary={
                  <Typography
                    variant='p16'
                    color='secondary.dark'
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    {renderItem(item)}
                    {isSelected && !isLoading && selectionIndicator !== 'none' && (
                      <Svgs.IconCheckmark />
                    )}
                    {isLoading && selectionIndicator !== 'none' && <CircularProgress size={20} />}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default ClickableList;
