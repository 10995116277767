import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { quizAnswerKeyString } from './QuizEditView';
import { QuizQuestion } from '../../../../../models/CourseData';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';

export interface IQuizQuestionContext {
  activeAnswerKeys: Set<string>;
  addAnswer: (answerId?: number) => void;
  allOfTheAboveKey?: string;
  addAllOfTheAbove?: (answerId?: number) => void;
  noneOfTheAboveKey?: string;
  addNoneOfTheAbove?: (answerId?: number) => void;
  removeAnswer: (answerKey: string) => void;
  resetAnswers: () => void;
  correctAnswerKeys: Set<string>;
  toggleCorrectAnswer: (answerKey: string) => void;
}

export const QuizQuestionContext = createContext<IQuizQuestionContext>({
  activeAnswerKeys: new Set(),
  addAnswer: () => {},
  removeAnswer: () => {},
  resetAnswers: () => {},
  correctAnswerKeys: new Set(),
  toggleCorrectAnswer: () => {},
});

export interface QuizQuestionProviderProps extends PropsWithChildren {
  question?: QuizQuestion;
}

export const QuizQuestionProvider: React.FC<QuizQuestionProviderProps> = ({
  children,
  question,
}) => {
  const [activeAnswerKeys, setActiveAnswerKeys] = useState<Set<string>>(new Set());
  const [correctAnswerKeys, setCorrectAnswerKeys] = useState<Set<string>>(new Set());
  const [allOfTheAboveKey, setAllOfTheAboveKey] = useState<string | undefined>();
  const [noneOfTheAboveKey, setNoneOfTheAboveKey] = useState<string | undefined>();
  const { setPreviewContent } = useLivePreviewContext();
  const answerCount = useMemo(() => [...activeAnswerKeys].length, [activeAnswerKeys]);

  const sortAnswerKeys = useCallback(() => {
    const sort = (a: string, b: string) => {
      if (a === noneOfTheAboveKey) return 1;
      if (b === noneOfTheAboveKey) return -1;
      if (a === allOfTheAboveKey) return 1;
      if (b === allOfTheAboveKey) return -1;

      return 0;
    };
    if (typeof allOfTheAboveKey !== 'undefined' || typeof noneOfTheAboveKey !== 'undefined') {
      setActiveAnswerKeys(prevState => {
        const sorted = new Set([...prevState].sort(sort));
        return sorted;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOfTheAboveKey, noneOfTheAboveKey, answerCount]);

  const setCorrectAnswer = useCallback((answerKey: string, correct?: boolean) => {
    setCorrectAnswerKeys(prevState => {
      const newAnswerKeys = new Set(prevState);
      if (!correct) {
        newAnswerKeys.delete(answerKey);
      } else {
        newAnswerKeys.add(answerKey);
      }
      return newAnswerKeys;
    });
  }, []);

  const toggleCorrectAnswer = useCallback(
    (answerKey: string) => {
      const correct = correctAnswerKeys.has(answerKey);
      setCorrectAnswer(answerKey, !correct);
    },
    [correctAnswerKeys, setCorrectAnswer],
  );

  const addAnswer = useCallback(
    (answerId?: number, correct?: boolean, type?: 'all' | 'none') => {
      const answerKey = `${quizAnswerKeyString}/${question?.questionId}/${
        answerId ?? Date.now() + Math.random()
      }`;
      setActiveAnswerKeys(prevState => {
        const newAnswerKeys = new Set(prevState);
        newAnswerKeys.add(answerKey);
        return newAnswerKeys;
      });
      if (typeof correct !== 'undefined') {
        setCorrectAnswer(answerKey, correct);
      }
      if (type === 'all') {
        setPreviewContent(answerKey, 'All of the above');
        setAllOfTheAboveKey(answerKey);
      } else if (type === 'none') {
        setPreviewContent(answerKey, 'None of the above');
        setNoneOfTheAboveKey(answerKey);
      }
    },
    [question?.questionId, setCorrectAnswer, setPreviewContent],
  );

  const addAllOfTheAbove = useMemo(
    () =>
      allOfTheAboveKey
        ? undefined
        : (answerId?: number, correct?: boolean) => {
            if (!allOfTheAboveKey) {
              addAnswer(answerId, correct, 'all');
            }
          },
    [addAnswer, allOfTheAboveKey],
  );

  const addNoneOfTheAbove = useMemo(
    () =>
      noneOfTheAboveKey
        ? undefined
        : (answerId?: number, correct?: boolean) => {
            if (!noneOfTheAboveKey) {
              addAnswer(answerId, correct, 'none');
            }
          },
    [addAnswer, noneOfTheAboveKey],
  );

  const removeAnswer = useCallback(
    (answerKey: string) => {
      setActiveAnswerKeys(prevState => {
        const newAnswerKeys = new Set(prevState);
        newAnswerKeys.delete(answerKey);
        return newAnswerKeys;
      });
      setCorrectAnswer(answerKey, false);
      if (answerKey === allOfTheAboveKey) {
        setAllOfTheAboveKey(undefined);
      } else if (answerKey === noneOfTheAboveKey) {
        setNoneOfTheAboveKey(undefined);
      }
    },
    [allOfTheAboveKey, noneOfTheAboveKey, setCorrectAnswer],
  );

  const resetAnswers = useCallback(() => {
    setActiveAnswerKeys(new Set());
    setCorrectAnswerKeys(new Set());
    setAllOfTheAboveKey(undefined);
    setNoneOfTheAboveKey(undefined);
    if (typeof question?.answers !== 'undefined' && question.answers.length > 0) {
      question.answers?.forEach(answer => {
        // if all/none of the above, call respective function
        let type: 'all' | 'none' | undefined;
        if (answer.text?.includes('All of the above')) type = 'all';
        else if (answer.text?.includes('None of the above')) type = 'none';
        addAnswer(answer.answerId, answer.isCorrect, type);
        setPreviewContent(
          `${quizAnswerKeyString}/${question.questionId}/${answer.answerId}`,
          answer.text ?? '',
        );
      });

      return;
    }

    addAnswer(Date.now() + Math.random());
    addAnswer(Date.now() + Math.random());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question?.answers, question?.questionId]);

  useEffect(() => {
    sortAnswerKeys();
  }, [sortAnswerKeys]);

  useEffect(() => {
    resetAnswers();
  }, [resetAnswers]);

  return (
    <QuizQuestionContext.Provider
      value={{
        activeAnswerKeys,
        addAnswer,
        allOfTheAboveKey,
        addAllOfTheAbove,
        noneOfTheAboveKey,
        addNoneOfTheAbove,
        removeAnswer,
        resetAnswers,
        correctAnswerKeys,
        toggleCorrectAnswer,
      }}>
      {children}
    </QuizQuestionContext.Provider>
  );
};

export function useQuizQuestionContext() {
  const context = useContext(QuizQuestionContext);

  if (!context) {
    throw new Error('useQuizQuestionContext must be used within LivePreviewProvider');
  }

  return context;
}
