import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { EditorContent, EditorEvents, Extensions, useEditor } from '@tiptap/react';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Align from '@tiptap/extension-text-align';
import CharacterCount from '@tiptap/extension-character-count';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { useLivePreviewContext } from './LivePreviewContext';
import LivePreivewToolbar from './LivePreviewToolbar';

export interface LivePreviewEditorProps {
  editorKey: string;
  label?: string;
  content?: string;
  placeholder?: string;
  disabled?: boolean;
  extensions?: Extensions;
  starterKitOptions?: Partial<StarterKitOptions>;
  dependencies?: any[];
  formatHtml?: (contentHtml: string) => string;
  startComponent?: React.JSX.Element;
  endComponent?: React.JSX.Element;
  characterLimit?: number;
}

const LivePreviewEditor: React.FC<LivePreviewEditorProps> = ({
  editorKey,
  label,
  content,
  placeholder,
  disabled,
  extensions,
  starterKitOptions,
  dependencies,
  formatHtml,
  startComponent,
  endComponent,
  characterLimit,
}) => {
  const defaultExtensions = useMemo(
    () => [
      StarterKit.configure({
        heading: false,
        ...starterKitOptions,
      }),
      TextStyle,
      Placeholder,
      Underline,
      Align.configure({ types: ['paragraph'] }),
      CharacterCount.configure({ limit: null }),
      Table.configure({
        HTMLAttributes: {
          style:
            'min-width: 100px; width: 100%; border-collapse: collapse; margin: 0 auto; overflow: hidden; table-layout: fixed;',
        },
      }),
      TableCell.configure({
        HTMLAttributes: {
          style:
            'border: solid; box-sizing: border-box; min-width: 1em; padding: 6px 8px; position: relative; vertical-align: top; overflow-wrap: break-word;',
        },
      }),
      TableHeader.configure({
        HTMLAttributes: {
          style:
            'border: solid; box-sizing: border-box; min-width: 1em; padding: 6px 8px; position: relative; vertical-align: top; overflow-wrap: break-word;',
        },
      }),
      TableRow,
      ...(extensions ?? []),
    ],
    [extensions, starterKitOptions],
  );

  const theme = useTheme();
  const { setPreviewContent, getPreviewInputError, setPreviewInputError } = useLivePreviewContext();
  const error = useMemo(() => getPreviewInputError(editorKey), [editorKey, getPreviewInputError]);
  const onUpdate = useCallback(
    (props: EditorEvents['update'] | EditorEvents['create']) => {
      const text = props.editor.getText();
      if (text.length === 0) {
        setPreviewContent(editorKey, '');
        return;
      }
      // replace empty pargraph with br to handle hard break
      let html = props.editor.getHTML().replaceAll('<p></p>', '<br>');
      if (formatHtml) {
        html = formatHtml(html);
      }
      setPreviewContent(editorKey, html);
    },
    [editorKey, formatHtml, setPreviewContent],
  );
  const editor = useEditor(
    {
      content,
      onUpdate,
      onCreate: onUpdate, // could be useful for applying initialStyle
      extensions: defaultExtensions,
    },
    [...(dependencies ?? []), defaultExtensions],
  );
  const charactersRemaining = useMemo(
    () =>
      characterLimit ? characterLimit - editor?.storage.characterCount.characters() : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [characterLimit, editor?.state.doc.content.size],
  );

  useEffect(() => {
    if (charactersRemaining && charactersRemaining < 0) {
      if (typeof error === 'undefined') {
        setPreviewInputError(editorKey, `Maximum: ${characterLimit} characters`);
      }
    } else if (charactersRemaining !== characterLimit) {
      setPreviewInputError(editorKey, undefined);
    }
  }, [
    characterLimit,
    charactersRemaining,
    editorKey,
    error,
    getPreviewInputError,
    setPreviewInputError,
  ]);

  useEffect(() => {
    if (disabled) {
      editor?.setEditable(false);
    } else {
      editor?.setEditable(true);
    }
  }, [disabled, editor]);

  return (
    <Grid
      display='flex'
      flexDirection='column'
      rowGap='10px'
      sx={{
        '& .tiptap': {
          '& p.is-editor-empty:first-child::before': {
            color: '#adb5bd',
            content: `"${placeholder ?? ''}"`,
            float: 'left',
            height: 0,
            pointerEvents: 'none',
          },
        },
      }}>
      {typeof label !== 'undefined' && (
        <Typography variant='p16' color='secondary.hover'>
          {label}
        </Typography>
      )}
      <Grid display='flex' columnGap='10px'>
        {startComponent}
        <Grid flexGrow={1} display='flex' flexDirection='column' rowGap='5px'>
          <Grid
            className='live-preview-editor'
            style={
              typeof error !== 'undefined' ? { borderColor: theme.palette.error.dark } : undefined
            }>
            <EditorContent editor={editor} style={{ flexGrow: 1 }} />
            <LivePreivewToolbar editor={editor} />
          </Grid>
          <Grid display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='p12' color='error.dark'>
              {error}
            </Typography>
            {typeof charactersRemaining !== 'undefined' && (
              <Typography
                variant='p12'
                color={charactersRemaining >= 0 ? 'secondary.hover' : 'error.dark'}>
                {charactersRemaining}
              </Typography>
            )}
          </Grid>
        </Grid>
        {endComponent}
      </Grid>
    </Grid>
  );
};

export default LivePreviewEditor;
