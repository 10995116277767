import { Grid } from '@mui/material';
import QuizInput, { QuizInputProps } from './QuizInput';
import QuizOutput, { QuizOutputProps } from './QuizOutput';
import { QuizQuestionProvider } from './QuizQuestionContext';

const QuizQuestionPreview: React.FC<QuizInputProps & QuizOutputProps> = ({
  question,
  publishedQuestion,
  index,
  empty,
  ...rest
}) => {
  return (
    <QuizQuestionProvider question={question}>
      <Grid className='live-preview-content' key={`${question.questionId ?? 'new-question'}`}>
        <Grid className='draft'>
          <QuizInput index={index} question={question} {...rest} />
        </Grid>
        <Grid className='preview'>
          <QuizOutput
            index={index}
            question={question}
            publishedQuestion={publishedQuestion}
            empty={empty}
          />
        </Grid>
      </Grid>
    </QuizQuestionProvider>
  );
};

export default QuizQuestionPreview;
