import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { UnitData } from '../../models/CourseData';

export class UnitApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getUnit(id: number): Promise<AxiosResponse<UnitData>> {
    return this.axios.get(`unit/${id}`);
  }

  getUnitByCourseId(lessonId: number): Promise<AxiosResponse<UnitData>> {
    return this.axios.get(`unit/by-course?courseId=${lessonId}`);
  }

  createUnit(unit: UnitData): Promise<AxiosResponse<UnitData>> {
    return this.axios.post('unit', unit);
  }

  updateUnit(unit: UnitData): Promise<AxiosResponse<UnitData>> {
    return this.axios.patch('unit', unit);
  }

  // revert back to id
  publishUnit(unit: UnitData): Promise<AxiosResponse<UnitData>> {
    return this.axios.post(`unit/publish/${unit.unitId}`);
  }

  // revert back to id
  unpublishUnit(unit: UnitData): Promise<AxiosResponse<UnitData>> {
    return this.axios.delete(`unit/unpublish/${unit.unitId}`);
  }

  updateUnitOrder(units: UnitData[], courseId: number): Promise<AxiosResponse<UnitData[]>> {
    return this.axios.patch(`unit/order?courseId=${courseId}`, units);
  }

  replaceUnit(unit: UnitData): Promise<AxiosResponse<UnitData>> {
    return this.axios.put('unit', unit);
  }

  deleteUnit(id: number): Promise<AxiosResponse<UnitData>> {
    return this.axios.delete(`unit/${id}`);
  }
}
