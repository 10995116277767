import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { MendixClientData, MendixClientApi } from '../../api/apis/MendixClientApi';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface MendixClientCompartments {
  mendixClientData: DataCompartmentOptions<MendixClientData | undefined>;
}

export const mendixClientStorageKey = 'data/mendixClient';

export const withMendixClientData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<MendixClientCompartments>({
      mendixClientData: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const mendixClientApi = container.get<MendixClientApi>(ApiKeys.MendixClient);
            const { data } = await mendixClientApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(mendixClientStorageKey, cache);
  },
);
