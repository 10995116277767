import { IObservableQuery, injectSubject } from '@aesop-fables/scrinium';
import { CourseData, LessonData } from '../../../models/CourseData';
import { LearningServices } from '../LearningServiceModule';
import { Observable, map } from 'rxjs';
import { findLessonSource } from '../commands/utils';

export interface LessonQueryParams {
  unitId: number;
  originalLessonId: number;
}

export class FindActiveLessonById
  implements IObservableQuery<LessonQueryParams, LessonData | undefined>
{
  constructor(
    @injectSubject(LearningServices.Courses) private readonly courses: Observable<CourseData[]>,
  ) {}

  execute({ unitId, originalLessonId }: LessonQueryParams): Observable<LessonData | undefined> {
    return this.courses.pipe(
      map(courses => {
        const { courseIndex, unitIndex } = findLessonSource({ unitId }, courses);
        if (courseIndex !== -1 && typeof unitIndex !== 'undefined') {
          const course = courses[courseIndex];
          const unit = course.units?.at(unitIndex);
          const activeLessonIndex = unit?.lessons?.findIndex(
            x => x.originalLessonId === originalLessonId && x.active,
          );
          // find the active version if it exists
          if (
            typeof unit?.lessons !== 'undefined' &&
            typeof activeLessonIndex !== 'undefined' &&
            activeLessonIndex !== -1
          ) {
            const lesson = unit?.lessons[activeLessonIndex];
            return lesson;
          }
        }
      }),
    );
  }
}
