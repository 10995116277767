import { Typography } from '@mui/material';
import { Row } from '.';

interface PageProps extends React.PropsWithChildren {
  title?: string;
  showAdornment?: boolean | undefined;
  titleAdornment?: JSX.Element;
}

const Page: React.FC<PageProps> = props => {
  return (
    <div style={{ margin: '30px 40px' }}>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {props.title && (
          <Typography
            variant='p40'
            color='secondary.main'
            flexGrow={1}
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'>
            {props.title}
          </Typography>
        )}
        {props.showAdornment && props.titleAdornment}
      </Row>
      {props.children}
    </div>
  );
};

export default Page;
