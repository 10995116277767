import { Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StripeSessionStatus } from '../api/apis/AdminPaymentApi';
import { ToastbarContext } from '../App';
import { Page, Spacer } from '../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { LicenseCompartments, licenseStorageKey } from '../data/license';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { PaymentCompartments, paymentStorageKey } from '../data/payment';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { UserCompartments, userStorageKey } from '../data/user';
import { OrganizationData } from '../models/OrganizationData';
import { OrgSignUpStateData } from '../models/SignUpData';
import { Toast } from '../models/Toast';
import { UserData } from '../models/UserData';
import { API_URL } from '../api/apis';
import { LicenseAgreementData } from '../api/apis/LicenseAgreementApi';
import { Loading } from '../routes/AuthenticatedRoutes';
import { DrillDownWizard, drillDownWizardKey, AdminTierTypeEnum } from '../services/drillDown';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../data/groupFeatureMap';
import { GroupFeatureMapData } from '../models/GroupFeatureMapData';
import { UpdateSignUpState } from '../data/signUp/mutations';
import AtAGlance from './dash/AtAGlance';
import MemberSignUpUrl from './dash/MemberSignUpUrl';
import RecentActivity from './dash/RecentActivity';
import ToDo from './dash/ToDo';
import { PaymentTypeEnum, PricebookBundle } from '../models/PricebookData';

const Dashboard = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const error = location.state?.error ?? false;
  const { setToast } = useContext(ToastbarContext);
  const [needsRedirect, setNeedsRedirect] = useState<boolean>(true);

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const updateSignUpState = useMutation(new UpdateSignUpState());

  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);

  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const organizationInitialized = useObservable(
    organizationDataCache.findCompartment('organization').initialized$,
  );
  const stripeSessionStatus = useObservable(
    paymentDataCache.observe$<StripeSessionStatus>('stripeSessionStatus'),
  );
  const paidStatus = useObservable(paymentDataCache.observe$<boolean>('paidStatus'));
  const paymentMethods = useObservable(
    paymentDataCache.observe$<PaymentMethodData[]>('paymentMethods'),
  );

  const origin = useObservable(drillDownWizard.origin$);
  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const orgUrl = orgData?.url;
  const { offboardingComplete } = orgSignUpState ?? { offboardingComplete: false };
  const pendingAgreements = licenseAgreements
    ? licenseAgreements.filter(license => !license.hasAccepted && license.docStatus)
    : undefined;
  const [resumeAddPackage, setResumeAddPackage] = useState<boolean>();

  const selectedPricebook: PricebookBundle | undefined =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state?.pricebook);
  const checkedOut =
    orgSignUpState?.state?.checkedOut && JSON.parse(orgSignUpState?.state.checkedOut);
  const landingPage = highestAccess === AdminTierTypeEnum.Organization && !origin;
  const showAdornment = orgData?.logoUri !== undefined && orgData?.logoUri !== null;
  const isADP = selectedPricebook?.prices.some(price => price.processor.name === 'ADP');
  const autoPay = orgData?.automaticPayment;
  const prePaid = selectedPricebook?.pricebook?.paymentType === PaymentTypeEnum.Prepaid;
  const meteredPayment = selectedPricebook?.pricebook?.paymentType === PaymentTypeEnum.Metered;
  const hasPendingAgreements = useMemo(() => {
    return pendingAgreements && pendingAgreements.length > 0;
  }, [pendingAgreements]);
  const needsStripePrepaid = useMemo(() => {
    return stripeSessionStatus && prePaid && paidStatus === false;
  }, [stripeSessionStatus, prePaid, paidStatus]);
  const needsStripeMetered = useMemo(() => {
    return meteredPayment && paymentMethods?.length === 0 && !checkedOut;
  }, [meteredPayment, paymentMethods, checkedOut]);
  const shouldShowPayment = useMemo(() => {
    return (
      !isADP &&
      autoPay &&
      organizationInitialized &&
      (hasPendingAgreements || needsStripePrepaid || needsStripeMetered)
    );
  }, [
    isADP,
    autoPay,
    organizationInitialized,
    hasPendingAgreements,
    needsStripePrepaid,
    needsStripeMetered,
  ]);

  useEffect(() => {
    // if the user gets rerouted to '/' due to an error
    if (error) {
      setToast(new Toast({ severity: 'error', open: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldShowPayment) {
      setResumeAddPackage(true);
      navigate('/addPackage/contract', {
        state: { redirectReason: 'abandonedAddPackage' },
      });
    } else if (organizationInitialized) {
      setResumeAddPackage(false);
    }
    setNeedsRedirect(false);
  }, [
    shouldShowPayment,
    checkedOut,
    organizationInitialized,
    pendingAgreements,
    stripeSessionStatus,
    paymentMethods?.length,
    autoPay,
    meteredPayment,
    prePaid,
    isADP,
    navigate,
  ]);

  useEffect(() => {
    if (orgSignUpState?.onboardingComplete && resumeAddPackage === false) {
      resetSignUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeAddPackage, orgSignUpState?.onboardingComplete]);

  const resetSignUp = async () => {
    await updateSignUpState.action({
      state: {},
    });
  };

  if (needsRedirect) {
    return <Loading />;
  }

  return (
    <Page
      title={
        landingPage ? (userData?.firstName ? `Hi, ${userData.firstName}!` : 'Hi!') : 'Dashboard'
      }
      showAdornment={showAdornment}
      titleAdornment={
        <img
          src={`${API_URL}organization/logo/${orgData?.id}`}
          style={{ height: '50px' }}
          alt={`${orgData?.name}'s logo`}
        />
      }>
      {groupFeatureMapData?.orgToDoListView && (
        <>
          <Spacer height='sm' />
          <ToDo />
        </>
      )}
      <Spacer height='sm' />
      <AtAGlance />
      {offboardingComplete && orgUrl && (
        <>
          <Spacer height='sm' />
          <Typography variant='h1' color='secondary'>
            Member sign up page
          </Typography>
          <Spacer height='xxs' />
          <MemberSignUpUrl />
        </>
      )}
      {groupFeatureMapData?.orgRecentActivityView && (
        <>
          <Spacer height='sm' />
          <RecentActivity />
        </>
      )}
    </Page>
  );
};

export default Dashboard;
