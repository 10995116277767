import { IObservableQuery, injectSubject } from '@aesop-fables/scrinium';
import { CourseData, PublicationStatusEnum } from '../../../models/CourseData';
import { LearningServices } from '../LearningServiceModule';
import { Observable, map } from 'rxjs';

export class FindCourseById implements IObservableQuery<number, CourseData | undefined> {
  constructor(
    @injectSubject(LearningServices.Courses) private readonly courses: Observable<CourseData[]>,
  ) {}

  execute(originalCourseId: number): Observable<CourseData | undefined> {
    return this.courses.pipe(
      map(courses => {
        const course = courses.find(
          x =>
            x.originalCourseId === originalCourseId &&
            (x.active || x.status === PublicationStatusEnum.DRAFT),
        );
        return course;
      }),
    );
  }
}
