import { ReactNode, useState, CSSProperties, Children } from 'react';
import Tippy from '@tippyjs/react';
import type { Placement } from 'tippy.js';
import Column from './Column';
import { MoreHoriz as EllipsesIcon } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import ClickableList from './ClickableList';
import React from 'react';

interface EllipsesMenuProps {
  children: ReactNode;
  disabled?: boolean;
  placement?: Placement;
  iconColor?: IconButtonProps['color'];
  style?: CSSProperties;
}

const EllipsesMenu: React.FC<EllipsesMenuProps> = ({
  children,
  disabled = false,
  placement = 'bottom-end',
  iconColor = 'primary',
  style,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const items = Children.toArray(children)
    .filter(Boolean)
    .map((element, index) => ({
      id: `item-${index}`,
      element,
    }));

  return (
    <Tippy
      visible={isVisible}
      disabled={disabled}
      placement={placement}
      arrow={false}
      interactive={true}
      onClickOutside={() => setIsVisible(false)}
      theme='tippy-rounded no-padding'
      content={
        <Column style={{ rowGap: '10px', ...style }}>
          <ClickableList
            size='compact'
            items={items}
            renderItem={item => item.element}
            getItemKey={item => item.id}
            onItemClick={async () => {
              setIsVisible(false);
              return true;
            }}
          />
        </Column>
      }>
      <IconButton
        className='ellipses'
        onClick={() => setIsVisible(prev => !prev)}
        size='small'
        color={iconColor}>
        <EllipsesIcon className='ellipses' />
      </IconButton>
    </Tippy>
  );
};

export default EllipsesMenu;
