import { Grid, Typography, Button, ToggleButtonGroup, ToggleButton, Toolbar } from '@mui/material';
import { Svgs } from '../assets/svg';
import { EnvironmentEnum, useLivePreviewContext } from './LivePreviewContext';

export interface LivePreviewHeaderProps {
  title?: string;
  onDone?: () => void;
}

export const LivePreviewHeader: React.FC<LivePreviewHeaderProps> = ({ title, onDone }) => {
  const { environment, setEnvironment, environmentOptions } = useLivePreviewContext();
  return (
    <Toolbar>
      <Grid className='live-preview-header'>
        <Grid className='draft'>
          <Typography variant='p24SemiBold' color='secondary'>
            {title ?? 'Draft'}
          </Typography>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onDone}
            disabled={typeof onDone === 'undefined'}>
            Done
          </Button>
        </Grid>
        <Grid className='preview'>
          <Typography variant='p24SemiBold' color='secondary'>
            {environment} Preview
          </Typography>
          <ToggleButtonGroup
            value={environment}
            exclusive
            onChange={(e, v) => {
              if (v) {
                setEnvironment(v);
              }
            }}>
            {environmentOptions[EnvironmentEnum.DESKTOP] && (
              <ToggleButton
                style={{ width: '120px' }}
                key={EnvironmentEnum.DESKTOP}
                value={EnvironmentEnum.DESKTOP}>
                {environment === EnvironmentEnum.DESKTOP ? (
                  <Svgs.IconTechLight />
                ) : (
                  <Svgs.IconTechDark />
                )}
              </ToggleButton>
            )}
            {environmentOptions[EnvironmentEnum.MOBILE] && (
              <ToggleButton
                style={{ width: '120px' }}
                key={EnvironmentEnum.MOBILE}
                value={EnvironmentEnum.MOBILE}>
                {environment === EnvironmentEnum.MOBILE ? (
                  <Svgs.IconCellPhoneLight />
                ) : (
                  <Svgs.IconCellPhoneDark />
                )}
              </ToggleButton>
            )}
            {environmentOptions[EnvironmentEnum.COMPARE] && (
              <ToggleButton
                style={{ width: '120px' }}
                key={EnvironmentEnum.COMPARE}
                value={EnvironmentEnum.COMPARE}>
                {environment === EnvironmentEnum.COMPARE ? (
                  <Svgs.IconSideBySideLight />
                ) : (
                  <Svgs.IconSideBySideDark />
                )}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default LivePreviewHeader;
