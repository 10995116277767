import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Svgs } from '../../../../../assets/svg';
import { ContentInputProps } from './ContentInput';

interface ContentReadonlyProps extends Omit<ContentInputProps, 'index'> {}

const ContentReadonly: React.FC<ContentReadonlyProps> = ({ content }) => {
  if (typeof content.contentId === 'undefined') return null;

  return (
    <Card variant='outlined' style={{ borderColor: '#d4ebf2' }}>
      <CardContent className='pad20'>
        <Grid display='flex' flexDirection='column' rowGap='20px'>
          <Typography className='lesson-content-title' color='secondary' variant='p18SemiBold'>
            <div dangerouslySetInnerHTML={{ __html: content.title ?? '' }} />
          </Typography>
          {typeof content.icon !== 'undefined' && content.icon !== null && (
            <Grid display='flex' alignItems='center' columnGap='10px'>
              <Svgs.IconPicture />
              <Typography variant='p16Light'>{content.icon.mediaName}</Typography>
            </Grid>
          )}
          <Typography className='lesson-content-copy' variant='p16Light'>
            <div dangerouslySetInnerHTML={{ __html: content.contentCopy ?? '' }} />
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentReadonly;
