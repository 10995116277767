import { Grid } from '@mui/material';
import { LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AddButton from '../../../../components/AddButton';
import LessonSummaryQuiz from './LessonSummaryQuiz';
import LessonSummaryContent from './LessonSummaryContent';
import LessonSummaryNameCard from './LessonSummaryNameCard';
import { CreateLessonDraft } from '../../../../data/learning/commands/CreateLessonDraft';
import { useLoading } from '../../../../hooks/useLoading';
import { useCommands } from '../../../../helpers/useCommands';
import { useTabContext } from '../../../../components/TabContext';

interface LessonSummaryDetailsProps {
  lesson?: LessonData;
  disabled?: boolean;
  linked?: boolean;
}

const LessonSummaryDetails: React.FC<LessonSummaryDetailsProps> = ({
  lesson,
  disabled,
  linked,
}) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const commands = useCommands();
  const { setActiveTab } = useTabContext();

  const contents = useMemo(() => lesson?.contents ?? [], [lesson?.contents]);
  const quiz = useMemo(() => lesson?.quiz, [lesson?.quiz]);

  const createContent = useCallback(async () => {
    try {
      setLoading(true);
      if (lesson?.status === PublicationStatusEnum.PUBLISHED) {
        await commands.execute(CreateLessonDraft, lesson);
      }
      navigate('content/edit');
      setActiveTab(1);
    } finally {
      setLoading(false);
    }
  }, [commands, lesson, navigate, setActiveTab, setLoading]);

  const createQuiz = useCallback(async () => {
    try {
      setLoading(true);
      if (lesson?.status === PublicationStatusEnum.PUBLISHED) {
        await commands.execute(CreateLessonDraft, lesson);
      }
      navigate('quiz/edit');
      setActiveTab(1);
    } finally {
      setLoading(false);
    }
  }, [commands, lesson, navigate, setActiveTab, setLoading]);

  return (
    <Grid display='flex' flexDirection='column' rowGap='20px'>
      <LessonSummaryNameCard lesson={lesson} disabled={disabled} />
      {contents.length > 0 && lesson ? (
        <LessonSummaryContent lesson={lesson} disabled={disabled} linked={linked} />
      ) : (
        !disabled && <AddButton content='Add new content' onClick={createContent} />
      )}
      {typeof quiz !== 'undefined' && quiz !== null && lesson ? (
        <LessonSummaryQuiz lesson={lesson} disabled={disabled} linked={linked} />
      ) : (
        !disabled && <AddButton content='Add new quiz' onClick={createQuiz} />
      )}
    </Grid>
  );
};

export default LessonSummaryDetails;
