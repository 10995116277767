import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { TrustFamilyData, TrustFamilyApi } from '../../api/apis/TrustFamilyApi';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface TrustFamilyCompartments {
  trustFamilyData: DataCompartmentOptions<TrustFamilyData[] | undefined>;
}

export const trustFamilyStorageKey = 'data/trustFamily';

export const withTrustFamilyData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<TrustFamilyCompartments>({
      trustFamilyData: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const trustFamilyApi = container.get<TrustFamilyApi>(ApiKeys.TrustFamily);
            const { data } = await trustFamilyApi.getTrustFamily();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(trustFamilyStorageKey, cache);
  },
);
