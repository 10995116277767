import React, { ReactNode } from 'react';

interface SemiBoldProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

export const SemiBold: React.FC<SemiBoldProps> = ({ children, style }) => (
  <span style={{ fontWeight: 600, ...style }}>{children}</span>
);
