import { CircularProgress, Grid } from '@mui/material';
import LivePreview from '../../../../../components/LivePreview';
import { useParams } from 'react-router';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FindDraftByLessonId } from '../../../../../data/learning/queries/FindDraftByLesson';
import ContentInput from './ContentInput';
import ContentOutput from './ContentOutput';
import { useNavigate } from 'react-router-dom';
import AddButton from '../../../../../components/AddButton';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ToastbarContext } from '../../../../../App';
import { useCommands } from '../../../../../helpers/useCommands';
import { useLoading } from '../../../../../hooks/useLoading';
import { Toast } from '../../../../../models/Toast';
import { LessonContent } from '../../../../../models/CourseData';
import { sortByPosition } from '../../../../../data/learning/commands/utils';
import { UpdateContentPosition } from '../../../../../data/learning/commands/UpdateContentPosition';
import {
  EnvironmentEnum,
  defaultEnvironmentOptions,
} from '../../../../../components/LivePreviewContext';
import { FindActiveLessonById } from '../../../../../data/learning/queries/FindActiveLessonById';

export const contentTitleKeyString = 'lessonContent/title';
export const contentCopyKeyString = 'lessonContent/copy';
export const contentIconKeyString = 'lessonContent/icon';

interface ContentEditViewProps {
  editing?: boolean;
}

const ContentEditView: React.FC<ContentEditViewProps> = ({ editing }) => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const params = useParams();
  const { unitId, originalLessonId } = useMemo(
    () => ({
      courseId: parseInt(params.courseId ?? ''),
      unitId: parseInt(params.unitId ?? ''),
      originalLessonId: parseInt(params.originalLessonId ?? ''),
    }),
    [params.courseId, params.originalLessonId, params.unitId],
  );
  const publishedLesson = useObservableQuery(FindActiveLessonById, { unitId, originalLessonId });
  const draft = useObservableQuery(FindDraftByLessonId, { unitId, originalLessonId });
  const [orderedContents, setOrderedContents] = useState<LessonContent[]>([]);
  const [activeContentId, setActiveContentId] = useState<number | undefined>();
  const [newContentActive, setNewContentActive] = useState<boolean>(false);
  const empty = useMemo(() => (draft?.contents?.length ?? 0) === 0, [draft?.contents?.length]);
  const environmentOptions = useMemo(
    () => ({
      ...defaultEnvironmentOptions,
      [EnvironmentEnum.COMPARE]: typeof publishedLesson !== 'undefined',
    }),
    [publishedLesson],
  );

  useEffect(() => {
    if (draft?.contents) {
      const sortedContents = draft.contents.sort(sortByPosition);
      setOrderedContents(sortedContents);
    }
  }, [draft?.contents]);

  useEffect(() => {
    if (draft?.contents?.length === 0) {
      setNewContentActive(true);
    } else {
      setNewContentActive(false);
    }
  }, [draft?.contents?.length]);

  const setEditing = useCallback(
    (contentId?: number) => {
      if (
        (typeof activeContentId !== 'undefined' &&
          typeof contentId !== 'undefined' &&
          activeContentId !== contentId) ||
        newContentActive
      ) {
        setToast(
          new Toast({
            severity: 'error',
            open: true,
            message: 'Please finish editing the current content',
          }),
        );
      } else {
        setActiveContentId(contentId);
      }
    },
    [activeContentId, newContentActive, setToast],
  );

  const addNewContent = useCallback(() => {
    if (typeof activeContentId !== 'undefined' || newContentActive) {
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Please finish editing the current content',
        }),
      );
    } else {
      setNewContentActive(true);
    }
  }, [activeContentId, newContentActive, setToast]);

  const dragEnded = async (param: any) => {
    const { source, destination } = param;
    const currentContents = [...orderedContents];

    try {
      setLoading(true);
      const newContents = [...orderedContents];
      // extracting the source item from the list
      const content = newContents.splice(source.index, 1)[0];
      newContents.splice(destination.index, 0, content);
      const sorted = newContents.map((x, i) => ({ ...x, position: i + 1 }));
      setOrderedContents(sorted);

      await commands.execute(UpdateContentPosition, {
        content,
        orderedContents: sorted,
      });
    } catch (err) {
      setToast(new Toast({ severity: 'error', open: true }));
      setOrderedContents(currentContents);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LivePreview
      environmentOptions={environmentOptions}
      headerProps={{
        title: editing ? 'Draft' : 'Published',
        onDone: !activeContentId && !newContentActive ? () => navigate(-1) : undefined,
      }}>
      <DragDropContext onDragEnd={dragEnded}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <Grid
              {...provided.droppableProps}
              ref={provided.innerRef}
              display='flex'
              flexDirection='column'>
              {draft?.contents?.map((content, index) => (
                <Grid className='live-preview-content' key={`draggable-${content.contentId}`}>
                  <Grid className='draft'>
                    <Draggable
                      draggableId={`draggable-${content.contentId}`}
                      index={index}
                      isDragDisabled={newContentActive || typeof activeContentId !== 'undefined'}>
                      {(_provided, _snapshot) => (
                        <ContentInput
                          snapshot={_snapshot}
                          ref={_provided.innerRef}
                          dragHandleProps={_provided.dragHandleProps}
                          dragProps={_provided.draggableProps}
                          content={content}
                          disabled={!editing}
                          disableDrag={newContentActive || typeof activeContentId !== 'undefined'}
                          editing={activeContentId === content.contentId}
                          setEditing={setEditing}
                        />
                      )}
                    </Draggable>
                  </Grid>
                  <Grid className='preview'>
                    {!snapshot.isDraggingOver && (
                      <ContentOutput
                        content={content}
                        publishedContent={publishedLesson?.contents?.at(index)}
                      />
                    )}
                    {snapshot.isDraggingOver && index === 0 && (
                      <Grid display='flex' justifyContent='center'>
                        <CircularProgress />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
              {newContentActive && (
                <Grid className='live-preview-content' key={`draggable-new-content`}>
                  <Grid className='draft'>
                    <Draggable
                      draggableId={`draggable-new-content`}
                      index={draft?.contents?.length ?? 0}
                      isDragDisabled>
                      {(_provided, _snapshot) => (
                        <ContentInput
                          snapshot={_snapshot}
                          ref={_provided.innerRef}
                          dragHandleProps={_provided.dragHandleProps}
                          dragProps={_provided.draggableProps}
                          content={{ lessonId: draft?.lessonId }}
                          disabled={!editing}
                          disableDrag
                          editing={true}
                          setEditing={() => setNewContentActive(false)}
                        />
                      )}
                    </Draggable>
                  </Grid>
                  <Grid className='preview'>
                    <ContentOutput content={{ lessonId: draft?.lessonId }} empty={empty} />
                  </Grid>
                </Grid>
              )}
              <Grid className='live-preview-content' key={`placeholder`}>
                <Grid className='draft'>{provided.placeholder}</Grid>
                <Grid className='preview' />
              </Grid>
              <Grid className='live-preview-content' key={`add-new-content`}>
                <Grid className='draft'>
                  <AddButton content='Add new content' onClick={addNewContent} />
                </Grid>
                <Grid className='preview' />
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </LivePreview>
  );
};

export default ContentEditView;
