import { ApiKeys } from '../../api/apis/ApiKeys';
import { OnboardingTemplateApi } from '../../api/apis/OnboardingTemplateApi';
import { OrgSignUpStateApi } from '../../api/apis/OrgSignUpStateApi';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { OrgSignUpStateData, TemplateData } from '../../models/SignUpData';
import { TeamSelected } from '../../predicates/TeamSelected';
import { IsAuthenticated } from '../../predicates/IsAuthenticated';

export interface SignUpCompartments {
  onboardingTemplate: DataCompartmentOptions<TemplateData | undefined>;
  orgSignUpState: DataCompartmentOptions<OrgSignUpStateData | undefined>;
  allOrgSignUpStates: DataCompartmentOptions<OrgSignUpStateData[] | undefined>;
}

export const signUpStorageKey = 'data/signUp';

export const withSignUpData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<SignUpCompartments>({
      onboardingTemplate: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(IsAuthenticated) },
        source: new ConfiguredDataSource(async () => {
          const onboardingTemplateApi = container.get<OnboardingTemplateApi>(
            ApiKeys.OnboardingTemplate,
          );
          const { data } = await onboardingTemplateApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
      orgSignUpState: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(IsAuthenticated) },
        source: new ConfiguredDataSource(async () => {
          const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
          const { data } = await orgSignUpStateApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
      allOrgSignUpStates: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(TeamSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
            const { data } = await orgSignUpStateApi.getAll();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(signUpStorageKey, cache);
  },
);
