import { useEffect, useMemo, useState } from 'react';
import { CategoryData } from '../../../../models/CourseData';
import { Button, Grid, Typography } from '@mui/material';
import { Row, Spacer } from '../../../../components';
import { Form } from 'react-final-form';
import RightDrawer from '../../../../components/RightDrawer';
import CheckboxList from '../../../../components/CheckboxList';

interface LessonCategoryDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (categories: CategoryData[]) => void;
  allCategories: CategoryData[];
  selectedCategories: CategoryData[];
}

const LessonCategoryDrawer: React.FC<LessonCategoryDrawerProps> = ({
  open,
  onClose,
  onSubmit,
  allCategories,
  selectedCategories,
}) => {
  const [selections, setSelections] = useState<CategoryData[]>([]);
  const hasChanges = useMemo(
    () =>
      selectedCategories.length !== selections.length ||
      selections.some(sel => !selectedCategories.find(cat => cat.categoryId === sel.categoryId)),
    [selections, selectedCategories],
  );

  useEffect(() => {
    if (open) {
      setSelections(selectedCategories);
    }
  }, [open, selectedCategories]);

  return (
    <RightDrawer title='Add categories' open={open} setOpen={onClose}>
      <Form
        onSubmit={() => onSubmit(selections)}
        initialValues={{ categories: selectedCategories }}>
        {({ handleSubmit }) => (
          <Grid container flexDirection='column'>
            <Typography variant='p18SemiBold' color='secondary'>
              Which categories does this lesson best fit?
            </Typography>

            <Spacer height='xxxs' />

            <CheckboxList<CategoryData>
              parentLabel='All categories'
              items={allCategories}
              noneSelectedMessage='No categories selected'
              checkedItems={selections}
              setCheckedItems={setSelections}
              getItemId={category => category.categoryId}
              getItemLabel={category => category.category}
            />

            <Spacer height='xxl' />

            <Row style={{ justifyContent: 'space-between' }}>
              <Button onClick={onClose} variant='outlined' color='secondary' className='sm'>
                Cancel
              </Button>
              <Button onClick={handleSubmit} color='primary' disabled={!hasChanges}>
                Save
              </Button>
            </Row>
          </Grid>
        )}
      </Form>
    </RightDrawer>
  );
};

export default LessonCategoryDrawer;
