import { LessonContent } from '../../../../../models/CourseData';
import {
  EnvironmentEnum,
  useLivePreviewContext,
} from '../../../../../components/LivePreviewContext';
import ContentOutputDesktop from './ContentOutputDesktop';
import ContentOutputMobile from './ContentOutputMobile';
import ContentReadonly from './ContentReadonly';

export interface ContentOutputProps {
  content: LessonContent;
  publishedContent?: LessonContent;
  empty?: boolean;
}

const ContentOutput: React.FC<ContentOutputProps> = ({ content, publishedContent, empty }) => {
  const { environment } = useLivePreviewContext();

  if (environment === EnvironmentEnum.COMPARE)
    return <ContentReadonly content={publishedContent ?? {}} />;

  if (environment === EnvironmentEnum.MOBILE)
    return <ContentOutputMobile content={content} empty={empty} />;

  return <ContentOutputDesktop content={content} empty={empty} />;
};

export default ContentOutput;
