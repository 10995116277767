import { Close } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { useMemo } from 'react';

interface TagSelectProps<T> {
  selectedItems: T[];
  onSelectionChange: (items: T[]) => void;
  getItemId: (item: T) => any;
  getItemLabel: (item: T) => any;
  formatChipLabel?: (label: string) => any;
  readOnly?: boolean;
}

const TagSelect = <T extends unknown>({
  selectedItems,
  onSelectionChange,
  getItemId,
  getItemLabel,
  formatChipLabel = (label: string) => label,
  readOnly,
}: TagSelectProps<T>) => {
  const sortedItems = useMemo(
    () =>
      [...selectedItems].sort((a, b) => {
        const labelA = formatChipLabel(getItemLabel(a)).toLowerCase();
        const labelB = formatChipLabel(getItemLabel(b)).toLowerCase();
        return labelA.localeCompare(labelB);
      }),
    [selectedItems, getItemLabel, formatChipLabel],
  );

  const handleRemoveItem = (itemId: any) => {
    onSelectionChange(selectedItems.filter(item => getItemId(item) !== itemId));
  };

  return sortedItems.map(item => (
    <Chip
      key={getItemId(item)}
      label={formatChipLabel(getItemLabel(item))}
      onDelete={readOnly ? undefined : () => handleRemoveItem(getItemId(item))}
      deleteIcon={readOnly ? undefined : <Close sx={{ fill: 'white' }} />}
      sx={{ backgroundColor: '#9eacb0', color: 'white' }}
      variant='filled'
    />
  ));
};

export default TagSelect;
