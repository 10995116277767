import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { GroupApi } from '../../api/apis/GroupApi';
import { GroupData } from '../../models/GroupData';
import { GroupMetricsData } from '../../models/MetricsData';
import { GroupMetricsApi } from '../../api/apis/GroupMetricsApi';
import { UserRolesInitialized } from '../../predicates/UserRolesInitialized';

export interface GroupCompartments {
  group: DataCompartmentOptions<GroupData | undefined>;
  allGroups: DataCompartmentOptions<GroupData[] | undefined>;
  allMetrics: DataCompartmentOptions<GroupMetricsData[] | undefined>;
}

export const groupStorageKey = 'data/group';

export const withGroupData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<GroupCompartments>({
      group: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(UserRolesInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const groupApi = container.get<GroupApi>(ApiKeys.Group);
            const { data } = await groupApi.get();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allGroups: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(UserRolesInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const groupApi = container.get<GroupApi>(ApiKeys.Group);
            const { data } = await groupApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allMetrics: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(UserRolesInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const groupMetricsApi = container.get<GroupMetricsApi>(ApiKeys.GroupMetrics);
            const { data } = await groupMetricsApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(groupStorageKey, cache);
  },
);
