import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, LessonData, UnitData } from '../../../models/CourseData';
import { LessonApi } from '../../../api/apis/LessonApi';
import { findLessonSource, updateCourses } from './utils';

interface UpdateLessonPositionParams {
  lesson: LessonData;
  orderedLessons: LessonData[];
}

// move learning out of profile folder
// make sure to update the draft position to be the same as the published version
// send a list of active and original lessons with updated positions
export class UpdateLessonPosition implements IDataCommand<UpdateLessonPositionParams, LessonData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Lesson) private readonly api: LessonApi,
  ) {}

  async execute({ lesson, orderedLessons }: UpdateLessonPositionParams): Promise<LessonData> {
    let response: LessonData | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (lesson.unitId && lesson.lessonId && lesson.position) {
        const { courseIndex, unitIndex } = findLessonSource(lesson, courses);
        if (courseIndex !== -1 && typeof unitIndex !== 'undefined') {
          const newUnits = [...(courses[courseIndex].units as UnitData[])];
          const newUnit = newUnits[unitIndex];

          const { data: updatedLessons } = await this.api.updateLessonOrder(
            orderedLessons,
            lesson.unitId,
          );
          // find all lessons with the same original id and update them until the backend is hooked up
          newUnit.lessons = newUnit.lessons?.map(x => {
            const updatedLesson = updatedLessons.find(
              y => y.originalLessonId === x.originalLessonId,
            );
            if (typeof updatedLesson !== 'undefined') {
              return { ...x, position: updatedLesson.position };
            }

            return x;
          });
          // newUnit.lessons = data;
          updateCourses(newUnit, [...courses]);
        }

        return courses;
      }
      return courses;
    });

    return response as LessonData;
  }
}
