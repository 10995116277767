import { Checkbox, IconButton, Typography } from '@mui/material';
import { Svgs } from '../../../../../assets/svg';
import LivePreviewEditor, {
  LivePreviewEditorProps,
} from '../../../../../components/LivePreviewEditor';
import { useMemo } from 'react';
import { useQuizQuestionContext } from './QuizQuestionContext';
import Tippy from '@tippyjs/react';
import '../../../../../themes/tippy-rounded.css';

export const formatAnswerHtml = (html: string) => {
  const formattedHtml = `<div style="font-size: 16px; font-family: 'Roboto'; color: #1e2969; overflow-wrap: anywhere; min-width: fit-content;">${html}</div>`;
  return formattedHtml;
};

interface QuizAnswerInputProps extends LivePreviewEditorProps {
  correct?: boolean;
  questionId?: number;
}

const QuizAnswerInput: React.FC<QuizAnswerInputProps> = ({ editorKey, content, questionId }) => {
  const {
    removeAnswer,
    correctAnswerKeys,
    toggleCorrectAnswer,
    allOfTheAboveKey,
    noneOfTheAboveKey,
  } = useQuizQuestionContext();
  const correct = useMemo(() => {
    return correctAnswerKeys?.has(editorKey);
  }, [correctAnswerKeys, editorKey]);
  const disabled = useMemo(
    () => editorKey === allOfTheAboveKey || editorKey === noneOfTheAboveKey,
    [editorKey, allOfTheAboveKey, noneOfTheAboveKey],
  );

  return (
    <LivePreviewEditor
      editorKey={editorKey}
      content={content}
      disabled={disabled}
      placeholder='Add an answer'
      formatHtml={formatAnswerHtml}
      characterLimit={200}
      startComponent={
        <IconButton
          style={{ padding: '0px' }}
          disableRipple
          onClick={() => removeAnswer(editorKey)}>
          <Svgs.IconDelete />
        </IconButton>
      }
      endComponent={
        <Tippy
          placement='right'
          theme='tippy-rounded no-padding'
          content={
            <Typography variant='p16Light' color='secondary'>
              correct answer
            </Typography>
          }>
          <Checkbox
            style={{ padding: '0px' }}
            disableRipple
            onClick={() => toggleCorrectAnswer(editorKey)}
            checked={correct}
          />
        </Tippy>
      }
    />
  );
};

export default QuizAnswerInput;
