import { useSubject } from '@aesop-fables/scrinium';
import { LmsPermission } from '../api/apis/LmsPermissionApi';
import { LmsPermissionSubject } from '../data/learning/subjects/LmsPermissionSubject';
import { LmsEnabled } from '../predicates/LmsEnabled';

export const useLmsPermission = () => {
  const permission = useSubject<LmsPermission>(LmsPermissionSubject);
  return permission;
};

export const useLmsEnabled = () => {
  const permission = useSubject<boolean>(LmsEnabled);
  return permission;
};
