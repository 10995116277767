import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';
import { Button, Typography } from '@mui/material';

const CoursesHeader = () => {
  const navigate = useNavigate();

  const createCourse = () => {
    navigate('/drill-down/courses/new');
  };

  return (
    <Row style={{ justifyContent: 'space-between' }}>
      <Row style={{ columnGap: '25px', alignItems: 'center' }}>
        <Svgs.ArtUniversity />
        <Typography variant='p30' color='secondary.main'>
          Courses
        </Typography>
      </Row>
      <Button onClick={createCourse}>Create new course</Button>
    </Row>
  );
};

export default CoursesHeader;
