import { TableCell, IconButton, Button, TableRow, Link, Typography } from '@mui/material';
import { Svgs } from '../../assets/svg';
import Row from '../Row';
import Spacer from '../Spacer';
import { DataTableHeader, DataTableRowData, DataTableType } from './types';
import { isPrimitive } from '../../helpers/utilityFunctions';

interface DataTableRowProps {
  headers: DataTableHeader[];
  row: DataTableRowData;
  index: number;
  cellVariant?: 'outlined';
  type?: DataTableType;
  firstColumnStyles?: any;
  onDeleteClick?: (row: any) => void;
  onButton1Click?: (row: any) => void;
  onButton2Click?: (row: any) => void;
  button1Text?: string | ((val: any) => string);
  button2Text?: string | ((val: any) => string);
  button1Disabled?: boolean;
  button2Disabled?: boolean;
  action2?: (entry: any) => void;
  setActiveEntry?: (row: any) => void;
  setActiveEntry2?: (row: any) => void;
  atLeastOneRowIsDeletable: boolean;
  lastColumnIsButtons: boolean;
}

export const DataTableRow = ({
  headers,
  row,
  index,
  cellVariant,
  type,
  firstColumnStyles,
  onDeleteClick,
  onButton1Click,
  onButton2Click,
  button1Text,
  button2Text,
  button1Disabled,
  button2Disabled,
  action2,
  setActiveEntry,
  setActiveEntry2,
  atLeastOneRowIsDeletable,
  lastColumnIsButtons,
}: DataTableRowProps) => {
  const button1TextValue = typeof button1Text === 'function' ? button1Text(row) : button1Text;
  const button2TextValue = typeof button2Text === 'function' ? button2Text(row) : button2Text;

  const renderDeleteButton = () => {
    if (!atLeastOneRowIsDeletable) return null;
    if (!row.deletableRow || row.isDefault) {
      return <TableCell sx={{ width: '50px' }}></TableCell>;
    }
    return (
      <TableCell sx={{ width: '50px', padding: 0 }}>
        <IconButton color='primary' onClick={() => onDeleteClick?.(row)}>
          <Svgs.IconDelete />
        </IconButton>
      </TableCell>
    );
  };

  const renderFirstColumn = (entry: any) => {
    if (type === 'licenseAgreement' && action2 && setActiveEntry) {
      return (
        <Row style={{ alignItems: 'center' }}>
          {entry && getLicenseArt(entry)}
          <Spacer width='xxxs' />
          <Link
            onClick={() => {
              setActiveEntry(row);
              action2(true);
            }}>
            {entry}
          </Link>
        </Row>
      );
    }
    return entry;
  };

  const renderActionButtons = () => {
    if (row.isDefault) {
      return (
        <TableCell variant='hasButton'>
          <Row
            style={{
              display: 'inline-block',
              minWidth: '120px',
              justifySelf: 'flex-end',
            }}>
            <Row style={{ justifyContent: 'center' }}>
              <Typography variant='p16Bold' color='primary'>
                Default
              </Typography>
            </Row>
          </Row>
        </TableCell>
      );
    }

    if (!lastColumnIsButtons || !onButton1Click || row.isDefault) {
      return null;
    }

    return (
      <TableCell variant='hasButton'>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            ...(onButton2Click ? undefined : { justifyContent: 'flex-end' }),
          }}>
          {button1TextValue && onButton1Click && (
            <Button
              onClick={() => onButton1Click(row)}
              disabled={button1Disabled}
              sx={{ typography: { fontSize: 14 }, minWidth: 'fit-content' }}
              variant='outlined'
              color='primary'>
              {button1TextValue}
            </Button>
          )}
          {onButton2Click && button2Text && setActiveEntry2 && (
            <>
              <Spacer width='xxs' />
              <Button
                onClick={() => {
                  setActiveEntry2(row);
                  onButton2Click(true);
                }}
                disabled={button2Disabled}
                sx={{ typography: { fontSize: 14 }, minWidth: 'fit-content' }}
                color='primary'>
                {button2TextValue}
              </Button>
            </>
          )}
        </Row>
      </TableCell>
    );
  };

  const getLicenseArt = (entry: any) => {
    const productName = entry?.toString();
    if (productName.includes('GuidedChoice Advisory Service')) return <Svgs.PackageGcAdviceSmall />;
    if (productName.includes('3Nickels Advice')) return <Svgs.Package3nAdviceSmall />;
    if (productName.includes('3Nickels Free')) return <Svgs.Package3nFreeSmall />;
    if (productName.includes('Advice + 3NickelsU')) return <Svgs.Package3nUSmall />;
  };

  return (
    <TableRow key={index}>
      {renderDeleteButton()}

      {Object.entries(row)
        .filter(([key]) => headers.some(header => header.label === key)) // only include fields that match column headers
        .map(([key, entry], j) => (
          <TableCell key={j} variant={cellVariant} sx={j === 0 ? firstColumnStyles : undefined}>
            {j === 0 ? renderFirstColumn(entry) : isPrimitive(entry) ? entry : entry}
          </TableCell>
        ))}

      {renderActionButtons()}
    </TableRow>
  );
};
