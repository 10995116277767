import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { QuizQuestion } from '../../models/CourseData';

export class QuizQuestionApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getQuestion(id: number): Promise<AxiosResponse<QuizQuestion>> {
    return this.axios.get(`question/${id}`);
  }

  getQuestionByQuizId(quizId: number): Promise<AxiosResponse<QuizQuestion>> {
    return this.axios.get(`question/by-quiz?QuizId=${quizId}`);
  }

  createQuestion(question: QuizQuestion): Promise<AxiosResponse<QuizQuestion>> {
    return this.axios.post('question', question);
  }

  updateQuestion(question: QuizQuestion): Promise<AxiosResponse<QuizQuestion>> {
    return this.axios.patch('question', question);
  }

  deleteQuestion(id: number): Promise<AxiosResponse<QuizQuestion>> {
    return this.axios.delete(`question/${id}`);
  }
}
