import { OrganizationMetadataApi } from './apis/OrganizationMetadataApi';
import { AdminPaymentApi } from './apis/AdminPaymentApi';
import { ApiKeys } from './apis/ApiKeys';
import { ChargeApi } from './apis/ChargeApi';
import { CompanyPayrollDeductionApi } from './apis/CompanyPayrollDeductionApi';
import { Scopes, createServiceModule } from '@aesop-fables/containr';
import { GooglePlacesApi } from './apis/GooglePlacesApi';
import { GroupApi } from './apis/GroupApi';
import { GroupFeatureMapApi } from './apis/GroupFeatureMapApi';
import { GroupMetricsApi } from './apis/GroupMetricsApi';
import { GroupSelectionApi } from './apis/GroupSelectionApi';
import { LicenseAgreementAcceptLogApi } from './apis/LicenseAgreementAcceptLogApi';
import { LicenseAgreementApi } from './apis/LicenseAgreementApi';
import { LicenseMetricsApi } from './apis/LicenseMetricsApi';
import { MembersApi } from './apis/MembersApi';
import { MendixClientApi } from './apis/MendixClientApi';
import { OffboardingTemplateApi } from './apis/OffboardingTemplateApi';
import { OnboardingTemplateApi } from './apis/OnboardingTemplateApi';
import { OrganizationApi } from './apis/OrganizationApi';
import { OrgPricebookLicenseMetrics } from './apis/OrgPricebookLicenseMetricsApi';
import { OrgSelectionApi } from './apis/OrgSelectionApi';
import { OrgSignUpStateApi } from './apis/OrgSignUpStateApi';
import { PlanApi } from './apis/PlanApi';
import { PricebookApi } from './apis/PricebookApi';
import { RestrictionApi } from './apis/RestrictionApi';
import { SecuritiesApi } from './apis/SecuritiesApi';
import { SubscriptionApi } from './apis/SubscriptionApi';
import { TeamApi } from './apis/TeamApi';
import { TeamMetricsApi } from './apis/TeamMetricsApi';
import { TeamSelectionApi } from './apis/TeamSelectionApi';
import { TermsApi } from './apis/TermsApi';
import { TrustFamilyApi } from './apis/TrustFamilyApi';
import { AuthUserApi, UserApi } from './apis/UserApi';
import { InvoiceApi } from './apis/InvoiceApi';
import { CustomerOrganizationApi } from './apis/CustomerOrganizationApi';
import { ReportApi } from './apis/ReportApi';
import { CourseApi } from './apis/CourseApi';
import { LessonApi } from './apis/LessonApi';
import { QuizApi } from './apis/QuizApi';
import { UnitApi } from './apis/UnitApi';
import { LessonCategoryApi } from './apis/LessonCategoryApi';
import { LessonContentApi } from './apis/LessonContentApi';
import { QuizQuestionApi } from './apis/QuizQuestionApi';
import { MediaApi } from './apis/MediaApi';
import { LmsPermissionApi } from './apis/LmsPermissionApi';

export const ipmApisKey = 'api/ipm';

export const useIpmApis = createServiceModule(ipmApisKey, services => {
  services.autoResolve(ApiKeys.AdminPayment, AdminPaymentApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Charge, ChargeApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Course, CourseApi, Scopes.Transient);
  services.autoResolve(ApiKeys.PayrollDeduction, CompanyPayrollDeductionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.GooglePlaces, GooglePlacesApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Group, GroupApi, Scopes.Transient);
  services.autoResolve(ApiKeys.GroupFeatureMap, GroupFeatureMapApi, Scopes.Transient);
  services.autoResolve(ApiKeys.GroupMetrics, GroupMetricsApi, Scopes.Transient);
  services.autoResolve(ApiKeys.GroupSelection, GroupSelectionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Invoice, InvoiceApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Lesson, LessonApi, Scopes.Transient);
  services.autoResolve(ApiKeys.LessonCategory, LessonCategoryApi, Scopes.Transient);
  services.autoResolve(ApiKeys.LessonContent, LessonContentApi, Scopes.Transient);
  services.autoResolve(ApiKeys.LicenseAgreement, LicenseAgreementApi, Scopes.Transient);
  services.autoResolve(
    ApiKeys.LicenseAgreementAcceptLog,
    LicenseAgreementAcceptLogApi,
    Scopes.Transient,
  );
  services.autoResolve(ApiKeys.LicenseMetrics, LicenseMetricsApi, Scopes.Transient);
  services.autoResolve(ApiKeys.LmsPermission, LmsPermissionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Media, MediaApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Members, MembersApi, Scopes.Transient);
  services.autoResolve(ApiKeys.MendixClient, MendixClientApi, Scopes.Transient);
  services.autoResolve(ApiKeys.OffboardingTemplate, OffboardingTemplateApi, Scopes.Transient);
  services.autoResolve(ApiKeys.OnboardingTemplate, OnboardingTemplateApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Organization, OrganizationApi, Scopes.Transient);
  services.autoResolve(ApiKeys.OrganizationMetadata, OrganizationMetadataApi, Scopes.Transient);
  services.autoResolve(ApiKeys.CustomerOrganization, CustomerOrganizationApi, Scopes.Transient);
  services.autoResolve(
    ApiKeys.OrgPricebookLicenseMetrics,
    OrgPricebookLicenseMetrics,
    Scopes.Transient,
  );
  services.autoResolve(ApiKeys.OrgSelection, OrgSelectionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.OrgSignUpState, OrgSignUpStateApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Plan, PlanApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Pricebook, PricebookApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Quiz, QuizApi, Scopes.Transient);
  services.autoResolve(ApiKeys.QuizQuestion, QuizQuestionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Report, ReportApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Restriction, RestrictionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Securities, SecuritiesApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Subscription, SubscriptionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Team, TeamApi, Scopes.Transient);
  services.autoResolve(ApiKeys.TeamMetrics, TeamMetricsApi, Scopes.Transient);
  services.autoResolve(ApiKeys.TeamSelection, TeamSelectionApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Terms, TermsApi, Scopes.Transient);
  services.autoResolve(ApiKeys.TrustFamily, TrustFamilyApi, Scopes.Transient);
  services.autoResolve(ApiKeys.Unit, UnitApi, Scopes.Transient);
  services.autoResolve(ApiKeys.User, UserApi, Scopes.Transient);
  services.autoResolve(ApiKeys.AuthUserApi, AuthUserApi, Scopes.Transient);
});
