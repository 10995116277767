import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { CategoryData } from '../../models/CourseData';

export class LessonCategoryApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getAll(): Promise<AxiosResponse<CategoryData[]>> {
    return this.axios.get('category');
  }
}
