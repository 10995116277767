import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { MemberData, MembersApi } from '../../api/apis/MembersApi';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface MemberCompartments {
  members: DataCompartmentOptions<MemberData[]>;
}

export const membersStorageKey = 'data/members';

export const withMemberData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<MemberCompartments>({
      members: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const membersApi = container.get<MembersApi>(ApiKeys.Members);
          const { data } = await membersApi.getMembers();
          return data;
        }),
        defaultValue: [],
      },
    });

    appStorage.store(membersStorageKey, cache);
  },
);
