import { Grid } from '@mui/material';
import LivePreview from '../../../../../components/LivePreview';
import { useParams } from 'react-router';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FindDraftByLessonId } from '../../../../../data/learning/queries/FindDraftByLesson';

import { useNavigate } from 'react-router-dom';
import AddButton from '../../../../../components/AddButton';
import { ToastbarContext } from '../../../../../App';
import { Toast } from '../../../../../models/Toast';
import {
  EnvironmentEnum,
  defaultEnvironmentOptions,
} from '../../../../../components/LivePreviewContext';
import QuizQuestionPreview from './QuizQuestionPreview';
import { FindActiveLessonById } from '../../../../../data/learning/queries/FindActiveLessonById';

export const quizQuestionKeyString = 'lesson-quiz/question';
export const quizAnswerKeyString = 'lesson-quiz/answer';

interface QuizEditViewProps {
  editing?: boolean;
}

const QuizEditView: React.FC<QuizEditViewProps> = ({ editing }) => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const params = useParams();
  const { unitId, originalLessonId } = useMemo(
    () => ({
      courseId: parseInt(params.courseId ?? ''),
      unitId: parseInt(params.unitId ?? ''),
      originalLessonId: parseInt(params.originalLessonId ?? ''),
    }),
    [params.courseId, params.originalLessonId, params.unitId],
  );
  const publishedLesson = useObservableQuery(FindActiveLessonById, { unitId, originalLessonId });
  const draft = useObservableQuery(FindDraftByLessonId, { unitId, originalLessonId });
  const [activeQuestionId, setActiveQuestionId] = useState<number | undefined>();
  const [newQuestionActive, setNewQuestionActive] = useState<boolean>(false);

  const questions = useMemo(() => draft?.quiz?.questions ?? [], [draft?.quiz?.questions]);
  const empty = useMemo(
    () => !draft?.quiz || (draft?.quiz?.questions?.length ?? 0) === 0,
    [draft?.quiz],
  );
  const environmentOptions = useMemo(
    () => ({
      ...defaultEnvironmentOptions,
      [EnvironmentEnum.COMPARE]: typeof publishedLesson !== 'undefined',
    }),
    [publishedLesson],
  );

  useEffect(() => {
    if (!draft?.quiz || draft?.quiz?.questions?.length === 0) {
      setNewQuestionActive(true);
    } else {
      setNewQuestionActive(false);
    }
  }, [draft?.quiz]);

  const setEditing = useCallback(
    (questionId?: number) => {
      if (
        (typeof activeQuestionId !== 'undefined' &&
          typeof questionId !== 'undefined' &&
          activeQuestionId !== questionId) ||
        newQuestionActive
      ) {
        setToast(
          new Toast({
            severity: 'error',
            open: true,
            message: 'Please finish editing the current question',
          }),
        );
      } else {
        setActiveQuestionId(questionId);
      }
    },
    [activeQuestionId, newQuestionActive, setToast],
  );

  const addNewQuestion = useCallback(() => {
    if (typeof activeQuestionId !== 'undefined' || newQuestionActive) {
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Please finish editing the current question',
        }),
      );
    } else {
      setNewQuestionActive(true);
    }
  }, [activeQuestionId, newQuestionActive, setToast]);

  return (
    <LivePreview
      environmentOptions={environmentOptions}
      headerProps={{
        title: editing ? 'Draft' : 'Published',
        onDone: !activeQuestionId && !newQuestionActive ? () => navigate(-1) : undefined,
      }}>
      <Grid display='flex' flexDirection='column'>
        {draft?.quiz?.questions?.map((question, index) => (
          <QuizQuestionPreview
            key={question.questionId}
            index={index}
            question={question}
            publishedQuestion={publishedLesson?.quiz?.questions?.at(index)}
            lessonId={draft?.lessonId}
            disabled={!editing}
            editing={activeQuestionId === question.questionId}
            setEditing={setEditing}
          />
        ))}
        {newQuestionActive && (
          <QuizQuestionPreview
            index={questions.length ?? 0}
            question={{ quizId: draft?.quiz?.quizId }}
            lessonId={draft?.lessonId}
            disabled={!editing}
            editing={true}
            setEditing={() => setNewQuestionActive(false)}
            empty={empty}
          />
        )}
        <Grid className='live-preview-content' key={`add-new-content`}>
          <Grid className='draft'>
            <AddButton content='Add new question' onClick={addNewQuestion} />
          </Grid>
          <Grid className='preview' />
        </Grid>
      </Grid>
    </LivePreview>
  );
};

export default QuizEditView;
