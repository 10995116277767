import { Route, Routes } from 'react-router-dom';
import { combineLatest, map } from 'rxjs';
import { PlanData } from '../../models/PlanData';
import { TrustFamilyData } from '../../api/apis/TrustFamilyApi';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { PlanCompartments, planStorageKey } from '../../data/plan';
import { TrustFamilyCompartments, trustFamilyStorageKey } from '../../data/trustFamily';
import { Loading } from '../../hooks/useLoading';
import EmptyPlan from './EmptyPlan';
import Plan from './Plan';

const PlanInfoDashboard = () => {
  const appStorage = useAppStorage();
  const trustFamilyDataCache = appStorage.retrieve<TrustFamilyCompartments>(trustFamilyStorageKey);
  const trustFamilyData = useObservable(
    trustFamilyDataCache.observe$<TrustFamilyData[]>('trustFamilyData'),
  );
  const trustFamily = trustFamilyData?.at(0);
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const initialized = useObservable(
    combineLatest([
      trustFamilyDataCache.findCompartment('trustFamilyData').initialized$,
      planDataCache.findCompartment('plan').initialized$,
    ]).pipe(
      map(([trustFamilyInitialized, planInitialized]) => trustFamilyInitialized && planInitialized),
    ),
  );

  return (
    <Routes>
      <Route
        path='/'
        element={initialized ? trustFamily && plan ? <Plan /> : <EmptyPlan /> : <Loading />}
      />
    </Routes>
  );
};

export default PlanInfoDashboard;
