import React, { useState } from 'react';
import { Box, SxProps, Theme, useTheme, Typography } from '@mui/material';
import { MediaData } from '../models/MediaData';
import { API_URL } from '../api/apis';

interface MediaImageProps {
  media?: MediaData;
  containerSx?: SxProps<Theme>;
  imgStyle?: React.CSSProperties;
}

const MediaImage: React.FC<MediaImageProps> = ({ media, containerSx, imgStyle }) => {
  const theme = useTheme();
  const [hasError, setHasError] = useState<boolean>(false);

  if (!media) return null;

  if (hasError) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.secondary.disabled,
          borderRadius: 2,
          aspectRatio: '1/1',
          ...containerSx,
        }}>
        <Typography
          variant='p16SemiBold'
          sx={{
            color: theme.palette.info.main,
            textAlign: 'center',
            width: '50%',
          }}>
          Image not found
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...containerSx,
      }}>
      <img
        src={`${API_URL}media/${media.id}`}
        alt={media.mediaName}
        onError={() => setHasError(true)}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          ...imgStyle,
        }}
        crossOrigin='use-credentials'
      />
    </Box>
  );
};

export default MediaImage;
