import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Svgs } from '../../../../../assets/svg';
import { formatAnswerHtml } from './QuizAnswerInput';
import { QuizInputProps } from './QuizInput';

interface QuizQuestionReadonlyProps extends Omit<QuizInputProps, 'index'> {}

const QuizQuestionReadonly: React.FC<QuizQuestionReadonlyProps> = ({ question }) => {
  if (!question.questionId) return null;

  return (
    <Card
      id={question.questionId?.toString()}
      variant='outlined'
      style={{ borderColor: '#d4ebf2' }}>
      <CardContent className='pad20'>
        <Grid display='flex' flexDirection='column' rowGap='20px'>
          <Typography className='lesson-content-title' color='secondary' variant='p18SemiBold'>
            <div dangerouslySetInnerHTML={{ __html: question.text ?? '' }} />
          </Typography>
          <Grid display='flex' flexDirection='column' rowGap='10px'>
            {question.answers?.map((answer, index) => {
              return (
                <Grid display='flex' columnGap='10px'>
                  <Svgs.IconCheckmark style={!answer.isCorrect ? { opacity: 0 } : {}} />
                  <Typography className='lesson-content-copy' variant='p16Light' color='secondary'>
                    <div
                      style={{ display: 'flex', columnGap: '5px', alignItems: 'start' }}
                      dangerouslySetInnerHTML={{
                        __html: `${formatAnswerHtml(
                          String.fromCharCode(97 + index).toUpperCase() + '.',
                        )}${answer.text}`,
                      }}
                    />
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuizQuestionReadonly;
