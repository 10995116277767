export enum ValidationMessages {
  FILE_REQUIREMENTS = "File doesn't meet upload requirements",
}

export interface ValidationResult {
  isValid: boolean;
  errors?: string[];
}

export const formatValidationErrors = (
  errors: string[],
  multipleErrorsMessage: ValidationMessages,
): string => {
  if (errors.length === 0) return '';
  if (errors.length === 1) return errors[0];
  return multipleErrorsMessage;
};
