import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { groupStorageKey, GroupCompartments } from '../data/group';
import { Predicates } from './Types';
import { GroupData } from '../models/GroupData';

@predicate(Predicates.UserInitialized)
@predicate(Predicates.UserRolesInitialized)
export class GroupSelected implements Predicate {
  constructor(
    @injectDataCache(groupStorageKey) private readonly groupDataCache: DataCache<GroupCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.groupDataCache
      .observe$<GroupData>('group')
      .pipe(map(groupData => typeof groupData !== 'undefined'));
  }
}
