import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface ITabContext {
  activeTab: number;
  setActiveTab: (tab: number) => void;
}

const TabContext = createContext<ITabContext>({
  activeTab: 0,
  setActiveTab: (tab: number) => {},
});

export const TabProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return <TabContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabContext.Provider>;
};

export function useTabContext() {
  const context = useContext(TabContext);

  if (!context) {
    throw new Error('useTabContext must be used within TabProvider');
  }

  return context;
}
