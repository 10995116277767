import { MediaData } from './MediaData';

export interface CourseData {
  courseId?: number;
  name?: string;
  icon?: MediaData | null;
  background?: MediaData | null;
  lastEditedTimestamp?: string;
  publishedTimestamp?: string;
  originalCourseId?: number;
  status?: string;
  active?: boolean;
  deleted?: boolean;
  units?: UnitData[];
  drafts?: CourseData[];
}

export enum AnswerTypeEnum {
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}

export enum PublicationStatusEnum {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export interface UnitData {
  unitId?: number;
  sourceCourseId?: number;
  name?: string;
  icon?: MediaData | null;
  background?: MediaData | null;
  position?: number;
  lastEditedTimestamp?: string;
  publishedTimestamp?: string;
  originalUnitId?: number;
  status?: PublicationStatusEnum;
  active?: boolean;
  deleted?: boolean;
  lessons?: LessonData[];
  drafts?: UnitData[];
}

export interface LessonData {
  lessonId?: number;
  unitId?: number;
  name?: string;
  icon?: MediaData | null;
  position?: number;
  category?: string;
  lastEditedTimestamp?: string;
  publishedTimestamp?: string;
  originalLessonId?: number;
  status?: PublicationStatusEnum;
  active?: boolean;
  deleted?: boolean;
  contents?: LessonContent[];
  quiz?: QuizData;
  categories?: CategoryData[];
}

export interface CategoryData {
  categoryId?: number;
  category?: string;
}

export interface LessonContent {
  contentId?: number;
  title?: string;
  contentCopy?: string;
  icon?: MediaData | null;
  position?: number;
  lessonId?: number;
  lastEditedTimestamp?: string;
  deleted?: boolean;
}

export interface QuizData {
  quizId?: number;
  lessonId?: number;
  name?: string;
  icon?: MediaData | null;
  background?: MediaData | null;
  lastEditedTimestamp?: string;
  questions?: QuizQuestion[];
  deleted?: boolean;
}

export interface QuizQuestion {
  questionId?: number;
  quizId?: number;
  text?: string;
  answerType?: AnswerTypeEnum;
  lastEditedTimestamp?: string;
  deleted?: boolean;
  answers?: QuizAnswer[];
}

export interface QuizAnswer {
  answerId?: number;
  questionId?: number;
  text?: string;
  lastEditedTimestamp?: string;
  anchorBottomPosition?: number; // 1 for all of the above, 2 for none of the above, undefined for all others
  isCorrect?: boolean;
  deleted?: boolean;
}
