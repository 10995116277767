import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { teamStorageKey, TeamCompartments } from '../data/team';
import { Predicates } from './Types';
import { TeamData } from '../models/TeamData';

@predicate(Predicates.UserInitialized)
@predicate(Predicates.UserRolesInitialized)
@predicate(Predicates.GroupSelected)
export class TeamSelected implements Predicate {
  constructor(
    @injectDataCache(teamStorageKey) private readonly teamDataCache: DataCache<TeamCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.teamDataCache
      .observe$<TeamData>('team')
      .pipe(map(teamData => typeof teamData !== 'undefined'));
  }
}
