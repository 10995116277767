import { convertDollarToNumber, isDate } from './utilityFunctions';

const tierOrder = ['Group', 'Team', 'Organization', 'Root'] as const;
const statusOrder = ['Active', 'Invited', 'Removed'] as const;
const invoiceOrder = ['Open', 'Paid', 'Uncollectible', 'Void'] as const;

const toString = (value: any): string => String(value ?? '').toLowerCase();

const compareValues = (a: any, b: any): number => {
  const strA = toString(a);
  const strB = toString(b);
  return strB.localeCompare(strA);
};

const getOrder = <T extends readonly string[]>(orderArray: T, value: any): number => {
  const str = toString(value);
  const index = orderArray.findIndex(item => str.includes(item.toLowerCase()));
  return index === -1 ? Infinity : index;
};

const sortByDate = (a: any, b: any): number => {
  return new Date(a).getTime() - new Date(b).getTime();
};

const sortDollars = (a: any, b: any) => {
  return convertDollarToNumber(a) - convertDollarToNumber(b);
};

const sortByInvoiceStatus = (a: any, b: any): number => {
  const orderA = getOrder(invoiceOrder, a);
  const orderB = getOrder(invoiceOrder, b);
  return orderB - orderA;
};

const sortByAdminStatus = (a: any, b: any): number => {
  const orderA = getOrder(statusOrder, a);
  const orderB = getOrder(statusOrder, b);
  return orderB - orderA;
};

const sortByTier = (a: any, b: any): number => {
  const orderA = getOrder(tierOrder, a);
  const orderB = getOrder(tierOrder, b);
  return orderA === orderB ? compareValues(a, b) : orderB - orderA;
};

const sortAdminStatusThenDate = (a: any, b: any): number => {
  const wordsA = a.status.split(' ');
  const wordsB = b.status.split(' ');

  const statusA = wordsA.find((word: any) => statusOrder.includes(word as any));
  const statusB = wordsB.find((word: any) => statusOrder.includes(word as any));
  const dateA = wordsA.find((word: any) => isDate(word));
  const dateB = wordsB.find((word: any) => isDate(word));

  return sortByAdminStatus(statusA, statusB) || sortByDate(dateA, dateB);
};

export {
  sortByDate,
  sortDollars,
  sortByInvoiceStatus,
  sortByAdminStatus,
  sortByTier,
  sortAdminStatusThenDate,
};
