import { useListener } from '@aesop-fables/scrinium';
import {
  AllOrgSignUpStatesListener,
  OnboardingTemplateListener,
  OrgSignUpStateListener,
} from '../data/signUp/listeners';
import {
  AllOrganizationMetricsListener,
  AllOrganizationsListener,
  OrganizationListener,
} from '../data/organization/listeners';
import {
  GroupAdminsListener,
  TeamAdminsListener,
  OrgAdminsListener,
} from '../data/admin/listeners';
import { CustomerOrganizationListener } from '../data/customerOrganization/listeners';
import { DeductionsListener } from '../data/deductions/listeners';
import { GroupListener, AllGroupsListener, AllGroupMetricsListener } from '../data/group/listeners';
import { GroupFeatureMapListener } from '../data/groupFeatureMap/listeners';
import { InvoicesListener } from '../data/invoices/listeners';
import { CoursesListener } from '../data/learning/listeners';
import { LicenseAgreementListener, LicenseMetricsListener } from '../data/license/listeners';
import { LicenseAgreementAcceptLogListener } from '../data/licenseAgreementAcceptLog/listeners';
import { MembersListener } from '../data/members/listeners';
import { MendixClientListener } from '../data/mendixClient/listeners';
import { GroupMetricsListener, TeamMetricsListener } from '../data/metrics/listeners';
import { OffboardingTemplateListener } from '../data/offboarding/listeners';
import { OrganizationMetadataListener } from '../data/organizationMetadata/listeners';
import {
  PaymentMethodsListener,
  DefaultPaymentMethodListener,
  PaymentHistoryListener,
  StripeSessionStatusListener,
  PaidStatusListener,
} from '../data/payment/listeners';
import { PricebookOptionsListener, PricebookSelectionsListener } from '../data/pricebook/listeners';
import { ProductTypeListener, PaidByListener } from '../data/productType/listeners';
import { RestrictionsListener } from '../data/restrictions/listeners';
import { SubscriptionListener } from '../data/subscription/listeners';
import { TeamListener, AllTeamListener, AllTeamMetricsListener } from '../data/team/listeners';
import { TrustFamilyListener } from '../data/trustFamily/listeners';

export const useCompartmentListeners = () => {
  useListener(AllOrgSignUpStatesListener);
  useListener(AllOrganizationMetricsListener);
  useListener(AllOrganizationsListener);
  useListener(OrganizationListener);
  useListener(GroupAdminsListener);
  useListener(TeamAdminsListener);
  useListener(OrgAdminsListener);
  useListener(CustomerOrganizationListener);
  useListener(DeductionsListener);
  useListener(GroupListener);
  useListener(AllGroupsListener);
  useListener(AllGroupMetricsListener);
  useListener(GroupFeatureMapListener);
  useListener(InvoicesListener);
  useListener(CoursesListener);
  useListener(LicenseAgreementListener);
  useListener(LicenseMetricsListener);
  useListener(LicenseAgreementAcceptLogListener);
  useListener(MembersListener);
  useListener(MendixClientListener);
  useListener(GroupMetricsListener);
  useListener(TeamMetricsListener);
  useListener(OffboardingTemplateListener);
  useListener(OrganizationMetadataListener);
  useListener(PaymentMethodsListener);
  useListener(DefaultPaymentMethodListener);
  useListener(PaymentHistoryListener);
  useListener(StripeSessionStatusListener);
  useListener(PaidStatusListener);
  useListener(PricebookOptionsListener);
  useListener(PricebookSelectionsListener);
  useListener(ProductTypeListener);
  useListener(PaidByListener);
  useListener(RestrictionsListener);
  useListener(OnboardingTemplateListener);
  useListener(OrgSignUpStateListener);
  useListener(SubscriptionListener);
  useListener(TeamListener);
  useListener(AllTeamListener);
  useListener(AllTeamMetricsListener);
  useListener(TrustFamilyListener);
};
