export const Predicates = {
  IsAuthenticated: 'Predicates/IsAuthenticated',
  GroupFeatureMapInitialized: 'Predicates/GroupFeatureMapInitialized',
  GroupSelected: 'Predicates/GroupSelected',
  LicenseAgreementInitialized: 'Predicates/LicenseAgreementInitialized',
  LmsEnabled: 'Predicates/LmsEnabled',
  OrganizationSelected: 'Predicates/OrganizationSelected',
  PricebooksSelected: 'Predicates/PricebooksSelected',
  SubscriptionInitialized: 'Predicates/SubscriptionInitialized',
  TeamSelected: 'Predicates/TeamSelected',
  UserInitialized: 'Predicates/UserInitialized',
  UserRolesInitialized: 'Predicates/UserRolesInitialized',
};
