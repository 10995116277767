import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { UserCompartments, userStorageKey } from '../data/user';
import { Predicates } from './Types';

@predicate(Predicates.IsAuthenticated)
export class UserRolesInitialized implements Predicate {
  constructor(
    @injectDataCache(userStorageKey) private readonly userDataCache: DataCache<UserCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.userDataCache.findCompartment('userRoles').initialized$;
  }
}
