import { CompanyPayrollDeductionApi } from '../../api/apis/CompanyPayrollDeductionApi';
import type { IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { firstValueFrom } from 'rxjs';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { PayrollDeductionData } from '../../api/apis/CompanyPayrollDeductionApi';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { GroupFeatureMapInitialized } from '../../predicates/GroupFeatureMapInitialized';

export interface PayrollDeductionCompartments {
  deductions: DataCompartmentOptions<PayrollDeductionData[] | undefined>;
}

export const payrollDeductionStorageKey = 'data/deductions';

export const withPayrollDeductionData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<PayrollDeductionCompartments>({
      deductions: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(GroupFeatureMapInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          const payrollDeductionApi = container.get<CompanyPayrollDeductionApi>(
            ApiKeys.PayrollDeduction,
          );
          const groupFeatureMapDataCache =
            appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
          const groupFeatureMap = await firstValueFrom(
            groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
          );
          if (groupFeatureMap.enableCompanyPayrollInfo) {
            const { data } = await payrollDeductionApi.get();
            if (data) {
              return [...data];
            }
          }
          return undefined;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(payrollDeductionStorageKey, cache);
  },
);
