export interface UserData {
  id?: number;
  firstName?: string;
  lastName?: string;
  role?: string;
  email?: string;
  status?: AdminStatusEnum;
  statusTimestamp?: string;
  primaryBillingUser?: boolean;
}

export enum AdminStatusEnum {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Removed = 'REMOVED',
}

export interface UserRole {
  role?: string;
}

export interface LoginData {
  email?: string;
  password?: string;
}
