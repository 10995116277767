import { ApiKeys } from '../../api/apis/ApiKeys';
import { PricebookApi } from '../../api/apis/PricebookApi';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { PricebookBundle } from '../../models/PricebookData';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface PricebookCompartments {
  pricebookOptions: DataCompartmentOptions<PricebookBundle[] | undefined>;
  pricebookSelections: DataCompartmentOptions<PricebookBundle[] | undefined>;
}

export const pricebookStorageKey = 'data/pricebook';

export const withPricebookData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<PricebookCompartments>({
      pricebookOptions: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const pricebookApi = container.get<PricebookApi>(ApiKeys.Pricebook);
          const { data } = await pricebookApi.getAvailable();
          return data;
        }),
        defaultValue: undefined,
      },
      pricebookSelections: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const pricebookApi = container.get<PricebookApi>(ApiKeys.Pricebook);
          const selected = await pricebookApi.getSelected();
          if (selected.data.length === 0) {
            return [];
          }
          const { data } = await pricebookApi.getSelections();
          return data;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(pricebookStorageKey, cache);
  },
);
