import { Grid, Portal } from '@mui/material';
import React from 'react';
import LivePreviewHeader, { LivePreviewHeaderProps } from './LivePreviewHeader';
import { LivePreviewProvider, LivePreviewProviderProps } from './LivePreviewContext';
import { subheaderElementId } from './TopNavBar';

interface LivePreviewProps extends LivePreviewProviderProps {
  headerProps?: Omit<LivePreviewHeaderProps, 'environment' | 'setEnvironment'>;
}

const LivePreview: React.FC<LivePreviewProps> = ({ headerProps, children, ...rest }) => {
  return (
    <LivePreviewProvider {...rest}>
      <Portal container={document.getElementById(subheaderElementId)}>
        <LivePreviewHeader {...headerProps} />
      </Portal>
      <Grid className='live-preview'>{children}</Grid>
    </LivePreviewProvider>
  );
};

export default LivePreview;
