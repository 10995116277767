import { ApiKeys } from '../../api/apis/ApiKeys';
import {
  LicenseAgreementAcceptLogApi,
  LicenseAgreementAcceptLogData,
} from '../../api/apis/LicenseAgreementAcceptLogApi';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { LicenseCompartments, licenseStorageKey } from '../license';
import { LicenseAgreementInitialized } from '../../predicates/LicenseAgreementInitialized';

export interface licenseAgreementAcceptLogCompartments {
  licenseAgreementAcceptLog: DataCompartmentOptions<
    Map<number, LicenseAgreementAcceptLogData> | undefined
  >;
}

export const licenseAgreementAcceptLogStorageKey = 'data/licenseAgreementAcceptLog';

export const withLicenseAgreementAcceptLogData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<licenseAgreementAcceptLogCompartments>({
      licenseAgreementAcceptLog: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(LicenseAgreementInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
            const licenseAgreements = (
              licenseDataCache.findCompartment('licenseAgreement') as DataCompartment<
                LicenseAgreementData[]
              >
            ).getData() as LicenseAgreementData[];

            const acceptLogs: Map<number, LicenseAgreementAcceptLogData> = new Map<
              number,
              LicenseAgreementAcceptLogData
            >();
            licenseAgreements &&
              licenseAgreements?.forEach(async agreement => {
                try {
                  const licenseAgreementAcceptLogApi = container.get<LicenseAgreementAcceptLogApi>(
                    ApiKeys.LicenseAgreementAcceptLog,
                  );
                  const { data } = await licenseAgreementAcceptLogApi.getByDocId(agreement.id);
                  acceptLogs.set(data.docId, data);
                } catch (err) {
                  return undefined;
                }
              });
            return acceptLogs;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(licenseAgreementAcceptLogStorageKey, cache);
  },
);
