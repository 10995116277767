import React, { useEffect, useMemo, useState } from 'react';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { capitalize, lowerCase } from 'lodash';
import { formatDateMMDDYYYY } from '../helpers/utilityFunctions';
import { UserData } from '../models/UserData';
import { MutatorState, useObservable } from '@aesop-fables/scrinium';
import { Card, CardContent } from '@mui/material';
import Column from './Column';
import Searchbox from './Searchbox';
import Spacer from './Spacer';
import { useService } from '@aesop-fables/containr-react';
import { FilteredAdminData } from '../hooks/useAdminHooks';
import { sortAdminStatusThenDate, sortByTier } from '../helpers/sortUtils';
import DataTable from './DataTable/DataTable';

interface AdminDataTableProps {
  role: AdminTierTypeEnum;
  usersList: FilteredAdminData[];
  currentUser: UserData | undefined;
  removeAdmin: MutatorState<number>;
  hideSearch?: boolean;
  loading?: boolean;
}

const headers = [
  {
    label: 'name',
    title: 'Name',
  },
  {
    label: 'role',
    title: 'Role',
  },
  {
    label: 'email',
    title: 'Email',
  },
  {
    label: 'status',
    title: 'Status',
  },
  {
    label: '',
    title: '',
  },
];

const AdminDataTable: React.FC<AdminDataTableProps> = ({
  role,
  usersList,
  removeAdmin,
  hideSearch,
  loading,
  currentUser,
}) => {
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);

  const [searchWord, setSearchWord] = useState('');
  const [filteredDataForTable, setFilteredDataForTable] = useState<FilteredAdminData[]>([]);

  const primaryBillingAdmin = useMemo(() => {
    return usersList.find(admin => admin.primaryBillingUser);
  }, [usersList]);

  useEffect(() => {
    if (!searchWord) {
      setFilteredDataForTable(usersList);
      return;
    }
    setFilteredDataForTable(
      usersList?.filter((item: any) =>
        Object.values(item).some(
          (val: any) => val?.toString().toLowerCase().includes(searchWord.toLowerCase()),
        ),
      ),
    );
  }, [searchWord, usersList]);

  const handleAdminRemoval = async (row: any) => {
    await removeAdmin.action(row.id);
  };

  return (
    <>
      {!hideSearch && (
        <Card variant='ghost' color='primary'>
          <CardContent className='searchbox'>
            <Column>
              <Searchbox
                callback={value => setSearchWord(value)}
                label='Search'
                placeholder='Search by admin name'
              />
            </Column>
          </CardContent>
        </Card>
      )}

      <Spacer height='sm' />
      <DataTable
        role={role}
        data={
          filteredDataForTable?.map(admin => {
            const lowerLevelAdmin =
              tier === AdminTierTypeEnum.Root
                ? admin.roles.some(role => role.includes('ORGANIZATION'))
                : tier === AdminTierTypeEnum.Organization
                ? admin.roles.some(role => role.includes('TEAM') || role.includes('ORGANIZATION'))
                : true;
            return {
              id: admin.id,
              name:
                admin.id === currentUser?.id
                  ? `${admin.firstName} ${admin.lastName} (You)`
                  : `${admin.firstName} ${admin.lastName}`,
              role: admin.roles.map(role => capitalize(role.replace(/_/g, ' '))).join(', '),
              email: admin.email,
              status: `${capitalize(admin.status)} as of ${formatDateMMDDYYYY(
                admin.statusTimestamp ?? '',
              )}`,
              deletableRow:
                admin.id !== currentUser?.id &&
                admin.id !== primaryBillingAdmin?.id &&
                lowerLevelAdmin,
              isPinned: admin.id === currentUser?.id,
            };
          }) ?? []
        }
        headers={headers}
        sortBy='role'
        customSort={[
          {
            column: 'role',
            function: (a, b) => sortByTier(a.role, b.role),
          },
          {
            column: 'status',
            function: sortAdminStatusThenDate,
          },
        ]}
        onDelete={handleAdminRemoval}
        type='admins'
        emptyStateText={`You don't have any other admins ${
          role === AdminTierTypeEnum.Team ? 'on' : 'in'
        } your ${lowerCase(role)}.`}
        filtered={searchWord.length > 0}
        lastColumnIsButtons={false}
        loading={loading}
      />
    </>
  );
};

export default AdminDataTable;
