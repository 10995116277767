import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, UnitData } from '../../../models/CourseData';
import { UnitApi } from '../../../api/apis/UnitApi';
import { updateCourses } from './utils';

export class PublishUnit implements IDataCommand<UnitData, UnitData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Unit) private readonly api: UnitApi,
  ) {}

  async execute(unit: UnitData): Promise<UnitData> {
    let response: UnitData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (unit.sourceCourseId && unit.unitId) {
        const lessons = unit.lessons;
        const { data: updatedUnit } = await this.api.publishUnit(unit);
        updatedUnit.lessons = lessons;
        response = updatedUnit;

        const newCourses = updateCourses(updatedUnit, courses);

        return newCourses;
      }
      return courses;
    });

    return response as UnitData;
  }
}
