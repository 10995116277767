import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import {
  CourseData,
  LessonData,
  PublicationStatusEnum,
  UnitData,
} from '../../../models/CourseData';
import { LessonApi } from '../../../api/apis/LessonApi';
import { findLessonSource, updateCourses } from './utils';

export class AddOrUpdateLesson implements IDataCommand<LessonData, LessonData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Lesson) private readonly api: LessonApi,
  ) {}

  async execute(lesson: LessonData): Promise<LessonData> {
    let response: LessonData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex } = findLessonSource(lesson, courses);
      if (courseIndex !== -1 && typeof unitIndex !== 'undefined') {
        if (lesson.lessonId) {
          const newUnits = [...(courses[courseIndex].units as UnitData[])];
          const newUnit = newUnits[unitIndex];
          // if lesson is published, explicitly create a draft
          if (lesson.status === PublicationStatusEnum.PUBLISHED && lesson.lessonId) {
            const { data: draftLesson } = await this.api.createDraft(lesson.lessonId);
            const { data: updatedLesson } = await this.api.updateLesson({
              ...lesson,
              lessonId: draftLesson.lessonId,
            });
            // updateLesson does not return contents or lesson, but draft does
            // set contents and quiz to draft contents and quiz
            updatedLesson.contents = draftLesson.contents;
            updatedLesson.quiz = draftLesson.quiz;
            // push updated draft into lesson list
            newUnit.lessons?.push(updatedLesson);
            response = updatedLesson;
          } else {
            const { data: updatedLesson } = await this.api.updateLesson(lesson);
            updatedLesson.contents = lesson.contents;
            updatedLesson.quiz = lesson.quiz;
            const lessonIndex = newUnit.lessons?.findIndex(x => x.lessonId === lesson.lessonId);
            if (newUnit.lessons && typeof lessonIndex !== 'undefined' && lessonIndex !== -1) {
              const newLessons = [...newUnit.lessons];
              newLessons[lessonIndex] = updatedLesson;
              newUnit.lessons = newLessons;
            }
            response = updatedLesson;
          }

          const newCourses = updateCourses(newUnit, courses);

          return newCourses;
        }

        // remove position logic, allow backend to handle
        const course = courses[courseIndex];
        const units = course.units ?? [];
        const { data } = await this.api.createLesson(lesson);
        const newUnits = [...units];
        const newUnit = newUnits[unitIndex];
        const newLessons = [...(newUnit.lessons ?? []), data];
        newUnit.lessons = newLessons;
        response = data;

        const newCourses = updateCourses(newUnit, courses);
        return newCourses;
      }

      return courses;
    });

    return response as LessonData;
  }
}
