import { Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import RemoveLessonModal from './RemoveLessonModal';
import React, { SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { RemoveLesson } from '../../../../data/learning/commands/RemoveLesson';
import { Toast } from '../../../../models/Toast';
import { useNavigate } from 'react-router-dom';
import { ToastbarContext } from '../../../../App';
import { useCommands } from '../../../../helpers/useCommands';
import { useLoading } from '../../../../hooks/useLoading';
import { UnpublishLesson } from '../../../../data/learning/commands/UnpublishLesson';
import { PublishLesson } from '../../../../data/learning/commands/PublishLesson';
import RemoveLessonDraftModal from './RemoveLessonDraftModal';

interface LessonSummaryHeaderProps {
  lesson?: LessonData;
  draftWithPublished?: boolean;
  linked?: boolean;
}

const LessonSummaryHeader: React.FC<LessonSummaryHeaderProps> = ({
  lesson,
  draftWithPublished,
  linked,
}) => {
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const navigate = useNavigate();
  const commands = useCommands();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const isEmpty = useMemo(
    () => !lesson?.contents?.length && !lesson?.quiz,
    [lesson?.contents?.length, lesson?.quiz],
  );

  const publishLesson = useCallback(async () => {
    try {
      setIsVisible(false);
      setLoading(true);
      await commands.execute(PublishLesson, lesson);
      setToast(
        new Toast({
          message: 'Lesson published!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [setLoading, commands, lesson, setToast]);

  const unpublishLesson = useCallback(async () => {
    try {
      setIsVisible(false);
      setLoading(true);
      await commands.execute(UnpublishLesson, lesson);
      setToast(
        new Toast({
          message: 'Lesson unpublished!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, setLoading, setToast, lesson]);

  const removeLesson = useCallback(async () => {
    setLoading(true);
    try {
      if (lesson) {
        await commands.execute(RemoveLesson, lesson);
        setToast(
          new Toast({
            message: draftWithPublished ? 'Lesson removed!' : 'Draft deleted!',
            severity: 'success',
            open: true,
          }),
        );
        if (!draftWithPublished) {
          navigate(-1);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [setLoading, lesson, commands, setToast, draftWithPublished, navigate]);

  return (
    <Grid>
      {linked && <LinkedHeader lesson={lesson} />}
      {!linked && lesson?.status === PublicationStatusEnum.DRAFT && (
        <UnpublishedHeader
          lesson={lesson}
          draftWithPublished={draftWithPublished}
          removeLesson={removeLesson}
          publishLesson={publishLesson}
          isEmpty={isEmpty}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}
      {!linked && lesson?.status === PublicationStatusEnum.PUBLISHED && (
        <PublishedHeader
          lesson={lesson}
          removeLesson={removeLesson}
          unpublishLesson={unpublishLesson}
          isEmpty={isEmpty}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}
    </Grid>
  );
};

interface ExtendedLessonSummaryHeaderProps extends LessonSummaryHeaderProps {
  publishLesson?: () => Promise<void>;
  unpublishLesson?: () => Promise<void>;
  removeLesson?: () => Promise<void>;
  isEmpty?: boolean;
  isVisible?: boolean;
  setIsVisible?: React.Dispatch<SetStateAction<boolean>>;
}

const LinkedHeader: React.FC<ExtendedLessonSummaryHeaderProps> = ({ lesson }) => {
  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <Grid display='flex' flexDirection='column' rowGap='10px'>
        <Typography variant='p30' color='secondary'>
          {lesson?.name}
        </Typography>
        <Typography variant='p14' color='secondary'>
          published on {dayjs().format('MM/DD/YY')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const UnpublishedHeader: React.FC<ExtendedLessonSummaryHeaderProps> = ({
  lesson,
  draftWithPublished,
  removeLesson,
  publishLesson,
  isEmpty,
  isVisible,
  setIsVisible = () => {},
}) => {
  const removeLessonWithGuard = useCallback(async () => {
    if (isEmpty && removeLesson) {
      await removeLesson();
    } else {
      setIsVisible(true);
    }
  }, [isEmpty, removeLesson, setIsVisible]);

  return (
    <Grid display='flex' justifyContent='flex-end' alignItems='center' columnGap='10px'>
      <RemoveLessonModal
        lesson={lesson}
        open={!draftWithPublished && isVisible === true}
        setOpen={setIsVisible}
        removeLesson={removeLesson}
      />
      <RemoveLessonDraftModal
        lesson={lesson}
        open={draftWithPublished === true && isVisible === true}
        setOpen={setIsVisible}
        removeLesson={removeLesson}
      />
      <Typography variant='p14' color='secondary'>
        last edited on {dayjs(lesson?.lastEditedTimestamp).format('MM/DD/YY')}
      </Typography>
      <Button variant='outlined' color='secondary' onClick={removeLessonWithGuard}>
        {draftWithPublished ? 'Delete draft' : 'Remove lesson'}
      </Button>
      {!isEmpty && (
        <Button color='primary' onClick={publishLesson}>
          Publish lesson
        </Button>
      )}
    </Grid>
  );
};

const PublishedHeader: React.FC<ExtendedLessonSummaryHeaderProps> = ({
  lesson,
  removeLesson,
  unpublishLesson,
  isEmpty,
  isVisible,
  setIsVisible = () => {},
}) => {
  const removeLessonWithGuard = useCallback(async () => {
    if (isEmpty && removeLesson) {
      await removeLesson();
    } else {
      setIsVisible(true);
    }
  }, [isEmpty, removeLesson, setIsVisible]);

  return (
    <Grid display='flex' justifyContent='flex-end' alignItems='center' columnGap='10px'>
      <RemoveLessonModal
        lesson={lesson}
        open={isVisible === true}
        setOpen={setIsVisible}
        removeLesson={removeLesson}
      />
      <Typography variant='p14' color='secondary'>
        published on {dayjs(lesson?.publishedTimestamp).format('MM/DD/YY')}
      </Typography>
      <Button variant='outlined' color='secondary' onClick={removeLessonWithGuard}>
        Remove lesson
      </Button>
      <Button color='secondary' onClick={unpublishLesson}>
        Unpublish lesson
      </Button>
    </Grid>
  );
};

export default LessonSummaryHeader;
