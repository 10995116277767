import { inject } from '@aesop-fables/containr';
import { ISubject, DataCache, ScriniumServices } from '@aesop-fables/scrinium';
import type { IAppStorage } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { LmsPermission } from '../../../api/apis/LmsPermissionApi';
import { UserCompartments, userStorageKey } from '../../user';

export class LmsPermissionSubject implements ISubject<LmsPermission> {
  private readonly cache: DataCache<UserCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly storage: IAppStorage) {
    this.cache = this.storage.retrieve<UserCompartments>(userStorageKey);
  }

  createObservable(): Observable<LmsPermission> {
    return this.cache.observe$<LmsPermission>('permission');
  }
}
