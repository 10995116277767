import { ProfileCard } from '../../Profile';
import CoursesHeader from './CoursesHeader';
import CourseList from './CourseList';
import { Page } from '../../../../components';

const Courses: React.FC = () => {
  return (
    <Page>
      <ProfileCard header={<CoursesHeader />} editable={false}>
        <CourseList />
      </ProfileCard>
    </Page>
  );
};

export default Courses;
