import { Card, Grid, IconButton, useTheme, Link } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { MediaData } from '../../../../models/MediaData';
import { TruncatedFilename } from '../../../../components/TruncatedFilename';

interface MediaPreviewProps {
  media: MediaData | null;
  onRemove: () => void;
  onChoose: () => void;
  variant?: 'light' | 'dark';
}

const MediaPreview: React.FC<MediaPreviewProps> = ({
  media,
  onRemove,
  onChoose,
  variant = 'light',
}) => {
  const theme = useTheme();

  return media ? (
    <Card
      variant={variant === 'dark' ? 'innerCard' : 'ghost'}
      sx={{ width: '100%', maxWidth: variant === 'dark' ? '650px' : 'auto' }}>
      <Grid display='flex' flex={1} py={1} px={2} justifyContent='space-between' minWidth={0}>
        <Grid display='flex' alignItems='center' columnGap='10px' minWidth={0}>
          <Svgs.IconPicture />
          <TruncatedFilename
            variant='p16'
            color='secondary.dark'
            filename={media.mediaName}
            sx={{ width: '95%' }}
          />
        </Grid>
        <IconButton onClick={onRemove}>
          <Svgs.IconDelete />
        </IconButton>
      </Grid>
    </Card>
  ) : (
    <Grid
      onClick={onChoose}
      display='flex'
      alignItems='center'
      columnGap='10px'
      sx={{ '&:hover': { cursor: 'pointer' } }}>
      <Svgs.IconAdd fill={theme.palette.secondary.hover} />
      <Link variant='p16SemiBold'>Choose image</Link>
    </Grid>
  );
};

export default MediaPreview;
