import { IListener, injectDataCache, injectSubject } from '@aesop-fables/scrinium';
import type { DataCache } from '@aesop-fables/scrinium';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { Predicates } from '../../../predicates/Types';
import { GroupCompartments, groupStorageKey } from '..';

export class GroupListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.UserRolesInitialized)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(groupStorageKey)
    private readonly cache: DataCache<GroupCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('group');
        } else {
          this.cache.reset('group');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class AllGroupsListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.UserRolesInitialized)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(groupStorageKey)
    private readonly cache: DataCache<GroupCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('allGroups');
        } else {
          this.cache.reset('allGroups');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class AllGroupMetricsListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.UserRolesInitialized)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(groupStorageKey)
    private readonly cache: DataCache<GroupCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('allMetrics');
        } else {
          this.cache.reset('allMetrics');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}
