import { Card, CardContent, Grid } from '@mui/material';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import { useMemo } from 'react';
import {
  contentCopyKeyString,
  contentIconKeyString,
  contentTitleKeyString,
} from './ContentEditView';
import { ContentOutputProps } from './ContentOutput';
import ContentOutputEmpty from './ContentOutputEmpty';
import MediaImage from '../../../../../components/MediaImage';

const ContentOutputDesktop: React.FC<ContentOutputProps> = ({ content, empty }) => {
  const { getPreviewContentByKey } = useLivePreviewContext();
  const previewTitle = useMemo(
    () => getPreviewContentByKey(`${contentTitleKeyString}/${content.contentId}`),
    [content.contentId, getPreviewContentByKey],
  );
  const previewCopy = useMemo(
    () => getPreviewContentByKey(`${contentCopyKeyString}/${content.contentId}`),
    [content.contentId, getPreviewContentByKey],
  );
  const previewIcon = useMemo(() => {
    const iconStr = getPreviewContentByKey(`${contentIconKeyString}/${content.contentId}`);
    if (!iconStr) return null;

    try {
      return iconStr ? JSON.parse(iconStr) : content.icon;
    } catch {
      return content.icon;
    }
  }, [content.contentId, content.icon, getPreviewContentByKey]);

  // should also check if there is an icon once that's wired up
  if (!previewTitle && !previewCopy && empty) return <ContentOutputEmpty />;

  if (!previewTitle && !previewCopy) return undefined;

  return (
    <Grid display='flex' flexDirection='column' rowGap='20px'>
      {typeof previewTitle !== 'undefined' && previewTitle.length > 0 && (
        <Grid display='flex' alignItems='center'>
          {previewIcon && (
            <MediaImage
              media={previewIcon}
              imgStyle={{
                maxWidth: '80px',
                maxHeight: '80px',
              }}
              containerSx={{ mx: '30px' }}
            />
          )}
          <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: previewTitle }} />
        </Grid>
      )}
      {typeof previewCopy !== 'undefined' && previewCopy.length > 0 && (
        <Card variant='outlined' style={{ borderColor: '#e8f1f3' }}>
          <CardContent className='pad20'>
            <div dangerouslySetInnerHTML={{ __html: previewCopy }} />
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default ContentOutputDesktop;
