import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, LessonContent, UnitData } from '../../../models/CourseData';
import { findLessonContentSource, updateCourses } from './utils';
import { LessonContentApi } from '../../../api/apis/LessonContentApi';

export class AddOrUpdateLessonContent implements IDataCommand<LessonContent, LessonContent> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.LessonContent) private readonly api: LessonContentApi,
  ) {}

  async execute(content: LessonContent): Promise<LessonContent> {
    let response: LessonContent | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex, lessonIndex } = findLessonContentSource(content, courses);
      if (
        typeof courseIndex !== 'undefined' &&
        typeof unitIndex !== 'undefined' &&
        typeof lessonIndex !== 'undefined'
      ) {
        const newUnits = [...(courses[courseIndex].units as UnitData[])];
        const newUnit = newUnits[unitIndex];
        if (newUnit.lessons) {
          const newLessons = [...newUnit.lessons];
          const newLesson = newLessons[lessonIndex];

          if (content.contentId) {
            // need to add logic to create a lesson draft if it is published
            const { data: updatedContent } =
              content.icon === null
                ? await this.api.replaceLessonContent(content)
                : await this.api.updateLessonContent(content);

            response = updatedContent;
            const contentIndex = newLesson.contents?.findIndex(
              x => x.contentId === updatedContent.contentId,
            );
            if (newLesson.contents && typeof contentIndex !== 'undefined' && contentIndex !== -1) {
              const newContents = [...newLesson.contents];
              newContents[contentIndex] = updatedContent;
              newLesson.contents = newContents;
              newLessons[lessonIndex] = newLesson;
            }

            const newCourses = updateCourses(newUnit, courses);

            return newCourses;
          }

          const { data } = await this.api.createLessonContent(content);
          response = data;
          const newContents = [...(newLesson.contents ?? []), data];
          newLesson.contents = newContents;
          newUnit.lessons = newLessons;

          const newCourses = updateCourses(newUnit, courses);

          return newCourses;
        }
      }
      return courses;
    });

    return response as LessonContent;
  }
}
