import { Card, CardContent, Divider, Grid, Link, Typography, useTheme } from '@mui/material';
import { LessonData, PublicationStatusEnum, UnitData } from '../../../../models/CourseData';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import UnitLesson from './UnitLesson';
import { Spacer } from '../../../../components';
import { Svgs } from '../../../../assets/svg';
import LessonEditDrawer from '../lessons/LessonEditDrawer';
import { PublicationStatusFilterOptions } from '../courses/CourseSummary';
import { FindDraftByLessonId } from '../../../../data/learning/queries/FindDraftByLesson';
import { useService } from '@aesop-fables/containr-react';
import {
  IObservableQueryDispatcher,
  ScriniumServices,
  useObservableQuery,
} from '@aesop-fables/scrinium';
import { FindCourseById } from '../../../../data/learning/queries/FindCourseById';

interface LessonListProps {
  unit: UnitData;
  lessons?: LessonData[];
  originalCourseId: number;
  filter?: PublicationStatusFilterOptions;
}

const UnitLessonList: React.FC<LessonListProps> = ({ unit, lessons, originalCourseId, filter }) => {
  const theme = useTheme();
  const dispatcher = useService<IObservableQueryDispatcher>(ScriniumServices.QueryDispatcher);
  const { unitId, sourceCourseId } = unit;
  const course = useObservableQuery(FindCourseById, sourceCourseId);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<LessonData | undefined>();
  const [filteredLessons, setFilteredLessons] = useState<LessonData[]>();
  const disabled = useMemo(
    () => sourceCourseId !== originalCourseId,
    [originalCourseId, sourceCourseId],
  );

  const editLesson = useCallback((lesson: LessonData) => {
    setSelectedLesson(lesson);
    setDrawerOpen(true);
  }, []);

  useEffect(() => {
    const filterLessons = async () => {
      if (lessons) {
        if (filter === PublicationStatusFilterOptions.PUBLISHED) {
          setFilteredLessons(lessons?.filter(lesson => lesson.active));
          return;
        } else if (filter === PublicationStatusFilterOptions.DRAFT) {
          const results = await Promise.all(
            lessons.map(async lesson => {
              if (lesson.status === PublicationStatusEnum.DRAFT) return true;
              const draft = await dispatcher.execute(FindDraftByLessonId, {
                unitId: lesson.unitId,
                originalLessonId: lesson.originalLessonId,
              });
              return typeof draft !== 'undefined';
            }),
          );
          setFilteredLessons(lessons?.filter((x, index) => results[index]));
          return;
        }

        setFilteredLessons(
          lessons?.filter(lesson => lesson.active || lesson.status === PublicationStatusEnum.DRAFT),
        );
      }
    };

    filterLessons();
  }, [dispatcher, filter, lessons]);

  useEffect(() => {
    if (!drawerOpen) {
      setSelectedLesson(undefined);
    }
  }, [drawerOpen]);

  return (
    <>
      <Droppable droppableId='droppable'>
        {provided => (
          <Grid
            {...provided.droppableProps}
            ref={provided.innerRef}
            display='flex'
            flexDirection='column'
            rowGap='5px'>
            {filteredLessons &&
              filteredLessons.map((lesson, index) =>
                disabled ? (
                  <UnitLesson
                    key={`${unitId}-${lesson.lessonId}`}
                    course={course}
                    unit={unit}
                    lesson={lesson}
                    editLesson={editLesson}
                    disabled={disabled}
                  />
                ) : (
                  <Draggable
                    key={`draggable-${unitId}-${lesson.lessonId}`}
                    draggableId={`draggable-${unitId}-${lesson.lessonId}`}
                    index={index}>
                    {(_provided, _snapshot) => (
                      <UnitLesson
                        ref={_provided.innerRef}
                        dragHandleProps={_provided.dragHandleProps}
                        dragProps={_provided.draggableProps}
                        lesson={lesson}
                        course={course}
                        unit={unit}
                        editLesson={editLesson}
                        disabled={disabled}
                      />
                    )}
                  </Draggable>
                ),
              )}
            {provided.placeholder}
            <LessonEditDrawer
              originalUnitId={unit.originalUnitId ?? 0}
              lessonData={selectedLesson}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
          </Grid>
        )}
      </Droppable>
      {!filteredLessons?.length && (
        <>
          {filter === PublicationStatusFilterOptions.PUBLISHED && (
            <Card variant='ghost' color='secondary'>
              <CardContent className='pad10'>
                <Grid display='flex' justifyContent='center'>
                  <Typography variant='p16SemiBold' color='secondary'>
                    There aren't any published lessons in this unit
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          )}
          {filter === PublicationStatusFilterOptions.DRAFT && (
            <Card variant='ghost' color='secondary'>
              <CardContent className='pad10'>
                <Grid display='flex' justifyContent='center'>
                  <Typography variant='p16SemiBold' color='secondary'>
                    There aren't any published lessons in this unit
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          )}
          {filter === PublicationStatusFilterOptions.ALL && (
            <Divider style={{ backgroundColor: theme.palette.secondary.disabled }} />
          )}
        </>
      )}
      {!disabled && (
        <Grid>
          <Spacer height='xs' />
          <CreateLessonButton onClick={() => setDrawerOpen(true)} />
        </Grid>
      )}
    </>
  );
};

interface CreateLessonButtonProps {
  onClick: () => void;
}

const CreateLessonButton: React.FC<CreateLessonButtonProps> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <Grid
      onClick={onClick}
      display='flex'
      columnGap='10px'
      sx={{ '&:hover': { cursor: 'pointer' } }}>
      <Svgs.IconAdd fill={theme.palette.secondary.hover} />
      <Link variant='p16SemiBold'>Create new lesson</Link>
    </Grid>
  );
};

export default UnitLessonList;
