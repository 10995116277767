import { ApiKeys } from '../../api/apis/ApiKeys';
import { UserApi } from '../../api/apis/UserApi';
import {
  DataCompartmentOptions,
  ConfiguredDataSource,
  createDataCache,
  createDataCacheModule,
  IAppStorage,
} from '@aesop-fables/scrinium';
import { IServiceContainer } from '@aesop-fables/containr';
import { UserData, UserRole } from '../../models/UserData';
import type { IAuthenticationContext } from '../../services/authentication';
import { authContextKey } from '../../services/authentication';
import { IsAuthenticated } from '../../predicates/IsAuthenticated';
import { LmsPermission, LmsPermissionApi } from '../../api/apis/LmsPermissionApi';
export interface UserCompartments {
  user: DataCompartmentOptions<UserData | undefined>;
  userRoles: DataCompartmentOptions<UserRole[] | undefined>;
  permission: DataCompartmentOptions<LmsPermission | undefined>;
}

export const userStorageKey = 'data/user';

export const withUserData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<UserCompartments>({
      user: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(IsAuthenticated) },
        source: new ConfiguredDataSource(async () => {
          const authContext = container.get<IAuthenticationContext>(authContextKey);
          try {
            const userApi = container.get<UserApi>(ApiKeys.User);
            const response = await userApi.get();
            authContext.setIsAuthenticated(true);
            return response.data;
          } catch (err) {
            console.error('User is not authenticated');
          } finally {
            authContext.setAuthReady(true);
          }
        }),
        defaultValue: undefined,
      },
      userRoles: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(IsAuthenticated) },
        source: new ConfiguredDataSource(async () => {
          try {
            const userApi = container.get<UserApi>(ApiKeys.User);
            const response = await userApi.getRole();
            return response.data;
          } catch (err) {
            console.error('User is not authenticated');
          }
        }),
        defaultValue: undefined,
      },
      permission: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(IsAuthenticated),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const lmsPermissionApi = container.get<LmsPermissionApi>(ApiKeys.LmsPermission);
            const { data } = await lmsPermissionApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(userStorageKey, cache);
  },
);
