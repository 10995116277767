import { Typography } from '@mui/material';
import { Column, Modal, Spacer } from '../../../../../components';

interface RemoveQuestionModalProps {
  removeQuestion?: (data?: any) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveQuestionModal: React.FC<RemoveQuestionModalProps> = ({
  open,
  setOpen,
  removeQuestion,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      handleSave={removeQuestion}
      title='Remove content?'
      primaryButtonText='Yes, Remove'
      secondaryButtonText='No, Keep'>
      <Column>
        <Typography variant='p14' color='secondary'>
          Are you sure you want to remove this quiz question?
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p14' color='secondary'>
          This action can't be undone.
        </Typography>
        <Spacer height='xs' />
      </Column>
    </Modal>
  );
};

export default RemoveQuestionModal;
