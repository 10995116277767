import { useParams } from 'react-router-dom';
import UnitList from '../units/UnitList';
import { DropdownMenu, Page, Spacer } from '../../../../components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { useCommands } from '../../../../helpers/useCommands';
import { useLoading } from '../../../../hooks/useLoading';
import { UpdateUnitPosition } from '../../../../data/learning/commands/UpdateUnitPosition';
import { PublicationStatusEnum, UnitData } from '../../../../models/CourseData';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { useCourses } from '../../../../hooks/useCourses';
import { Grid, Typography, useTheme } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { Svgs } from '../../../../assets/svg';
import { HelpTitle } from '../../../../components/HelpText';
import { StatusDotRed } from '../../../../components/StatusDotRed';
import BulletedList from '../../../../components/BulletedList';
import { SemiBold } from '../../../../components/SemiBold';

export enum PublicationStatusFilterOptions {
  ALL = 'Show all',
  PUBLISHED = 'Show published',
  DRAFT = 'Show unpublished',
}

const CourseSummary: React.FC = () => {
  const theme = useTheme();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const { courseId } = useParams();
  const courses = useCourses();
  const course = useMemo(
    () =>
      courses?.find(
        x =>
          x.originalCourseId === parseInt(courseId ?? '') &&
          ((x.status === PublicationStatusEnum.PUBLISHED && x.active) ||
            x.status === PublicationStatusEnum.DRAFT),
      ),
    [courses, courseId],
  );
  // should only display units in a course if they are published or unpublished but have the same sourceCourseId
  const [orderedUnits, setOrderedUnits] = useState<UnitData[]>([]);
  const [filter, setFilter] = useState<PublicationStatusFilterOptions>(
    PublicationStatusFilterOptions.ALL,
  );
  const isEmpty = !course?.units || course.units.length === 0;

  useEffect(() => {
    if (course?.units) {
      if (filter === PublicationStatusFilterOptions.PUBLISHED) {
        setOrderedUnits(
          course?.units
            ?.filter(unit => unit.active)
            .sort((a, b) => (a.position ?? 0) - (b.position ?? 0)),
        );
        return;
      } else if (filter === PublicationStatusFilterOptions.DRAFT) {
        setOrderedUnits(
          course?.units
            ?.filter(
              unit =>
                unit.status === PublicationStatusEnum.DRAFT ||
                unit.lessons?.find(lesson => lesson.status === PublicationStatusEnum.DRAFT),
            )
            .sort((a, b) => (a.position ?? 0) - (b.position ?? 0)),
        );
        return;
      }
      setOrderedUnits(
        course?.units
          ?.filter(unit => unit.active || unit.status === PublicationStatusEnum.DRAFT)
          .sort((a, b) => (a.position ?? 0) - (b.position ?? 0)),
      );
    }
  }, [course?.units, filter]);

  const dragEnded = async (param: any) => {
    const { source, destination } = param;
    const currentUnits = [...orderedUnits];

    try {
      setLoading(true);
      const newUnits = [...orderedUnits];
      // extracting the source item from the list
      const unit = newUnits.splice(source.index, 1)[0];
      newUnits.splice(destination.index, 0, unit);
      const sorted = newUnits.map((x, i) => ({ ...x, position: i + 1 }));
      setOrderedUnits(sorted);

      await commands.execute(UpdateUnitPosition, {
        unit,
        orderedUnits: sorted,
      });
    } catch (err) {
      setToast(new Toast({ severity: 'error', open: true }));
      setOrderedUnits(currentUnits);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title={course?.name}
      titleAdornment={
        isEmpty ? undefined : (
          <Grid display='flex' alignItems='center' columnGap='20px'>
            <Grid display='flex' alignItems='center' columnGap='10px'>
              <Typography variant='p16' color='secondary'>
                Status explanations
              </Typography>
              <Tippy placement='right' content={<StatusHelpText />} theme='tippy-rounded'>
                <HelpOutline style={{ color: theme.palette.info.main }} />
              </Tippy>
            </Grid>
            <DropdownMenu
              callback={val => setFilter(val as PublicationStatusFilterOptions)}
              inputStyles={{ minWidth: '185px' }}
              selectProps={{ variant: 'outlined' }}
              selected={filter.toString()}
              options={Object.values(PublicationStatusFilterOptions)}
            />
          </Grid>
        )
      }
      showAdornment={!isEmpty}>
      <Spacer height='xs' />
      <DragDropContext onDragEnd={dragEnded}>
        <UnitList
          originalCourseId={course?.originalCourseId ?? 0}
          units={orderedUnits}
          filter={filter}
        />
      </DragDropContext>
    </Page>
  );
};

export const StatusHelpText = () => (
  <>
    <HelpTitle>Unit and lesson statuses</HelpTitle>
    <Spacer height='xxs' />
    <BulletedList
      children={[
        <span>
          <SemiBold>Course hierarchy</SemiBold>: a course has units, units have lessons, and lessons
          have content and quizzes
        </span>,

        <span>
          <SemiBold>Published unit</SemiBold>: this unit is visible as part of the course, as long
          as the course is also published
        </span>,

        <span>
          <SemiBold>Unpublished unit</SemiBold>: this unit is not visible as part of the course,
          even if there are published lessons in the unit
        </span>,

        <span>
          <SemiBold>Published lesson</SemiBold>: this lesson is visible as part of the course, as
          long as the unit is also published
        </span>,

        <span>
          <SemiBold>Published lesson with an open draft</SemiBold>: this lesson is published (see
          above), but there is also an edited version that is not visible as part of the course
        </span>,

        <span>
          <SemiBold>Draft lesson</SemiBold>: this lesson is still in the editing phase and is not
          visible
        </span>,

        <Grid display='inline'>
          <Svgs.IconCheckmarkGrey
            style={{
              display: 'inline-flex',
              flexShrink: 0,
              verticalAlign: 'middle',
              marginRight: '3px',
            }}
          />
          <span style={{ display: 'inline', verticalAlign: 'middle' }}>
            this indicates a unit or lesson that is visible in the course
          </span>
        </Grid>,

        <Grid display='inline' alignItems='center'>
          <StatusDotRed
            style={{
              display: 'inline-flex',
              flexShrink: 0,
              verticalAlign: 'middle',
              transform: 'translateY(0px)',
              marginRight: '7px',
            }}
          />
          <span style={{ display: 'inline', verticalAlign: 'middle' }}>
            this indicates a lesson with a draft still open
          </span>
        </Grid>,
      ]}
    />
    <Spacer height='xxs' />
  </>
);

export default CourseSummary;
