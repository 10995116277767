import { Card, CardContent, Grid, Typography } from '@mui/material';
import { UnitData } from '../../../../models/CourseData';
import UnitItem from './UnitItem';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import AddButton from '../../../../components/AddButton';
import { useNavigate } from 'react-router-dom';
import { PublicationStatusFilterOptions } from '../courses/CourseSummary';

interface UnitListParams {
  originalCourseId: number;
  units?: UnitData[];
  filter?: PublicationStatusFilterOptions;
}

const UnitList: React.FC<UnitListParams> = ({ originalCourseId, units, filter }) => {
  const navigate = useNavigate();

  const createUnit = () => {
    navigate(`unit/new`);
  };

  return (
    <Droppable droppableId='droppable'>
      {provided => (
        <Grid
          {...provided.droppableProps}
          ref={provided.innerRef}
          display='flex'
          flexDirection='column'
          rowGap='20px'>
          {units &&
            units.map((unit, index) => (
              <Draggable
                key={`draggable-${unit.unitId}`}
                draggableId={`draggable-${unit.unitId}`}
                index={index}>
                {(_provided, _snapshot) => (
                  <UnitItem
                    snapshot={_snapshot}
                    ref={_provided.innerRef}
                    dragHandleProps={_provided.dragHandleProps}
                    dragProps={_provided.draggableProps}
                    unit={unit}
                    originalCourseId={originalCourseId}
                    filter={filter}
                  />
                )}
              </Draggable>
            ))}
          {provided.placeholder}
          {!units?.length && filter === PublicationStatusFilterOptions.PUBLISHED && (
            <Card variant='ghost' color='primary'>
              <CardContent className='pad10'>
                <Grid display='flex' justifyContent='center'>
                  <Typography variant='p20SemiBold' color='secondary'>
                    There aren't any published units in this course.
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          )}
          {!units?.length && filter === PublicationStatusFilterOptions.DRAFT && (
            <Card variant='ghost' color='primary'>
              <CardContent className='pad10'>
                <Grid display='flex' justifyContent='center'>
                  <Typography variant='p20SemiBold' color='secondary'>
                    There aren't any unpublished units or lessons in this course.
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          )}
          <AddButton content='Create new unit' onClick={createUnit} />
        </Grid>
      )}
    </Droppable>
  );
};

export default UnitList;
