import {
  AdminPaymentApi,
  PaymentMethodData,
  StripeSession,
  StripeSessionStatus,
} from '../../api/apis/AdminPaymentApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { ChargeApi, ChargeData } from '../../api/apis/ChargeApi';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  createDataCache,
  createDataCacheModule,
  IAppStorage,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { OrgSignUpStateApi } from '../../api/apis/OrgSignUpStateApi';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface PaymentCompartments {
  paymentMethods: DataCompartmentOptions<PaymentMethodData[] | undefined>;
  defaultPaymentMethod: DataCompartmentOptions<string | undefined>;
  paymentHistory: DataCompartmentOptions<ChargeData[] | undefined>;
  stripeSessionStatus: DataCompartmentOptions<StripeSessionStatus | undefined>;
  paidStatus: DataCompartmentOptions<boolean | undefined>;
}

export const paymentStorageKey = 'data/payment';

export const withPaymentData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<PaymentCompartments>({
      paymentMethods: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const adminPaymentApi = container.get<AdminPaymentApi>(ApiKeys.AdminPayment);
          const { data } = await adminPaymentApi.getAllPaymentMethods();
          return data;
        }),
        defaultValue: undefined,
      },
      defaultPaymentMethod: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const adminPaymentApi = container.get<AdminPaymentApi>(ApiKeys.AdminPayment);
          const { data } = await adminPaymentApi.getDefaultPaymentMethod();
          return data;
        }),
        defaultValue: undefined,
      },
      paymentHistory: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const chargeApi = container.get<ChargeApi>(ApiKeys.Charge);
          const { data } = await chargeApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
      stripeSessionStatus: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
          const { data } = await orgSignUpStateApi.get();
          const stripeSessionJson = data.state?.stripeSession;
          return stripeSessionJson ? (JSON.parse(stripeSessionJson) as StripeSession) : undefined;
        }),
        defaultValue: undefined,
      },
      paidStatus: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const orgSignUpStateApi = container.get<OrgSignUpStateApi>(ApiKeys.OrgSignUpState);
            const { data } = await orgSignUpStateApi.get();
            const stripeSessionJson = data.state?.stripeSession;
            const stripeSession = stripeSessionJson
              ? (JSON.parse(stripeSessionJson) as StripeSession)
              : undefined;

            if (stripeSession?.id) {
              const adminPaymentApi = container.get<AdminPaymentApi>(ApiKeys.AdminPayment);
              const { data: paidStatus } = await adminPaymentApi.getSessionPaymentStatus(
                stripeSession.id,
              );
              return paidStatus;
            }
            return undefined;
          } catch (error) {
            console.error('Error fetching paid status:', error);
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(paymentStorageKey, cache);
  },
);
