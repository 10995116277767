import { useState, useContext, useEffect, useCallback } from 'react';
import { CategoryData, LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import { Add } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';
import TagSelect from '../../../../components/TagSelect';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { useCommands } from '../../../../helpers/useCommands';
import { AddOrUpdateLesson } from '../../../../data/learning/commands/AddOrUpdateLesson';
import { useLoading } from '../../../../hooks/useLoading';
import LessonCategoryDrawer from './LessonCategoryDrawer';
import { useTabContext } from '../../../../components/TabContext';

interface LessonCategoriesProps {
  categories: CategoryData[];
  disabled: boolean;
  lesson?: LessonData;
}

const LessonCategories: React.FC<LessonCategoriesProps> = ({ categories, lesson, disabled }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<CategoryData[]>([]);
  const { setActiveTab } = useTabContext();
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (lesson?.categories) {
      setSelectedCategories(lesson.categories);
    }
  }, [lesson?.categories]);

  const updateLessonCategories = useCallback(
    async (categories: CategoryData[]) => {
      if (!lesson) return;

      try {
        setLoading(true);
        await commands.execute(AddOrUpdateLesson, {
          ...lesson,
          categories,
        });

        setToast(
          new Toast({
            message: 'Category changes saved!',
            severity: 'success',
            open: true,
            autoHideDuration: 2000,
          }),
        );
        setActiveTab(1);
        setSelectedCategories(categories);
      } catch (err) {
        console.error(err);
        setToast(
          new Toast({
            message: 'Failed to update categories',
            severity: 'error',
            open: true,
          }),
        );
        setSelectedCategories(lesson.categories || []);
      } finally {
        setLoading(false);
      }
    },
    [lesson, setLoading, commands, setToast, setActiveTab],
  );

  const formatCategoryName = useCallback((category: string | undefined): string => {
    if (!category) return '';
    return category
      .replaceAll('_', ' ')
      .toLowerCase()
      .replace(/\b\w/g, c => c.toUpperCase());
  }, []);

  const handleDrawerSubmit = async (selectedItems: CategoryData[]) => {
    setDrawerOpen(false);
    await updateLessonCategories(selectedItems);
  };

  const handleTagRemove = async (newSelection: CategoryData[]) => {
    await updateLessonCategories(newSelection);
  };

  if (!lesson) return null;

  return (
    <>
      <Grid container gap={1}>
        <TagSelect<CategoryData>
          selectedItems={selectedCategories}
          onSelectionChange={handleTagRemove}
          getItemId={category => category.categoryId}
          getItemLabel={category => category.category}
          formatChipLabel={formatCategoryName}
          readOnly={lesson.status === PublicationStatusEnum.PUBLISHED && disabled}
        />
        {selectedCategories.length < categories.length && !disabled && (
          <Chip
            icon={<Add style={{ color: '#9eacb0' }} />}
            variant='outlined'
            label='add a category'
            onClick={() => setDrawerOpen(true)}
          />
        )}
      </Grid>

      <LessonCategoryDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSubmit={handleDrawerSubmit}
        allCategories={categories}
        selectedCategories={selectedCategories}
      />
    </>
  );
};

export default LessonCategories;
