import { Card, CardContent, Link, Typography } from '@mui/material';
import { API_URL } from '../../api/apis';
import { Page, Spacer } from '../../components';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { OrganizationData } from '../../models/OrganizationData';
import { OrgSignUpStateData } from '../../models/SignUpData';
import AboutYourOrganizationCard from './AboutYourOrganizationCard';
import CustomUrlCard from './CustomUrlCard';
import FinishTodosCard from './FinishTodosCard';
import LogoCard from './LogoCard';
import MembersAccessCard from './MembersAccessCard';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import CustomBlurbCard from './CustomBlurbCard';

const OrganizationInfo: React.FC = () => {
  const appStorage = useAppStorage();
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgInitialized = useObservable(organizationDataCache.initialized$);
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );
  const { offboardingComplete } = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  ) ?? { offboardingComplete: false };
  const showAdornment =
    orgInitialized && orgData?.logoUri !== undefined && orgData?.logoUri !== null;

  return (
    <Page
      title='Organization info'
      showAdornment={showAdornment}
      titleAdornment={
        <img
          src={`${API_URL}organization/logo/${orgData?.id}`}
          style={{ height: '50px' }}
          alt={`${orgData?.name}'s logo`}
        />
      }>
      <Spacer height='xs' />
      <AboutYourOrganizationCard />
      <Spacer height='xs' />
      {offboardingComplete ? (
        <>
          <LogoCard />
          <Spacer height='xs' />
          <MembersAccessCard />
          <Spacer height='xs' />
          <CustomBlurbCard />
          <Spacer height='xxs' />
          <CustomUrlCard />
        </>
      ) : groupFeatureMapData?.onlyRestrictMembersByDomain ? (
        <>
          <MembersAccessCard />
          <Spacer height='xs' />
        </>
      ) : groupFeatureMapData?.orgToDoListView ? (
        <FinishTodosCard />
      ) : null}
    </Page>
  );
};

interface OrganizationCardProps extends React.PropsWithChildren {
  title?: string;
  editable: boolean;
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onEdit?: () => void;
}

export const OrganizationCard: React.FC<OrganizationCardProps> = props => {
  const { title, editable, setDrawerOpen, onEdit } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit();
      return;
    }
    if (setDrawerOpen) {
      setDrawerOpen(true);
      return;
    }
  };
  return (
    <>
      {title && (
        <>
          <Typography variant='subtitle1' color='secondary.main'>
            {title}
          </Typography>
          <Spacer height='xs' />
        </>
      )}
      <Card variant='ghost' color='primary'>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          className={editable ? `profileCard` : `pad30`}>
          {editable ? (
            <>
              <Link onClick={onEditClick} style={{ alignSelf: 'flex-end' }} variant='body2'>
                Edit
              </Link>
              <Spacer height='xxs' />
            </>
          ) : null}

          {props.children}
        </CardContent>
      </Card>
    </>
  );
};

export default OrganizationInfo;
