import { Grid, Typography, Box } from '@mui/material';
import { MediaData, MediaType } from '../../../../models/MediaData';
import MediaUploader from './MediaUploader';
import MediaLibraryGrid from './MediaLibraryGrid';
import { SemiBold } from '../../../../components/SemiBold';
interface MediaDrawerProps {
  selectedMedia: MediaData | null;
  media: MediaData[] | null;
  handleFileUpload: (file: File) => void;
  setSelectedMedia: (media: MediaData | null) => void;
  mediaType: MediaType;
}

const MediaDrawer: React.FC<MediaDrawerProps> = ({
  selectedMedia,
  media,
  handleFileUpload,
  setSelectedMedia,
  mediaType,
}) => (
  <Grid container mt={1.5} rowGap={'25px'} height='100%'>
    <Grid container flexDirection='column'>
      <Typography variant='p16' color='secondary.dark'>
        {selectedMedia ? (
          <Typography variant='p16' color='secondary.active'>
            <SemiBold>{selectedMedia.mediaName}</SemiBold> selected
          </Typography>
        ) : (
          'No image selected'
        )}
      </Typography>
    </Grid>

    <MediaUploader handleFileUpload={handleFileUpload} mediaType={mediaType} />

    <Grid container rowGap={2} height='100%'>
      <Typography variant='p16Bold' color='info.main'>
        Or select an image from your library
      </Typography>
      <Box
        px={2}
        pt={1}
        pb={4}
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}>
        <MediaLibraryGrid
          media={media}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          mediaType={mediaType}
        />
      </Box>
    </Grid>
  </Grid>
);

export default MediaDrawer;
