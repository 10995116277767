import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { pricebookStorageKey, PricebookCompartments } from '../data/pricebook';
import { PricebookBundle } from '../models/PricebookData';
import { Predicates } from './Types';

@predicate(Predicates.IsAuthenticated)
export class PricebooksSelected implements Predicate {
  constructor(
    @injectDataCache(pricebookStorageKey)
    private readonly pricebookDataCache: DataCache<PricebookCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.pricebookDataCache
      .observe$<PricebookBundle[]>('pricebookSelections')
      .pipe(map(pricebookSelections => pricebookSelections?.length > 0));
  }
}
