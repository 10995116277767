import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
} from '@mui/icons-material';
import { Grid, ButtonBase, useTheme } from '@mui/material';
import { Editor, Extensions } from '@tiptap/react';
import { useMemo } from 'react';

interface LivePreviewToolbarProps {
  extensions?: Extensions;
  editor: Editor | null;
}

const LivePreivewToolbar: React.FC<LivePreviewToolbarProps> = ({ extensions, editor }) => {
  const theme = useTheme();

  const { bold, italic, underline, bulletList, orderedList, textAlign } = useMemo(() => {
    let bold = false;
    let italic = false;
    let underline = false;
    let textAlign = false;
    let bulletList = false;
    let orderedList = false;

    if (editor?.extensionManager.extensions) {
      for (let i = 0; i < editor.extensionManager.extensions.length; i++) {
        const extension = editor?.extensionManager.extensions[i];
        if (extension.name === 'bold') bold = true;
        if (extension.name === 'italic') italic = true;
        if (extension.name === 'underline') underline = true;
        if (extension.name === 'textAlign') textAlign = true;
        if (extension.name === 'bulletList') bulletList = true;
        if (extension.name === 'orderedList') orderedList = true;
      }
    }

    return { bold, italic, underline, textAlign, bulletList, orderedList };
  }, [editor?.extensionManager.extensions]);

  if (!editor) return null;

  return (
    <Grid
      className='live-preview-toolbar'
      display='flex'
      columnGap='30px'
      borderTop={`solid 1px ${theme.palette.secondary.light}`}
      paddingTop='5px'>
      <Grid display='flex' columnGap='10px'>
        {bold && (
          <ButtonBase
            className={editor.isActive('bold') ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().toggleBold().run()}>
            <FormatBold />
          </ButtonBase>
        )}
        {italic && (
          <ButtonBase
            className={editor.isActive('italic') ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().toggleItalic().run()}>
            <FormatItalic />
          </ButtonBase>
        )}
        {underline && (
          <ButtonBase
            className={editor.isActive('underline') ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().toggleUnderline().run()}>
            <FormatUnderlined />
          </ButtonBase>
        )}
        {bulletList && (
          <ButtonBase
            className={editor.isActive('bulletList') ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().toggleBulletList().run()}>
            <FormatListBulleted />
          </ButtonBase>
        )}
        {orderedList && (
          <ButtonBase
            className={editor.isActive('orderedList') ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().toggleOrderedList().run()}>
            <FormatListNumbered />
          </ButtonBase>
        )}
      </Grid>
      {textAlign && (
        <Grid display='flex' columnGap='10px'>
          <ButtonBase
            className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().setTextAlign('left').run()}>
            <FormatAlignLeft />
          </ButtonBase>
          <ButtonBase
            className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().setTextAlign('center').run()}>
            <FormatAlignCenter />
          </ButtonBase>
          <ButtonBase
            className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().setTextAlign('right').run()}>
            <FormatAlignRight />
          </ButtonBase>
          <ButtonBase
            className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
            disableRipple
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}>
            <FormatAlignJustify />
          </ButtonBase>
        </Grid>
      )}
    </Grid>
  );
};

export default LivePreivewToolbar;
