import { PlanApi } from '../../api/apis/PlanApi';
import { PlanData } from '../../models/PlanData';
import {
  DataCompartmentOptions,
  ConfiguredDataSource,
  createDataCache,
  createDataCacheModule,
  IAppStorage,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface PlanCompartments {
  plan: DataCompartmentOptions<PlanData | undefined>;
}

export const planStorageKey = 'data/plan';

export const withPlanData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<PlanCompartments>({
      plan: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const planApi = container.get<PlanApi>(ApiKeys.Plan);
            const { data } = await planApi.getPlan();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(planStorageKey, cache);
  },
);
