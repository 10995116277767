import { ApiKeys } from '../../api/apis/ApiKeys';
import { OffboardingTemplateApi } from '../../api/apis/OffboardingTemplateApi';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { TemplateData } from '../../models/SignUpData';
import { PricebooksSelected } from '../../predicates/PricebooksSelected';

export interface OffboardingCompartments {
  offboardingTemplate: DataCompartmentOptions<TemplateData | undefined>;
}

export const offboardingStorageKey = 'data/offboarding';

export const withOffboardingData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<OffboardingCompartments>({
      offboardingTemplate: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(PricebooksSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const offboardingTemplateApi = container.get<OffboardingTemplateApi>(
              ApiKeys.OffboardingTemplate,
            );
            const { data } = await offboardingTemplateApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(offboardingStorageKey, cache);
  },
);
