import React, { CSSProperties, ReactElement, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { Check } from '@mui/icons-material';

interface CheckboxListProps<T> {
  items: T[];
  itemStyles?: CSSProperties;
  itemsStyles?: CSSProperties;
  parentLabel: string;
  noneSelectedMessage: string;
  numColumns?: number;
  checkedItems: T[];
  setCheckedItems: (items: T[]) => void;
  getItemId: (item: T) => any;
  getItemLabel: (item: T) => any;
}

const CheckboxList = <T extends unknown>({
  items,
  itemStyles,
  itemsStyles,
  parentLabel,
  noneSelectedMessage,
  numColumns = 3,
  checkedItems,
  setCheckedItems,
  getItemId,
  getItemLabel,
}: CheckboxListProps<T>): ReactElement => {
  const isItemChecked = (item: T): boolean => {
    return checkedItems.some(checkedItem => getItemId(checkedItem) === getItemId(item));
  };

  const handleCheckAll = () => {
    setCheckedItems(checkedItems.length > 0 ? [] : items);
  };

  const handleCheckItem = (item: T) => {
    if (isItemChecked(item)) {
      setCheckedItems(
        checkedItems.filter(checkedItem => getItemId(checkedItem) !== getItemId(item)),
      );
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  const formatLabel = useCallback((label: string): string => {
    if (!label) return '';
    return capitalize(label.replaceAll('_', ' '));
  }, []);

  return (
    <Grid container rowGap={1}>
      <Grid container item>
        <FormControlLabel
          className='parent'
          control={
            <Checkbox
              className='parent'
              onChange={handleCheckAll}
              checked={checkedItems.length > 0 && checkedItems.length === items.length}
              indeterminate={checkedItems.length > 0 && checkedItems.length < items.length}
            />
          }
          label={parentLabel}
          slotProps={{ typography: { fontWeight: 600 } }}
          sx={{ margin: 0, padding: 0 }}
        />
      </Grid>

      <Grid container rowGap={2} columnSpacing={5} px={4}>
        {items.map(item => (
          <Grid key={getItemId(item)} container item xs={12 / numColumns} style={itemsStyles}>
            <FormControlLabel
              className={isItemChecked(item) ? 'itemChecked' : 'itemUnchecked'}
              control={
                <Checkbox
                  className='child'
                  checked={isItemChecked(item)}
                  checkedIcon={<Check />}
                  onChange={() => handleCheckItem(item)}
                />
              }
              label={formatLabel(getItemLabel(item))}
              style={itemStyles}
              sx={{ flex: 1, margin: 0 }}
            />
          </Grid>
        ))}
      </Grid>

      {checkedItems.length === 0 && (
        <Grid container px={4} py={1}>
          <Typography variant='p16'>{noneSelectedMessage}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CheckboxList;
