import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { CourseData } from '../../models/CourseData';

export class CourseApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getAllCourses(): Promise<AxiosResponse<CourseData[]>> {
    return this.axios.get('course');
  }

  getCourse(id: number): Promise<AxiosResponse<CourseData>> {
    return this.axios.get(`course/${id}`);
  }

  createCourse(course: CourseData): Promise<AxiosResponse<CourseData>> {
    return this.axios.post('course', course);
  }

  updateCourse(course: CourseData): Promise<AxiosResponse<CourseData>> {
    return this.axios.patch('course', course);
  }

  // change back to id
  publishCourse(course: CourseData): Promise<AxiosResponse<CourseData>> {
    return this.axios.post(`course/publish/${course.courseId}`);
  }

  // change back to id
  unpublishCourse(course: CourseData): Promise<AxiosResponse<CourseData>> {
    return this.axios.delete(`course/unpublish/${course.courseId}`);
  }

  replaceCourse(course: CourseData): Promise<AxiosResponse<CourseData>> {
    return this.axios.put('course', course);
  }

  deleteCourse(id: number): Promise<AxiosResponse<CourseData>> {
    return this.axios.delete(`course/${id}`);
  }
}
