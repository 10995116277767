import { useSubject } from '@aesop-fables/scrinium';
import { CourseData } from '../models/CourseData';
import { CoursesSubject } from '../data/learning/subjects/CoursesSubject';
import { CoursesInitialized } from '../predicates/CoursesInitialized';

export const useCourses = () => {
  const courses = useSubject<CourseData[]>(CoursesSubject);
  return courses;
};

export const useCoursesAlphabetical = () => {
  const courses = useCourses();

  return courses?.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? -1);
};

export const useCoursesInitialized = () => {
  const initialized = useSubject<boolean>(CoursesInitialized);

  return initialized;
};
