import { inject } from '@aesop-fables/containr';
import { ISubject, DataCache, ScriniumServices } from '@aesop-fables/scrinium';
import type { IAppStorage } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { LearningCompartments, learningStorageKey } from '..';
import { MediaData } from '../../../models/MediaData';

export class MediaSubject implements ISubject<MediaData[]> {
  private readonly cache: DataCache<LearningCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly storage: IAppStorage) {
    this.cache = this.storage.retrieve<LearningCompartments>(learningStorageKey);
  }

  createObservable(): Observable<MediaData[]> {
    return this.cache.observe$<MediaData[]>('media');
  }
}
