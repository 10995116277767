import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Column, Page, Row, Searchbox, Spacer } from '../../components';
import {
  MutationStatus,
  MutatorState,
  useAppStorage,
  useMutation,
  useObservable,
} from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { UserCompartments, userStorageKey } from '../../data/user';
import { UserData } from '../../models/UserData';
import { ToastbarContext } from '../../App';
import { useLoading } from '../../hooks/useLoading';
import { Svgs } from '../../assets/svg';
import { ArtDashboardTeam } from '../../assets/png';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { TeamData } from '../../models/TeamData';
import { TeamCompartments, teamStorageKey } from '../../data/team';
import { DeleteTeam } from '../../data/team/mutations';
import { Toast } from '../../models/Toast';
import { GroupCompartments, groupStorageKey } from '../../data/group';
import { GroupData } from '../../models/GroupData';
import SubHeaderRow from '../multitier/SubHeaderRow';
import { DataDisplayProps } from '../multitier/AdminDashboard';
import { TeamMetricsData } from '../../models/MetricsData';

const TeamDataDisplay: React.FC<DataDisplayProps> = ({ select, saveData: saveTeamData }) => {
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const [tryAgain, setTryAgain] = useState<boolean>(false);

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const deleteTeam = useMutation(new DeleteTeam());

  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);

  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const allTeams = useObservable(teamDataCache.observe$<TeamData[]>('allTeams'));
  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const origin = useObservable(drillDownWizard.origin$);
  const groupData = useObservable(groupDataCache.observe$<GroupData>('group'));
  const numGroups = useObservable(groupDataCache.observe$<GroupData[]>('allGroups'))?.length ?? 0;
  const allTeamMetrics = useObservable(teamDataCache.observe$<TeamMetricsData[]>('allMetrics'));

  const groupName = groupData?.name ?? drillDownWizard.findGroupNameById();
  const numTeams = allTeams?.length ?? 0;
  const landingPage =
    (highestAccess === AdminTierTypeEnum.Team && numTeams >= 2) ||
    (highestAccess === AdminTierTypeEnum.Group && numGroups < 2) ||
    origin === AdminTierTypeEnum.Team;

  const onCreate = async (values: any) => {
    setLoading(true);
    await saveTeamData?.action({
      name: values.name,
    });
  };

  useEffect(() => {
    if (!tryAgain) {
      return;
    }
    if (deleteTeam.error) {
      setLoading(false);
      setToast(
        new Toast({
          message: 'Error removing team',
          severity: 'error',
          open: true,
        }),
      );
      setTryAgain(false);
    } else if (deleteTeam.status === MutationStatus.Complete) {
      setLoading(false);
      setToast(new Toast({ message: 'Team removed!', severity: 'success', open: true }));
      setTryAgain(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTeam, tryAgain]);

  if (allTeams === undefined || allTeamMetrics === undefined) {
    return null;
  }

  return (
    <Page
      title={
        landingPage ? (userData?.firstName ? `Hi, ${userData.firstName}!` : 'Hi!') : groupName
      }>
      <Spacer height='sm' />
      <SubHeaderRow
        onCreate={onCreate}
        hideCreate={highestAccess !== AdminTierTypeEnum.Group}
        tierIcon={<img src={ArtDashboardTeam} alt='Art Dashboard Team' width={70} height={70} />}
        tier='team'
      />
      <TeamDisplay teamList={allTeams} allMetrics={allTeamMetrics} select={select} />
    </Page>
  );
};

export interface TeamDisplayProps {
  teamList: TeamData[];
  allMetrics: TeamMetricsData[];
  select: MutatorState<number>;
}

const TeamDisplay: React.FC<TeamDisplayProps> = ({ teamList, allMetrics, select }) => {
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const [searchWord, setSearchWord] = useState('');
  const [filteredTeamDataForTable, setFilteredTeamDataForTable] = useState<TeamData[]>([]);
  const numTeams = teamList?.length ?? 0;
  const enoughTeams = numTeams >= 10;

  useEffect(() => {
    if (!searchWord) {
      setFilteredTeamDataForTable(teamList);
      return;
    }
    setFilteredTeamDataForTable(
      teamList.filter((item: any) =>
        Object.values(item).some(
          (val: any) => val?.toString().toLowerCase().includes(searchWord.toLowerCase()),
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, teamList]);

  const selectTeam = async (teamId: any) => {
    await select.action(teamId);
  };

  const onView = async (teamId: any) => {
    if (teamId === undefined) {
      setToast(new Toast({ message: 'No team id found', severity: 'error', open: true }));
      return;
    }
    setLoading(true);
    selectTeam(teamId);
  };

  return (
    <>
      {enoughTeams && (
        <>
          <Spacer height='xs' />
          <Card variant='ghost' color='primary'>
            <CardContent className='searchbox'>
              <Column>
                <Searchbox
                  callback={value => setSearchWord(value)}
                  label='Search'
                  placeholder='Search by team name'
                />
              </Column>
            </CardContent>
          </Card>
        </>
      )}

      <Spacer height='xs' />
      <Card variant='ghost' color='primary'>
        <CardContent
          className='pad25'
          sx={{ textAlign: filteredTeamDataForTable.length > 0 ? undefined : 'center' }}>
          {numTeams === 0 ? (
            <Typography
              variant='p20'
              color='secondary.main'
              textAlign='center'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              You haven't created any teams yet.
            </Typography>
          ) : filteredTeamDataForTable.length > 0 ? (
            filteredTeamDataForTable.map((team, i) => {
              const metrics = allMetrics.find(x => x.teamId === team.id);
              return (
                <Card
                  variant='ghost'
                  color='secondary'
                  key={team.id}
                  sx={{ marginTop: i === 0 ? '0px' : '10px' }}>
                  <CardContent className='pad10'>
                    <Row style={{ justifyContent: 'space-between' }}>
                      <Row
                        style={{
                          alignItems: 'center',
                        }}>
                        <Svgs.IconTeamsDefault />
                      </Row>
                      <Row
                        style={{
                          alignItems: 'center',
                          flex: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        <Spacer width='xxs' />
                        <Link
                          className='darkenOnHover'
                          noWrap={true}
                          sx={{ flex: 1 }}
                          variant='p18SemiBold'
                          color='secondary.main'
                          onClick={() => {
                            onView(team.id);
                          }}>
                          {team.name}
                        </Link>
                        <Spacer width='lg' />
                      </Row>

                      <Row
                        style={{
                          alignItems: 'center',
                        }}>
                        {metrics ? (
                          <>
                            <Column>
                              <Typography variant='p12SemiBold' color='secondary.main'>
                                Organizations
                              </Typography>
                              <Row style={{ alignItems: 'center' }}>
                                <Svgs.IconOrgPaysLarge />
                                <Spacer width='xxs' />
                                <Typography variant='p20SemiBold' color='secondary.main'>
                                  {metrics.organizations}
                                </Typography>
                              </Row>
                            </Column>
                            <Spacer width='md' />
                            <Column>
                              <Typography variant='p12SemiBold' color='secondary.main'>
                                Active users
                              </Typography>
                              <Row style={{ alignItems: 'center' }}>
                                <Svgs.IconMembersDefault />
                                <Spacer width='xxs' />
                                <Typography variant='p20SemiBold' color='secondary.main'>
                                  {metrics.activeLicenses}
                                </Typography>
                              </Row>
                            </Column>
                          </>
                        ) : (
                          <div style={{ padding: '24px ' }} />
                        )}

                        <Spacer width='xxl' />
                        <Button
                          onClick={() => onView(team.id)}
                          sx={{
                            padding: '5px 30px',
                            typography: { fontSize: 12 },
                            height: '30px',
                          }}
                          color='secondary'>
                          View
                        </Button>
                      </Row>
                    </Row>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Typography variant='p18SemiBold' color='secondary.main'>
              No results found
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default TeamDataDisplay;
