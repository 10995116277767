import { DataCache, Predicate, injectDataCache } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { LmsPermission } from '../api/apis/LmsPermissionApi';
import { UserCompartments, userStorageKey } from '../data/user';

export class LmsEnabled implements Predicate {
  constructor(
    @injectDataCache(userStorageKey)
    private readonly learningDataCache: DataCache<UserCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.learningDataCache
      .observe$<LmsPermission>('permission')
      .pipe(map(x => x?.lmsPermission === 'READ' || x?.lmsPermission === 'WRITE'));
  }
}
