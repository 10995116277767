import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { LessonContent } from '../../models/CourseData';

export class LessonContentApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getLessonContent(id: number): Promise<AxiosResponse<LessonContent>> {
    return this.axios.get(`content/${id}`);
  }

  getLessonContentByLessonId(lessonId: number): Promise<AxiosResponse<LessonContent>> {
    return this.axios.get(`content/by-lesson?lessonId=${lessonId}`);
  }

  getLessonContentIcon(id: number): Promise<AxiosResponse<string>> {
    return this.axios.get(`content/icon/${id}`);
  }

  getLessonContentBackground(id: number): Promise<AxiosResponse<string>> {
    return this.axios.get(`content/background/${id}`);
  }

  createLessonContent(content: LessonContent): Promise<AxiosResponse<LessonContent>> {
    return this.axios.post('content', content);
  }

  updateLessonContent(content: LessonContent): Promise<AxiosResponse<LessonContent>> {
    return this.axios.patch('content', content);
  }

  updateLessonContentOrder(
    contents: LessonContent[],
    lessonId: number,
  ): Promise<AxiosResponse<LessonContent[]>> {
    return this.axios.patch(`content/order?lessonId=${lessonId}`, contents);
  }

  replaceLessonContent(content: LessonContent): Promise<AxiosResponse<LessonContent>> {
    return this.axios.put('content', content);
  }

  deleteLessonContent(id: number): Promise<AxiosResponse<LessonContent>> {
    return this.axios.delete(`content/${id}`);
  }
}
