import { DataCache, Predicate, injectDataCache } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { LearningCompartments, learningStorageKey } from '../data/learning';

export class CoursesInitialized implements Predicate {
  constructor(
    @injectDataCache(learningStorageKey)
    private readonly learningDataCache: DataCache<LearningCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.learningDataCache.findCompartment('courses').initialized$;
  }
}
