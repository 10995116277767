import { Scopes, createServiceModule } from '@aesop-fables/containr';
import { IsAuthenticated } from './IsAuthenticated';
import { UserRolesInitialized } from './UserRolesInitialized';
import { Predicates } from './Types';
import { UserInitialized } from './UserInitialized';
import { GroupFeatureMapInitialized } from './GroupFeatureMapInitialized';
import { GroupSelected } from './GroupSelected';
import { TeamSelected } from './TeamSelected';
import { OrganizationSelected } from './OrganizationSelected';
import { SubscriptionInitialized } from './SubscriptionInitialized';
import { LicenseAgreementInitialized } from './LicenseAgreementInitialized';
import { PricebooksSelected } from './PricebooksSelected';
import { LmsEnabled } from './LmsEnabled';

export const usePredicateServiceModule = createServiceModule('PredicateServiceModule', services => {
  services.autoResolve(Predicates.IsAuthenticated, IsAuthenticated, Scopes.Singleton);
  services.autoResolve(
    Predicates.GroupFeatureMapInitialized,
    GroupFeatureMapInitialized,
    Scopes.Singleton,
  );
  services.autoResolve(Predicates.GroupSelected, GroupSelected, Scopes.Singleton);
  services.autoResolve(
    Predicates.LicenseAgreementInitialized,
    LicenseAgreementInitialized,
    Scopes.Singleton,
  );
  services.autoResolve(Predicates.LmsEnabled, LmsEnabled, Scopes.Singleton);
  services.autoResolve(Predicates.OrganizationSelected, OrganizationSelected, Scopes.Singleton);
  services.autoResolve(Predicates.PricebooksSelected, PricebooksSelected, Scopes.Singleton);
  services.autoResolve(
    Predicates.SubscriptionInitialized,
    SubscriptionInitialized,
    Scopes.Singleton,
  );
  services.autoResolve(Predicates.TeamSelected, TeamSelected, Scopes.Singleton);
  services.autoResolve(Predicates.UserInitialized, UserInitialized, Scopes.Singleton);
  services.autoResolve(Predicates.UserRolesInitialized, UserRolesInitialized, Scopes.Singleton);
});
