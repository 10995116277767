import { BehaviorSubject, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import {
  OrganizationData,
  RestrictionEnum,
  CommunicationsData,
  DefaultCommunicationsData,
  ProductEnum,
} from '../../models/OrganizationData';
import { Scopes, createServiceModule } from '@aesop-fables/containr';
import { UserData } from '../../models/UserData';

export interface AuthenticatedWizardState {
  templateId: number;
  product: ProductEnum;
  organizationData: OrganizationData;
  communicationsData: CommunicationsData;
  userData: UserData;
}

export const InitialAuthenticatedState: AuthenticatedWizardState = {
  templateId: 3,
  product: ProductEnum.GuidedChoice,
  organizationData: {},
  communicationsData: DefaultCommunicationsData,
  userData: {},
};

export const authenticatedWizardKey = 'services/authenticatedWizard';

class AuthenticatedWizard implements Disposable {
  private readonly state: BehaviorSubject<AuthenticatedWizardState> = new BehaviorSubject(
    InitialAuthenticatedState,
  );

  constructor() {
    console.log('creating authenticated wizard');
  }

  get state$(): Observable<AuthenticatedWizardState> {
    return this.state;
  }

  /* this is wrong, need to add another object for it */
  setRestriction(restrictMemberAccess: RestrictionEnum) {
    const current = this.state.value;
    const next = {
      ...current,
      organizationData: { ...current.organizationData, restrictMemberAccess },
    };
    this.state.next(next);
  }

  setCommunications(communications: CommunicationsData) {
    const current = this.state.value;
    const next = {
      ...current,
      communicationsData: communications,
    };
    this.state.next(next);
  }

  saveAboutYou(userData: UserData) {
    const current = this.state.value;
    const next = {
      ...current,
      userData: { ...current.userData, userData },
    };
    this.state.next(next);
    // TODO: call api to save about you data after
    // still need an API to patch user ; only have a GET at the moment
  }

  dispose(): void {
    console.log('disposing AuthenticatedWizard');
  }
}

export const withAuthWizard = createServiceModule(authenticatedWizardKey, services => {
  services.autoResolve<AuthenticatedWizard>(
    authenticatedWizardKey,
    AuthenticatedWizard,
    Scopes.Singleton,
  );
});

export { AuthenticatedWizard };
