import { BehaviorSubject, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';

import type { IAppStorage } from '@aesop-fables/scrinium';
import { ScriniumServices } from '@aesop-fables/scrinium';
import { inject, createServiceModule, Scopes } from '@aesop-fables/containr';

import {
  SignUpWizardState,
  InitialSignUpState,
  OnboardingStepsDefault,
  OnboardingStepsAdp,
  StepData,
} from '../../models/SignUpData';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { TemplateData } from '../../models/SignUpData';
import { Location } from 'react-router-dom';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { GooglePlacesApi } from '../../api/apis/GooglePlacesApi';
export const signUpWizardKey = 'services/signUpWizard';

export enum OnboardingTemplateIdEnum {
  'Default' = 1,
  'Adp' = 2,
}

const getOnboardingSteps = (template: TemplateData): StepData[] => {
  switch (template.id) {
    case OnboardingTemplateIdEnum.Adp:
      return OnboardingStepsAdp;
    default:
      return OnboardingStepsDefault;
  }
};

class SignUpWizard implements Disposable {
  private readonly state: BehaviorSubject<SignUpWizardState> = new BehaviorSubject(
    InitialSignUpState,
  );

  private readonly signUpComplete: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private onboardingSteps: StepData[] = OnboardingStepsDefault;
  private googleKey: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.GooglePlaces)
    private readonly googlePlacesApi: GooglePlacesApi,
  ) {
    this.init();
  }

  async init() {
    try {
      const signUpDataCache = this.appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
      signUpDataCache.observe$<TemplateData>('onboardingTemplate').subscribe(template => {
        if (template) {
          this.onboardingSteps = getOnboardingSteps(template);
        }
      });

      const googleKey = await this.googlePlacesApi.get();
      this.googleKey.next(googleKey.data);
    } catch (err) {
      console.error(err);
    }
  }

  get signUpComplete$(): Observable<boolean> {
    return this.signUpComplete;
  }

  get googlePlacesKey$(): Observable<string> {
    return this.googleKey;
  }

  getCurrent(location: Location): string {
    return location.pathname.split('onboarding/')[1];
  }

  resumeOnboarding(lastCompletedStep: string): StepData {
    const index = this.onboardingSteps.findIndex(s => s.current === lastCompletedStep);
    const step = this.onboardingSteps.at(index + 1);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  nextStep(location: Location): StepData {
    const current = location.pathname.split('onboarding/')[1];
    const index = this.onboardingSteps.findIndex(s => s.current === current);
    const step = this.onboardingSteps.at(index + 1);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  currentStep(location: Location): StepData {
    const current = this.getCurrent(location);
    const step = this.onboardingSteps.find(s => s.current === current);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  previousStep(location: Location): StepData {
    const current = location.pathname.split('onboarding/')[1];
    const index = this.onboardingSteps.findIndex(s => s.current === current);
    const step = this.onboardingSteps.at(index - 1);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  getTotalSteps(): number {
    return this.onboardingSteps.length;
  }

  agreeAndSignUp() {
    this.signUpComplete.next(true);
  }

  reset() {
    this.signUpComplete.next(false);
  }

  dispose(): void {
    console.log('disposing SignUpWizard');
  }

  getCurrentStepIndex(location: Location) {
    const current = location.pathname.split('onboarding/')[1];
    const index = OnboardingStepsDefault.findIndex(s => s.current === current);
    return index + 1;
  }
}

export const withSignUpWizard = createServiceModule(signUpWizardKey, services => {
  services.autoResolve<SignUpWizard>(signUpWizardKey, SignUpWizard, Scopes.Singleton);
});

export { SignUpWizard };
