import { Card, CardContent, Typography, Link, Button } from '@mui/material';
import { useState } from 'react';
import { Page, Spacer } from '../../components';
import useResetToOrigin from '../../hooks/useResetToOrigin';
import AboutYouCard from './AboutYouCard';

const Profile = () => {
  const [onGo, setOnGo] = useState<boolean>(false);
  const resetToOrigin = useResetToOrigin(onGo, setOnGo);
  const navToHome = () => {
    setOnGo(true);
    return resetToOrigin;
  };

  return (
    <Page title='Profile'>
      <Spacer height='xs' />
      <AboutYouCard />
      <Spacer height='sm' />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button className='sm' variant='outlined' color='secondary' onClick={navToHome}>
          Back to home
        </Button>
      </div>
    </Page>
  );
};

interface ProfileCardProps extends React.PropsWithChildren {
  title?: string;
  editable: boolean;
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onEdit?: () => void;
  header?: React.ReactNode;
}

export const ProfileCard: React.FC<ProfileCardProps> = props => {
  const { title, editable, setDrawerOpen, onEdit, header } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit();
      return;
    }
    if (setDrawerOpen) {
      setDrawerOpen(true);
      return;
    }
  };
  return (
    <>
      {typeof header !== 'undefined' ? (
        <>
          {header}
          <Spacer height='xs' />
        </>
      ) : (
        title && (
          <>
            <Typography variant='p30SemiBold' color='secondary.main'>
              {title}
            </Typography>
            <Spacer height='xs' />
          </>
        )
      )}
      <Card variant='ghost' color='primary'>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          className={editable ? `profileCard` : `communications`}>
          {editable ? (
            <Link onClick={onEditClick} style={{ alignSelf: 'flex-end' }} variant='body2'>
              Edit
            </Link>
          ) : null}

          {props.children}
        </CardContent>
      </Card>
    </>
  );
};

export default Profile;
