import { Scopes, createServiceModule } from '@aesop-fables/containr';
import { CoursesSubject } from './subjects/CoursesSubject';
import { LessonCategoriesSubject } from './subjects/LessonCategoriesSubject';
import { getMiddlewareAliasRegistry } from '@aesop-fables/scrinium';
import { ReloadCourses } from './commands/ReloadCourses';
import { MediaSubject } from './subjects/MediaSubject';
import { UploadMedia } from './commands/UploadMedia';

export const LearningServices = {
  Courses: 'learning/subjects/courses',
  LessonCategories: 'learning/subjects/lessonCategories',
  Media: 'learning/subjects/media',
  ReloadCourses: 'learning/commands/ReloadCourses',
  UploadMedia: 'learning/commands/UploadMedia',
};

export const useLearningServiceModule = createServiceModule('learning/services', services => {
  services.autoResolve(LearningServices.Courses, CoursesSubject, Scopes.Transient);
  services.autoResolve(
    LearningServices.LessonCategories,
    LessonCategoriesSubject,
    Scopes.Transient,
  );
  services.autoResolve(LearningServices.Media, MediaSubject, Scopes.Transient);

  const registry = getMiddlewareAliasRegistry();
  registry[LearningServices.ReloadCourses] = ReloadCourses;
  registry[LearningServices.UploadMedia] = UploadMedia;
});
