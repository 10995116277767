import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { InvoiceApi, InvoiceData } from '../../api/apis/InvoiceApi';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface InvoiceCompartments {
  invoices: DataCompartmentOptions<InvoiceData[] | undefined>;
}

export const invoiceStorageKey = 'data/invoices';

export const withInvoiceData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<InvoiceCompartments>({
      invoices: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(OrganizationSelected) },
        source: new ConfiguredDataSource(async () => {
          const invoiceApi = container.get<InvoiceApi>(ApiKeys.Invoice);
          const { data } = await invoiceApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(invoiceStorageKey, cache);
  },
);
