import { IListener, injectDataCache, injectSubject } from '@aesop-fables/scrinium';
import type { DataCache } from '@aesop-fables/scrinium';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { Predicates } from '../../../predicates/Types';
import { PaymentCompartments, paymentStorageKey } from '..';

export class PaymentMethodsListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(paymentStorageKey)
    private readonly cache: DataCache<PaymentCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('paymentMethods');
        } else {
          this.cache.reset('paymentMethods');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class DefaultPaymentMethodListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(paymentStorageKey)
    private readonly cache: DataCache<PaymentCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('defaultPaymentMethod');
        } else {
          this.cache.reset('defaultPaymentMethod');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class PaymentHistoryListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(paymentStorageKey)
    private readonly cache: DataCache<PaymentCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('paymentHistory');
        } else {
          this.cache.reset('paymentHistory');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class StripeSessionStatusListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(paymentStorageKey)
    private readonly cache: DataCache<PaymentCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('stripeSessionStatus');
        } else {
          this.cache.reset('stripeSessionStatus');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class PaidStatusListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(paymentStorageKey)
    private readonly cache: DataCache<PaymentCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('paidStatus');
        } else {
          this.cache.reset('paidStatus');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}
