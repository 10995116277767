import { inject } from '@aesop-fables/containr';
import { ISubject, DataCache, ScriniumServices } from '@aesop-fables/scrinium';
import type { IAppStorage } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { CourseData, PublicationStatusEnum } from '../../../models/CourseData';
import { LearningCompartments, learningStorageKey } from '..';

export class CoursesSubject implements ISubject<CourseData[]> {
  private readonly cache: DataCache<LearningCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly storage: IAppStorage) {
    this.cache = this.storage.retrieve<LearningCompartments>(learningStorageKey);
  }

  createObservable(): Observable<CourseData[]> {
    return this.cache.observe$<CourseData[]>('courses').pipe(
      map(
        courses =>
          // filter active
          courses?.filter(course => course.active || course.status === PublicationStatusEnum.DRAFT),
      ),
    );
  }
}
