import { OrganizationMetadata } from './../../models/OrganizationMetadata';
import { OrganizationMetadataApi } from './../../api/apis/OrganizationMetadataApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface OrganizationMetadataCompartments {
  metadata: DataCompartmentOptions<OrganizationMetadata | undefined>;
}

export const organizationMetadataStorageKey = 'data/organizationMetadata';

export const withOrganizationMetadata = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<OrganizationMetadataCompartments>({
      metadata: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const orgMetadataApi = container.get<OrganizationMetadataApi>(
            ApiKeys.OrganizationMetadata,
          );
          const domains = await orgMetadataApi.get();
          return domains?.data;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(organizationMetadataStorageKey, cache);
  },
);
