import { Typography } from '@mui/material';
import { useCoursesAlphabetical, useCoursesInitialized } from '../../../../hooks/useCourses';
import { Column, Row } from '../../../../components';
import { useEffect } from 'react';
import '../../../../themes/tippy-rounded.css';
import CourseItem from './CourseItems';
import { useLoading } from '../../../../hooks/useLoading';

const CourseList = () => {
  const courses = useCoursesAlphabetical();
  const coursesInitialized = useCoursesInitialized();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (coursesInitialized) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [coursesInitialized, setLoading]);

  if (courses?.length === 0) {
    return (
      <Row style={{ justifyContent: 'center' }}>
        <Typography variant='p20SemiBold' color='secondary'>
          You haven't created any courses yet.
        </Typography>
      </Row>
    );
  }

  return (
    <Column style={{ rowGap: '10px' }}>
      {courses?.map(course => <CourseItem key={course.courseId} course={course} />)}
    </Column>
  );
};

export default CourseList;
