import { ApiKeys } from '../../api/apis/ApiKeys';
import { RestrictionApi } from '../../api/apis/RestrictionApi';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { OrgDomain } from '../../models/OffboardingData';
import { PricebooksSelected } from '../../predicates/PricebooksSelected';

export interface RestrictionCompartments {
  restrictions: DataCompartmentOptions<OrgDomain[] | undefined>;
}

export const restrictionStorageKey = 'data/restrictions';

export const withRestrictionData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<RestrictionCompartments>({
      restrictions: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(PricebooksSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const restrictionApi = container.get<RestrictionApi>(ApiKeys.Restriction);
          const domains = await restrictionApi.get();
          return domains?.data;
        }),
        defaultValue: [],
      },
    });

    appStorage.store(restrictionStorageKey, cache);
  },
);
