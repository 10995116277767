import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { Predicates } from './Types';
import { SubscriptionCompartments, subscriptionStorageKey } from '../data/subscription';

@predicate(Predicates.OrganizationSelected)
export class SubscriptionInitialized implements Predicate {
  constructor(
    @injectDataCache(subscriptionStorageKey)
    private readonly subscriptionDataCache: DataCache<SubscriptionCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.subscriptionDataCache.initialized$;
  }
}
