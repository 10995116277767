import {
  DataCompartmentOptions,
  ConfiguredDataSource,
  createDataCache,
  createDataCacheModule,
  IAppStorage,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { TeamData } from '../../models/TeamData';
import { TeamApi } from '../../api/apis/TeamApi';
import { TeamMetricsData } from '../../models/MetricsData';
import { TeamMetricsApi } from '../../api/apis/TeamMetricsApi';
import { UserRolesInitialized } from '../../predicates/UserRolesInitialized';
import { GroupSelected } from '../../predicates/GroupSelected';

export interface TeamCompartments {
  team: DataCompartmentOptions<TeamData | undefined>;
  allTeams: DataCompartmentOptions<TeamData[] | undefined>;
  allMetrics: DataCompartmentOptions<TeamMetricsData[] | undefined>;
}

export const teamStorageKey = 'data/team';

export const withTeamData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<TeamCompartments>({
      team: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(UserRolesInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const teamApi = container.get<TeamApi>(ApiKeys.Team);
            const { data } = await teamApi.get();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allTeams: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(GroupSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const teamApi = container.get<TeamApi>(ApiKeys.Team);
            const { data } = await teamApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allMetrics: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(GroupSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const teamMetricsApi = container.get<TeamMetricsApi>(ApiKeys.TeamMetrics);
            const { data } = await teamMetricsApi.getAll();
            if (!data) {
              return undefined;
            }
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(teamStorageKey, cache);
  },
);
