import { IObservableQuery, injectSubject } from '@aesop-fables/scrinium';
import { CourseData, UnitData } from '../../../models/CourseData';
import { LearningServices } from '../LearningServiceModule';
import { Observable, map } from 'rxjs';
import { findLessonSource } from '../commands/utils';

export class FindUnitById implements IObservableQuery<number, UnitData | undefined> {
  constructor(
    @injectSubject(LearningServices.Courses) private readonly courses: Observable<CourseData[]>,
  ) {}

  execute(unitId: number): Observable<UnitData | undefined> {
    return this.courses.pipe(
      map(courses => {
        const { courseIndex, unitIndex } = findLessonSource({ unitId }, courses);
        if (courseIndex !== -1 && typeof unitIndex !== 'undefined') {
          const course = courses[courseIndex];
          const unit = course.units?.at(unitIndex);
          return unit;
        }
      }),
    );
  }
}
