import { Box, SxProps, Theme, Typography, TypographyProps } from '@mui/material';

interface TruncatedFilenameProps {
  filename: string;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
  sx?: SxProps<Theme>;
}

export const TruncatedFilename: React.FC<TruncatedFilenameProps> = ({
  filename,
  variant,
  color,
  sx,
}) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return (
      <Typography variant={variant} color={color} noWrap title={filename}>
        {filename}
      </Typography>
    );
  }

  const name = filename.substring(0, lastDotIndex);
  const extension = filename.substring(lastDotIndex);

  return (
    <Box sx={{ display: 'flex', width: '100%', ...sx }}>
      <Typography variant={variant} color={color} noWrap title={filename}>
        {name}
      </Typography>
      <Typography variant={variant} color={color}>
        {extension}
      </Typography>
    </Box>
  );
};
