import { Card, CardContent, Typography } from '@mui/material';

const ContentOutputEmpty: React.FC = () => {
  return (
    <Card variant='outlined' style={{ borderColor: '#e8f1f3' }}>
      <CardContent className='pad20' style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='p16' color='#1e2969' fontFamily='Roboto'>
          no content added yet
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ContentOutputEmpty;
