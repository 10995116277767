import type { AxiosInstance } from 'axios';
import { AxiosResponse } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import { LessonData } from '../../models/CourseData';

export class LessonApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getLesson(id: number): Promise<AxiosResponse<LessonData>> {
    return this.axios.get(`lesson/${id}`);
  }

  getLessonByUnitId(unitId: number): Promise<AxiosResponse<LessonData>> {
    return this.axios.get(`lesson/by-unit?unitId=${unitId}`);
  }

  createLesson(lesson: LessonData): Promise<AxiosResponse<LessonData>> {
    return this.axios.post('lesson', lesson);
  }

  createDraft(lessonId: number): Promise<AxiosResponse<LessonData>> {
    return this.axios.post(`lesson/draft/${lessonId}`);
  }

  updateLesson(lesson: LessonData): Promise<AxiosResponse<LessonData>> {
    return this.axios.patch('lesson', lesson);
  }

  // revert back to id
  publishLesson(lesson: LessonData): Promise<AxiosResponse<LessonData>> {
    return this.axios.post(`lesson/publish/${lesson.lessonId}`);
  }

  // revert back to id
  unpublishLesson(lesson: LessonData): Promise<AxiosResponse<LessonData>> {
    return this.axios.delete(`lesson/unpublish/${lesson.lessonId}`);
  }

  // can send lessons with just their ids and updated orders
  updateLessonOrder(lessons: LessonData[], unitId: number): Promise<AxiosResponse<LessonData[]>> {
    return this.axios.patch(`lesson/order?unitId=${unitId}`, lessons);
  }

  deleteLesson(id: number): Promise<AxiosResponse<LessonData>> {
    return this.axios.delete(`lesson/${id}`);
  }
}
