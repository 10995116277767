import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseApi } from '../../../api/apis/CourseApi';
import { CourseData, PublicationStatusEnum } from '../../../models/CourseData';

export class AddOrUpdateCourse implements IDataCommand<CourseData, CourseData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Course) private readonly api: CourseApi,
  ) {}

  async execute(course: CourseData): Promise<CourseData> {
    let response: CourseData | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (course.courseId) {
        const newCourses = [...courses];
        const courseIndex = newCourses.findIndex(x => x.courseId === course.courseId);
        if (courseIndex !== -1) {
          const units = newCourses[courseIndex].units;

          // returns new draft if course is already published
          let { data: updatedCourse } =
            course.icon === null || course.background === null
              ? await this.api.replaceCourse(course)
              : await this.api.updateCourse(course);

          // only publish if original course was published
          if (course.status === PublicationStatusEnum.PUBLISHED) {
            const { data: publishedCourse } = await this.api.publishCourse(updatedCourse);
            updatedCourse = publishedCourse;
          }
          updatedCourse.units = units;
          newCourses[courseIndex] = updatedCourse;
          response = updatedCourse;
        }

        return newCourses;
      }

      const { data } = await this.api.createCourse(course);
      response = data;

      return [...courses, data];
    });

    return response as CourseData;
  }
}
