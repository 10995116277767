import { Grid, Card } from '@mui/material';
import MediaImage from '../../../../components/MediaImage';
import { TruncatedFilename } from '../../../../components/TruncatedFilename';
import { MediaData, MediaType } from '../../../../models/MediaData';

interface MediaLibraryGridProps {
  media: MediaData[] | null;
  selectedMedia: MediaData | null;
  setSelectedMedia: (media: MediaData) => void;
  mediaType: MediaType;
}

const MediaLibraryGrid: React.FC<MediaLibraryGridProps> = ({
  media,
  selectedMedia,
  setSelectedMedia,
  mediaType,
}) => {
  return (
    <Grid container spacing={2}>
      {media
        ?.filter(media => media.type === mediaType)
        .map(media => (
          <Grid item xs={mediaType === MediaType.BACKGROUND ? 6 : 3} key={media.id}>
            <Card
              className={
                selectedMedia?.id === media.id ? 'media-item-card-selected' : 'media-item-card'
              }
              variant='ghost'
              onClick={() => setSelectedMedia(media)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Grid container justifyContent='center' alignItems='center' xs={12} p={1}>
                <MediaImage media={media} />
              </Grid>
              <TruncatedFilename
                filename={media.mediaName}
                variant='p12'
                color='secondary.main'
                sx={{
                  pb: '3px',
                  width: '100%',
                  justifyContent: 'center',
                  alignSelf: 'flex-end',
                }}
              />
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default MediaLibraryGrid;
