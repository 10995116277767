import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { LicenseMetricsApi, LicenseMetricsData } from '../../api/apis/LicenseMetricsApi';
import { LicenseAgreementApi, LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';
import { SubscriptionInitialized } from '../../predicates/SubscriptionInitialized';

export interface LicenseCompartments {
  licenseAgreement: DataCompartmentOptions<LicenseAgreementData[] | undefined>;
  licenseMetrics: DataCompartmentOptions<LicenseMetricsData[] | undefined>;
}

export const licenseStorageKey = 'data/license';

export const withLicenseData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<LicenseCompartments>({
      licenseAgreement: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const licenseAgreementApi = container.get<LicenseAgreementApi>(ApiKeys.LicenseAgreement);
          const { data } = await licenseAgreementApi.getAllDocs();
          return data;
        }),
        defaultValue: undefined,
      },
      licenseMetrics: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(SubscriptionInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const licenseMetricsApi = container.get<LicenseMetricsApi>(ApiKeys.LicenseMetrics);
            const { data } = await licenseMetricsApi.get();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(licenseStorageKey, cache);
  },
);
