import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MediaApi } from '../../../api/apis/MediaApi';
import { MediaType, MediaData } from '../../../models/MediaData';

export interface UploadMediaParams {
  file: File;
  type: MediaType;
}

export class UploadMedia implements IDataCommand<UploadMediaParams, MediaData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Media) private readonly api: MediaApi,
  ) {}

  async execute(params: UploadMediaParams): Promise<MediaData> {
    const formData = new FormData();
    formData.append('media', params.file);

    const { data } = await this.api.upload(formData, params.type);

    await this.cache.modify<MediaData[]>('media', async currentMedia => {
      return [...currentMedia, data];
    });

    return data;
  }
}
