import { IServiceContainer } from '@aesop-fables/containr';
import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import { CategoryData, CourseData } from '../../models/CourseData';
import { CourseApi } from '../../api/apis/CourseApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { LessonCategoryApi } from '../../api/apis/LessonCategoryApi';
import { MediaApi } from '../../api/apis/MediaApi';
import { MediaData } from '../../models/MediaData';
import { LmsEnabled } from '../../predicates/LmsEnabled';

export interface LearningCompartments {
  courses: DataCompartmentOptions<CourseData[] | undefined>;
  lessonCategories: DataCompartmentOptions<CategoryData[] | undefined>;
  media: DataCompartmentOptions<MediaData[] | undefined>;
}

export const learningStorageKey = 'data/learning';

export const withLearningData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<LearningCompartments>({
      courses: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(LmsEnabled),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const courseApi = container.get<CourseApi>(ApiKeys.Course);
            const { data } = await courseApi.getAllCourses();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: [],
      },
      lessonCategories: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: container.resolve(LmsEnabled),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const categoryApi = container.get<LessonCategoryApi>(ApiKeys.LessonCategory);
            const { data } = await categoryApi.getAll();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      media: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: container.resolve(LmsEnabled),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const mediaApi = container.get<MediaApi>(ApiKeys.Media);
            const { data } = await mediaApi.getAll();
            return data;
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(learningStorageKey, cache);
  },
);
