import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { LicenseCompartments, licenseStorageKey } from '../data/license';
import { Predicates } from './Types';

@predicate(Predicates.OrganizationSelected)
export class LicenseAgreementInitialized implements Predicate {
  constructor(
    @injectDataCache(licenseStorageKey)
    private readonly licenseDataCache: DataCache<LicenseCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.licenseDataCache.findCompartment('licenseAgreement').initialized$;
  }
}
