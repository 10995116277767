import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MediaData } from '../models/MediaData';

export enum EnvironmentEnum {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  COMPARE = 'Published',
}

export interface EnvironmentOptions {
  [EnvironmentEnum.DESKTOP]?: boolean;
  [EnvironmentEnum.MOBILE]?: boolean;
  [EnvironmentEnum.COMPARE]?: boolean;
}

export const defaultEnvironmentOptions = {
  [EnvironmentEnum.DESKTOP]: true,
  [EnvironmentEnum.MOBILE]: true,
  [EnvironmentEnum.COMPARE]: false,
};

export interface ILivePreviewContext {
  content: Map<string, string>;
  getPreviewContentByKey: (key: string) => string | undefined;
  setPreviewContent: (key: string, previewContent: string) => void;
  getPreviewMedia: (key: string) => MediaData | null;
  setPreviewMedia: (key: string, media: MediaData | null) => void;
  getPreviewInputError: (key: string) => string | undefined;
  setPreviewInputError: (key: string, previewContent?: string) => void;
  environment: EnvironmentEnum;
  setEnvironment: (env: EnvironmentEnum) => void;
  environmentOptions: EnvironmentOptions;
}

export const LivePreviewContext = createContext<ILivePreviewContext>({
  content: new Map(),
  getPreviewContentByKey: (key: string) => undefined,
  setPreviewContent: (key: string, previewContent: string) => {},
  getPreviewMedia: (key: string) => null,
  setPreviewMedia: (key: string, media: MediaData | null) => {},
  getPreviewInputError: (key: string) => undefined,
  setPreviewInputError: (key: string, error?: string) => {},
  environment: EnvironmentEnum.DESKTOP,
  setEnvironment: (env: EnvironmentEnum) => {},
  environmentOptions: defaultEnvironmentOptions,
});

export interface LivePreviewProviderProps extends PropsWithChildren {
  initialEnvironment?: EnvironmentEnum;
  environmentOptions?: EnvironmentOptions;
}

export const LivePreviewProvider: React.FC<LivePreviewProviderProps> = ({
  children,
  initialEnvironment,
  environmentOptions = defaultEnvironmentOptions,
}) => {
  const [content, setContent] = useState<Map<string, string>>(new Map());
  const [inputError, setInputError] = useState<Map<string, string | undefined>>(new Map());
  const [environment, setEnvironment] = useState<EnvironmentEnum>(EnvironmentEnum.DESKTOP);

  useEffect(() => {
    if (initialEnvironment && environmentOptions[initialEnvironment]) {
      setEnvironment(initialEnvironment);
    }
  }, [environmentOptions, initialEnvironment]);

  const getPreviewContentByKey = useCallback(
    (key: string) => {
      const previewContent = content.get(key);
      return previewContent;
    },
    [content],
  );

  const setPreviewContent = useCallback((key: string, previewContent: string) => {
    setContent(prevContent => {
      const newContent = new Map(prevContent);
      newContent.set(key, previewContent);
      return newContent;
    });
  }, []);

  const getPreviewMedia = useCallback(
    (key: string): MediaData | null => {
      const mediaStr = content.get(key);
      if (!mediaStr) return null;
      try {
        return JSON.parse(mediaStr);
      } catch {
        return null;
      }
    },
    [content],
  );

  const setPreviewMedia = useCallback((key: string, media: MediaData | null) => {
    setContent(prevContent => {
      const newContent = new Map(prevContent);
      newContent.set(key, media ? JSON.stringify(media) : '');
      return newContent;
    });
  }, []);

  const getPreviewInputError = useCallback(
    (key: string) => {
      const previewContent = inputError.get(key);
      return previewContent;
    },
    [inputError],
  );

  const setPreviewInputError = useCallback((key: string, previewContent?: string) => {
    setInputError(prevContent => {
      const newContent = new Map(prevContent);
      newContent.set(key, previewContent);
      return newContent;
    });
  }, []);

  return (
    <LivePreviewContext.Provider
      value={{
        content,
        getPreviewContentByKey,
        setPreviewContent,
        getPreviewMedia,
        setPreviewMedia,
        getPreviewInputError,
        setPreviewInputError,
        environment,
        setEnvironment,
        environmentOptions,
      }}>
      {children}
    </LivePreviewContext.Provider>
  );
};

export function useLivePreviewContext() {
  const context = useContext(LivePreviewContext);

  if (!context) {
    throw new Error('useLivePreviewContent must be used within LivePreviewProvider');
  }

  return context;
}
