import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { SubscriptionApi, SubscriptionData } from '../../api/apis/SubscriptionApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface SubscriptionCompartments {
  subscription: DataCompartmentOptions<SubscriptionData[] | undefined>;
}

export const subscriptionStorageKey = 'data/subscription';

export const withSubscriptionData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<SubscriptionCompartments>({
      subscription: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);
          const { data } = await subscriptionApi.get();
          return data;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(subscriptionStorageKey, cache);
  },
);
