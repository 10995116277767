export enum MediaType {
  ICON = 'ICON',
  BACKGROUND = 'BACKGROUND',
}

export interface MediaData {
  id: number;
  type: MediaType;
  mediaName: string;
  uploadedTimestamp: string;
}
