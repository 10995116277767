import { useCallback, useMemo } from 'react';
import { LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import { Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { useLoading } from '../../../../hooks/useLoading';
import { useCommands } from '../../../../helpers/useCommands';
import { useNavigate } from 'react-router-dom';
import { CreateLessonDraft } from '../../../../data/learning/commands/CreateLessonDraft';
import { formatQuestionHtml } from './quiz/QuizQuestionInput';
import { useTabContext } from '../../../../components/TabContext';

export interface LessonQuizProps {
  lesson: LessonData;
  disabled?: boolean;
  linked?: boolean;
}

const LessonSummaryQuiz: React.FC<LessonQuizProps> = ({ lesson, disabled, linked }) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const commands = useCommands();
  const { setActiveTab } = useTabContext();
  const quiz = lesson.quiz ?? {};
  const questions = useMemo(() => quiz.questions ?? [], [quiz.questions]);

  const viewQuiz = useCallback(() => navigate('quiz/edit'), [navigate]);

  const editQuiz = useCallback(async () => {
    try {
      setLoading(true);
      if (lesson.status === PublicationStatusEnum.PUBLISHED) {
        await commands.execute(CreateLessonDraft, lesson);
      }
      navigate('quiz/edit');
      setActiveTab(1);
    } finally {
      setLoading(false);
    }
  }, [commands, lesson, navigate, setActiveTab, setLoading]);

  return (
    <Card variant='ghost' color='primary'>
      <CardContent className='pad10'>
        <Grid
          display='flex'
          flexDirection='column'
          paddingLeft='30px'
          rowGap='10px'
          paddingBottom='10px'>
          <Grid display='flex' justifyContent='space-between'>
            <Typography variant='p24SemiBold' color='secondary' paddingY='10px'>
              Quiz
            </Typography>
            {!disabled && !linked && (
              <Link variant='p12' color='primary' onClick={editQuiz}>
                Edit
              </Link>
            )}
            {linked && (
              <Link variant='p12' color='primary' onClick={viewQuiz}>
                View
              </Link>
            )}
          </Grid>
          {questions.map((question, index) => (
            <Typography
              key={question.questionId}
              className='lesson-content-title'
              variant='p18SemiBold'
              color='secondary'
              display='flex'
              alignItems='start'
              columnGap='5px'>
              <div dangerouslySetInnerHTML={{ __html: formatQuestionHtml(`${index + 1}.`) }} />
              <div dangerouslySetInnerHTML={{ __html: question.text ?? '' }} />
            </Typography>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LessonSummaryQuiz;
