import { Typography } from '@mui/material';
import { Column, Spacer } from '../../../../components';
import Modal from '../../../../components/Modal';
import { LessonData } from '../../../../models/CourseData';

interface RemoveLessonDraftModalProps {
  lesson?: LessonData;
  removeLesson?: (data?: any) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveLessonDraftModal: React.FC<RemoveLessonDraftModalProps> = ({
  lesson,
  open,
  setOpen,
  removeLesson,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      handleSave={removeLesson}
      title='Delete draft?'
      primaryButtonText='Yes, Delete'
      secondaryButtonText='No, Keep'>
      <Column>
        <Typography variant='p14' color='secondary'>
          Are you sure you want to delete the changes made to {lesson?.name}?
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p14' color='secondary'>
          This action can't be undone.
        </Typography>
        <Spacer height='xs' />
      </Column>
    </Modal>
  );
};

export default RemoveLessonDraftModal;
