import { Typography } from '@mui/material';
import { Column, Spacer } from '../../../../components';
import Modal from '../../../../components/Modal';
import { LessonData } from '../../../../models/CourseData';

interface RemoveLessonModalProps {
  lesson?: LessonData;
  removeLesson?: (data?: any) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveLessonModal: React.FC<RemoveLessonModalProps> = ({
  lesson,
  open,
  setOpen,
  removeLesson,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      handleSave={removeLesson}
      title='Remove lesson?'
      primaryButtonText='Yes, Remove'
      secondaryButtonText='No, Keep'>
      <Column>
        <Typography variant='p14' color='secondary'>
          Are you sure you want to remove {lesson?.name}?
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p14' color='secondary'>
          This will remove any content or quizzes attached to this lesson.
        </Typography>
        <Spacer height='xs' />
      </Column>
    </Modal>
  );
};

export default RemoveLessonModal;
