import { useContext, useEffect, useMemo, useState } from 'react';
import { KeyValueLabel } from '../../../../components';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { Button, Grid, Typography } from '@mui/material';
import { useCommands } from '../../../../helpers/useCommands';
import { AddOrUpdateCourse } from '../../../../data/learning/commands/AddOrUpdateCourse';
import { MediaData, MediaType } from '../../../../models/MediaData';
import MediaEditView from '../media/MediaEditView';
import { Form } from 'react-final-form';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { FindCourseById } from '../../../../data/learning/queries/FindCourseById';
import { useNavigate, useParams } from 'react-router-dom';
import { PublishCourse } from '../../../../data/learning/commands/PublishCourse';
import { PublicationStatusEnum } from '../../../../models/CourseData';

export const courseIconKeyString = 'course/icon';
export const courseBackgroundKeyString = 'course/background';

const CourseEditView = () => {
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const navigate = useNavigate();
  const params = useParams();
  const { originalCourseId } = useMemo(
    () => ({
      originalCourseId: parseInt(params.courseId ?? ''),
    }),
    [params.courseId],
  );
  const course = useObservableQuery(FindCourseById, originalCourseId);
  const editing = useMemo(() => typeof course !== 'undefined', [course]);

  const [previewIcon, setPreviewIcon] = useState<MediaData | null>(null);
  const [previewBackground, setPreviewBackground] = useState<MediaData | null>(null);

  useEffect(() => {
    if (course) {
      setPreviewIcon(course.icon ?? null);
      setPreviewBackground(course.background ?? null);
    }
  }, [course]);

  const initialValues = useMemo(
    () => ({
      name: course?.name || '',
      icon: course?.icon,
      background: course?.background,
    }),
    [course],
  );

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = async (values: any) => {
    try {
      const updatedCourse = await commands.execute(AddOrUpdateCourse, {
        ...course,
        originalCourseId,
        name: values.name,
        icon: previewIcon,
        background: previewBackground,
      });

      if (course?.status === PublicationStatusEnum.PUBLISHED) {
        await commands.execute(PublishCourse, updatedCourse);
      }

      setToast(
        new Toast({
          message: originalCourseId ? 'Course updated!' : 'Course created!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      navigate(-1);
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <>
          <Grid container justifyContent='center' alignItems='center' height='70%'>
            <Grid height='100%' container flexDirection='column' xs={10} rowSpacing={4}>
              <Grid item justifyContent='center'>
                <Typography variant='p50Light' color='secondary.main'>
                  {editing ? 'Edit Course' : 'Create Course'}
                </Typography>
              </Grid>

              <Grid item justifyContent='flex-start' alignItems='flex-start'>
                <KeyValueLabel
                  keyString='Course name'
                  value={course?.name}
                  fieldName='name'
                  layout='stacked'
                  inputStyles={{ width: '20%' }}
                  editing
                  required
                  maxLength={100}
                />
              </Grid>

              <Grid item>
                <MediaEditView
                  variant='dark'
                  mediaType={MediaType.ICON}
                  initialMedia={course?.icon ?? null}
                  onMediaChange={setPreviewIcon}
                />
              </Grid>

              <Grid item>
                <MediaEditView
                  variant='dark'
                  mediaType={MediaType.BACKGROUND}
                  initialMedia={course?.background ?? null}
                  onMediaChange={setPreviewBackground}
                />
              </Grid>

              <Grid container flex={1} alignItems='flex-end' justifyContent='space-between'>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={onCancel}
                  sx={{ width: '250px' }}>
                  Cancel
                </Button>
                <Button color='primary' onClick={handleSubmit} sx={{ width: '250px' }}>
                  {editing ? 'Save course' : 'Create course'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default CourseEditView;
