import { IListener, injectDataCache, injectSubject } from '@aesop-fables/scrinium';
import type { DataCache } from '@aesop-fables/scrinium';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { Predicates } from '../../../predicates/Types';
import { AdminCompartments, adminStorageKey } from '..';

export class GroupAdminsListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.GroupSelected) private readonly predicate: Observable<boolean>,
    @injectDataCache(adminStorageKey) private readonly cache: DataCache<AdminCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('groupAdmins');
        } else {
          this.cache.reset('groupAdmins');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class TeamAdminsListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.TeamSelected) private readonly predicate: Observable<boolean>,
    @injectDataCache(adminStorageKey) private readonly cache: DataCache<AdminCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('teamAdmins');
        } else {
          this.cache.reset('teamAdmins');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}

export class OrgAdminsListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected) private readonly predicate: Observable<boolean>,
    @injectDataCache(adminStorageKey) private readonly cache: DataCache<AdminCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('orgAdmins');
        } else {
          this.cache.reset('orgAdmins');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}
