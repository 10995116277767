import { Card, CardContent, Grid, IconButton, Link, Typography } from '@mui/material';
import { LessonContent } from '../../../../../models/CourseData';
import { Svgs } from '../../../../../assets/svg';
import ContentReadonly from './ContentReadonly';
import DraggableItemProps from '../../../../../components/DraggableItemProps';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import {
  contentCopyKeyString,
  contentIconKeyString,
  contentTitleKeyString,
} from './ContentEditView';
import { useLoading } from '../../../../../hooks/useLoading';
import { ToastbarContext } from '../../../../../App';
import { useCommands } from '../../../../../helpers/useCommands';
import { Toast } from '../../../../../models/Toast';
import { RemoveLessonContent } from '../../../../../data/learning/commands/RemoveLessonContent';
import RemoveContentModal from './RemoveContentModal';
import ContentInputEditable from './ContentInputEditable';

export interface ContentInputProps extends DraggableItemProps {
  content: LessonContent;
  editing?: boolean;
  disabled?: boolean;
  disableDrag?: boolean;
  setEditing?: (contentId?: number) => void;
}

const ContentInput = forwardRef<HTMLElement, ContentInputProps>(
  (
    { content, disabled, editing, setEditing, disableDrag, dragProps, dragHandleProps, snapshot },
    ref,
  ) => {
    const { setToast } = useContext(ToastbarContext);
    const { setLoading } = useLoading();
    const commands = useCommands();
    const { setPreviewContent, getPreviewContentByKey, getPreviewMedia, setPreviewMedia } =
      useLivePreviewContext();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const displayContent = useMemo(() => {
      const contentId = content.contentId;

      return {
        title: getPreviewContentByKey(`${contentTitleKeyString}/${contentId}`),
        contentCopy: getPreviewContentByKey(`${contentCopyKeyString}/${contentId}`),
        icon: getPreviewMedia(`${contentIconKeyString}/${contentId}`),
      };
    }, [content.contentId, getPreviewContentByKey, getPreviewMedia]);

    useEffect(() => {
      if (content.contentId) {
        if (content.title) {
          setPreviewContent(`${contentTitleKeyString}/${content.contentId}`, content.title);
        }
        if (content.contentCopy) {
          setPreviewContent(`${contentCopyKeyString}/${content.contentId}`, content.contentCopy);
        }
        setPreviewMedia(`${contentIconKeyString}/${content.contentId}`, content.icon ?? null);
      }
    }, [
      content.contentCopy,
      content.contentId,
      content.title,
      content.icon,
      setPreviewContent,
      setPreviewMedia,
    ]);

    const removeContent = useCallback(async () => {
      setLoading(true);
      try {
        if (content) {
          await commands.execute(RemoveLessonContent, content);
          if (content.contentId) {
            setPreviewMedia(`${contentIconKeyString}/${content.contentId}`, null);
          }
          setToast(
            new Toast({
              message: 'Content removed!',
              severity: 'success',
              open: true,
            }),
          );
        }

        if (editing && setEditing) {
          setEditing(undefined);
        }
      } finally {
        setLoading(false);
      }
    }, [commands, content, editing, setEditing, setLoading, setToast, setPreviewMedia]);

    const removeContentWithGuard = useCallback(async () => {
      setIsVisible(true);
    }, []);

    if (disabled) return <ContentReadonly content={content} />;
    if (editing)
      return (
        <ContentInputEditable
          content={content}
          setEditing={setEditing}
          disableDrag={disableDrag}
          removeContent={removeContent}
          dragProps={dragProps}
          dragHandleProps={dragHandleProps}
        />
      );

    return (
      <Grid ref={ref as any} {...dragProps}>
        <RemoveContentModal open={isVisible} setOpen={setIsVisible} removeContent={removeContent} />
        <Card id={content.contentId?.toString()} variant='ghost' color='primary'>
          <CardContent className='pad20'>
            <Grid display='flex' flexDirection='column' rowGap='20px'>
              <Grid display='flex' justifyContent='space-between' alignItems='center'>
                <IconButton
                  {...dragHandleProps}
                  style={{ padding: '0px', ...(disableDrag ? { cursor: 'default' } : undefined) }}
                  disableRipple>
                  <Svgs.IconGrip
                    className={disableDrag ? 'grip-disabled' : 'grip'}
                    style={{ opacity: '100 !important' }}
                  />
                </IconButton>
                <Grid display='flex' columnGap='20px' alignItems='center' width='75%' container>
                  <Grid display='flex' minWidth={0}>
                    <Typography
                      className='lesson-content-title'
                      color='secondary'
                      variant='p18SemiBold'
                      flexGrow={1}
                      whiteSpace='nowrap'
                      overflow='hidden'
                      textOverflow='ellipsis'
                      sx={{
                        '& *': {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: displayContent.title ?? '' }} />
                    </Typography>
                  </Grid>
                </Grid>

                {!disabled && (
                  <Grid display='flex' columnGap='20px' alignItems='center'>
                    <Link
                      variant='p12'
                      color='primary'
                      onClick={() => {
                        if (setEditing) {
                          setEditing(content.contentId);
                        }
                      }}>
                      Edit
                    </Link>
                    <IconButton style={{ padding: '0px' }} onClick={removeContentWithGuard}>
                      <Svgs.IconDelete />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              {typeof displayContent.icon !== 'undefined' && displayContent.icon !== null && (
                <Grid display='flex' alignItems='center' columnGap='10px'>
                  <Svgs.IconPicture />
                  <Typography variant='p16Light'>{displayContent.icon.mediaName}</Typography>
                </Grid>
              )}
              <Typography className='lesson-content-copy' variant='p16Light' color='secondary'>
                <div dangerouslySetInnerHTML={{ __html: displayContent.contentCopy ?? '' }} />
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  },
);

export default ContentInput;
