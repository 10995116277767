import { Typography } from '@mui/material';
import { Column, Modal, Spacer } from '../../../../../components';

interface RemoveContentModalProps {
  removeContent?: (data?: any) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveContentModal: React.FC<RemoveContentModalProps> = ({
  open,
  setOpen,
  removeContent,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      handleSave={removeContent}
      title='Remove content?'
      primaryButtonText='Yes, Remove'
      secondaryButtonText='No, Keep'>
      <Column>
        <Typography variant='p14' color='secondary'>
          Are you sure you want to remove this item?
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p14' color='secondary'>
          This action can't be undone.
        </Typography>
        <Spacer height='xs' />
      </Column>
    </Modal>
  );
};

export default RemoveContentModal;
