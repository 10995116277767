import {
  DataCache,
  IDataCommand,
  appendCommandMiddleware,
  injectDataCache,
} from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData } from '../../../models/CourseData';
import { CourseApi } from '../../../api/apis/CourseApi';
import { LearningServices } from '../LearningServiceModule';

@appendCommandMiddleware(LearningServices.ReloadCourses)
export class UnpublishCourse implements IDataCommand<CourseData, CourseData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Course) private readonly api: CourseApi,
  ) {}

  async execute(course: CourseData): Promise<CourseData> {
    let response: CourseData | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (course.courseId) {
        const newCourses = [...courses];
        const courseIndex = newCourses.findIndex(x => x.courseId === course.courseId);
        if (courseIndex !== -1) {
          const course = newCourses[courseIndex];
          const units = course.units;
          // returns the original course with active: false. status is still published
          // but a new draft is created. Must reload courses to get the new draft
          const { data: updatedCourse } = await this.api.unpublishCourse(course);
          updatedCourse.units = units;
          newCourses[courseIndex] = updatedCourse;
        }

        return newCourses;
      }
      return courses;
    });

    return response as CourseData;
  }
}
