import { Card, CardContent, Grid, IconButton, Link, Typography } from '@mui/material';
import { QuizQuestion } from '../../../../../models/CourseData';
import { Svgs } from '../../../../../assets/svg';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import { useLoading } from '../../../../../hooks/useLoading';
import { ToastbarContext } from '../../../../../App';
import { useCommands } from '../../../../../helpers/useCommands';
import { Toast } from '../../../../../models/Toast';
import { quizQuestionKeyString } from './QuizEditView';
import QuizInputEditable from './QuizInputEditable';
import { RemoveQuizQuestion } from '../../../../../data/learning/commands/RemoveQuizQuestion';
import RemoveQuestionModal from './RemoveQuestionModal';
import { formatAnswerHtml } from './QuizAnswerInput';

export interface QuizInputProps {
  index: number;
  question: QuizQuestion;
  lessonId?: number;
  editing?: boolean;
  disabled?: boolean;
  setEditing?: (contentId?: number) => void;
}

const QuizInput: React.FC<QuizInputProps> = ({
  index,
  question,
  lessonId,
  disabled,
  editing,
  setEditing,
}) => {
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const { setPreviewContent } = useLivePreviewContext();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (question.questionId && question.text) {
      setPreviewContent(`${quizQuestionKeyString}/${question.questionId}`, question.text);
    }
  }, [question.questionId, question.text, setPreviewContent]);

  const removeQuestion = useCallback(async () => {
    setLoading(true);
    try {
      if (question) {
        await commands.execute(RemoveQuizQuestion, question);
        setToast(
          new Toast({
            message: 'Question removed!',
            severity: 'success',
            open: true,
          }),
        );
      }

      if (editing && setEditing) {
        setEditing(undefined);
      }
    } finally {
      setLoading(false);
    }
  }, [commands, editing, question, setEditing, setLoading, setToast]);

  const removeQuestionWithGuard = useCallback(async () => {
    setIsVisible(true);
  }, []);

  // if (disabled) return <ContentInputReadonly content={content} />;
  if (editing)
    return (
      <QuizInputEditable
        index={index}
        question={question}
        lessonId={lessonId}
        setEditing={setEditing}
        removeQuestion={removeQuestion}
      />
    );

  return (
    <Grid>
      <RemoveQuestionModal
        open={isVisible}
        setOpen={setIsVisible}
        removeQuestion={removeQuestion}
      />
      <Card id={question.questionId?.toString()} variant='ghost' color='primary'>
        <CardContent className='pad20'>
          <Grid display='flex' flexDirection='column' rowGap='20px'>
            <Grid
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              columnGap='20px'>
              <Grid display='flex' minWidth={0}>
                <Typography
                  className='lesson-content-title'
                  color='secondary'
                  variant='p18SemiBold'
                  flexGrow={1}
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  sx={{
                    '& *': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}>
                  <div dangerouslySetInnerHTML={{ __html: question.text ?? '' }} />
                </Typography>
              </Grid>
              {!disabled && (
                <Grid display='flex' columnGap='20px' alignItems='center'>
                  <Link
                    variant='p12'
                    color='primary'
                    onClick={() => {
                      if (setEditing) {
                        setEditing(question.questionId);
                      }
                    }}>
                    Edit
                  </Link>
                  <IconButton style={{ padding: '0px' }} onClick={removeQuestionWithGuard}>
                    <Svgs.IconDelete />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid display='flex' flexDirection='column' rowGap='10px'>
              {question.answers?.map((answer, index) => {
                return (
                  <Grid display='flex' columnGap='10px' key={answer.answerId}>
                    <Svgs.IconCheckmark style={!answer.isCorrect ? { opacity: 0 } : {}} />
                    <Typography
                      className='lesson-content-copy'
                      variant='p16Light'
                      color='secondary'>
                      <div
                        style={{ display: 'flex', columnGap: '5px', alignItems: 'start' }}
                        dangerouslySetInnerHTML={{
                          __html: `${formatAnswerHtml(
                            String.fromCharCode(97 + index).toUpperCase() + '.',
                          )}${answer.text}`,
                        }}
                      />
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuizInput;
