import React, { ReactNode } from 'react';

interface BoldProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

export const Bold: React.FC<BoldProps> = ({ children, style }) => (
  <span style={{ fontWeight: 'bold', ...style }}>{children}</span>
);
