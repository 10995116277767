import React from 'react';
import { useTheme } from '@mui/material';

interface StatusDotRedProps {
  style?: React.CSSProperties;
  visible?: boolean | undefined;
}

export const StatusDotRed: React.FC<StatusDotRedProps> = ({ visible = true, style }) => {
  const theme = useTheme();

  return (
    <div
      className={`status-dot-red ${visible ? 'visible' : ''}`}
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: theme.palette.error.dark,
        transform: 'translateY(-8px)',
        opacity: visible ? 1 : 0,
        ...style,
      }}
    />
  );
};
