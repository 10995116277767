import { Card, Grid, Typography } from '@mui/material';
import { CourseData, LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import { Svgs } from '../../../../assets/svg';
import { Apps as UnitsIcon } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { SemiBold } from '../../../../components/SemiBold';
import CourseMenu from './CourseMenu';
import { StatusDotRed } from '../../../../components/StatusDotRed';
import { Spacer } from '../../../../components';
import { filterLessons } from '../../../../data/learning/commands/utils';

export interface CourseItemProps {
  course: CourseData;
}

const CourseItem: React.FC<CourseItemProps> = ({ course }) => {
  const navigate = useNavigate();
  const isPublished = course.status === PublicationStatusEnum.PUBLISHED;

  const uniqueLessons = useMemo(
    () =>
      course.units?.reduce((lessons, unit) => {
        const filtered = filterLessons(unit.lessons ?? []);
        return [...lessons, ...filtered];
      }, [] as LessonData[]),
    [course.units],
  );

  const hasUnpublishedUnits = useMemo(
    () => course.units?.some(unit => unit.status === PublicationStatusEnum.DRAFT),
    [course.units],
  );

  const hasUnpublishedLessons = useMemo(
    () =>
      course.units?.some(
        unit => unit.lessons?.some(lesson => lesson.status === PublicationStatusEnum.DRAFT),
      ),
    [course.units],
  );

  return (
    <Card
      className='course-item-card'
      variant='ghost'
      color='secondary'
      onClick={() => navigate(`${course.originalCourseId}`)}>
      <Grid container>
        <Grid
          item
          sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: '10px', minWidth: 0 }}>
          <Svgs.IconBook style={{ flexShrink: 0 }} />
          <Typography
            className='course-name'
            variant='p18Bold'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: 0,
            }}>
            {course.name}
          </Typography>
          <Grid item sx={{ flexShrink: 1, alignItems: 'center', display: 'flex' }}>
            {isPublished ? (
              <Svgs.IconCheckmarkGrey style={{ flexShrink: 0, marginRight: '10px' }} />
            ) : (
              <Spacer width='xxl' />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            display: 'flex',
            flexShrink: 0,
            justifyContent: 'flex-end',
            minWidth: 0,
            columnGap: '30px',
          }}>
          <Grid
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '20px' }}>
            {[
              {
                label: 'Units',
                icon: UnitsIcon,
                count: course.units?.length ?? 0,
                hasUnpublished: hasUnpublishedUnits,
              },
              {
                label: 'Lessons',
                icon: Svgs.IconLightbulb,
                count: uniqueLessons?.length ?? 0,
                hasUnpublished: hasUnpublishedLessons,
              },
            ].map(({ label, icon: Icon, count, hasUnpublished }) => (
              <Grid
                item
                key={label}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '5px',
                  height: '100%',
                  width: '100%',
                  flexGrow: 1,
                }}>
                <Typography variant='p12' color='secondary'>
                  {label}
                </Typography>
                <Grid sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <Icon fontSize='large' style={{ flexShrink: 0 }} />
                  <Typography className='course-name' variant='p20SemiBold' color='secondary'>
                    {count}
                  </Typography>
                  <StatusDotRed visible={hasUnpublished} />
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexShrink: 0,
              flexBasis: '210px',
            }}>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '10px',
                minWidth: 0,
              }}>
              <Typography
                variant='p16'
                color='secondary'
                sx={{
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {isPublished ? (
                  <>
                    <SemiBold>Published</SemiBold> on{' '}
                    {dayjs(course?.publishedTimestamp).format('MM/DD/YY')}
                  </>
                ) : (
                  'Unpublished'
                )}
              </Typography>
              <Grid display='flex' onClick={e => e.stopPropagation()}>
                <CourseMenu course={course} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CourseItem;
