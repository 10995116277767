import { DataCache, Predicate, injectDataCache, predicate } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { Predicates } from './Types';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../data/groupFeatureMap';

@predicate(Predicates.UserInitialized)
export class GroupFeatureMapInitialized implements Predicate {
  constructor(
    @injectDataCache(groupFeatureMapStorageKey)
    private readonly groupFeatureMapDataCache: DataCache<GroupFeatureMapCompartments>,
  ) {}

  createObservable(): Observable<boolean> {
    return this.groupFeatureMapDataCache.findCompartment('groupFeatureMap').initialized$;
  }
}
