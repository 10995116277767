import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, QuizData, UnitData } from '../../../models/CourseData';
import { findQuizSource, updateCourses } from './utils';
import { QuizApi } from '../../../api/apis/QuizApi';

export class AddOrUpdateLessonQuiz implements IDataCommand<QuizData, QuizData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Quiz) private readonly api: QuizApi,
  ) {}

  async execute(quiz: QuizData): Promise<QuizData> {
    let response: QuizData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex, lessonIndex } = findQuizSource(quiz, courses);
      if (
        typeof courseIndex !== 'undefined' &&
        typeof unitIndex !== 'undefined' &&
        typeof lessonIndex !== 'undefined'
      ) {
        const newUnits = [...(courses[courseIndex].units as UnitData[])];
        const newUnit = newUnits[unitIndex];
        if (newUnit.lessons) {
          const newLessons = [...newUnit.lessons];
          const newLesson = newLessons[lessonIndex];
          if (quiz.quizId) {
            const { data: updatedQuiz } = await this.api.updateQuiz(quiz);
            response = updatedQuiz;
            // do I need to set the questions or are they returned in the response???
            newLesson.quiz = updatedQuiz;

            const newCourses = updateCourses(newUnit, courses);

            return newCourses;
          }

          const { data } = await this.api.createQuiz(quiz);
          response = data;
          newLesson.quiz = data;

          const newCourses = updateCourses(newUnit, courses);

          return newCourses;
        }
      }
      return courses;
    });

    return response as QuizData;
  }
}
