import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { Svgs } from '../../../../../assets/svg';
import React, { useCallback, useContext, useState } from 'react';
import { useLivePreviewContext } from '../../../../../components/LivePreviewContext';
import { useLoading } from '../../../../../hooks/useLoading';
import { useCommands } from '../../../../../helpers/useCommands';
import { QuizInputProps } from './QuizInput';
import { quizQuestionKeyString } from './QuizEditView';
import QuizQuestionInput from './QuizQuestionInput';
import { AddOrUpdateQuizQuestion } from '../../../../../data/learning/commands/AddOrUpdateQuizQuestion';
import RemoveQuestionModal from './RemoveQuestionModal';
import QuizAnswerInput from './QuizAnswerInput';
import { QuizAnswer } from '../../../../../models/CourseData';
import { useQuizQuestionContext } from './QuizQuestionContext';
import { ToastbarContext } from '../../../../../App';
import { Toast } from '../../../../../models/Toast';

interface QuizInputEditableProps extends QuizInputProps {
  removeQuestion: () => Promise<void>;
}

const QuizInputEditable: React.FC<QuizInputEditableProps> = ({
  index,
  question,
  lessonId,
  setEditing,
  removeQuestion,
}) => {
  const theme = useTheme();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const { getPreviewContentByKey, setPreviewContent, getPreviewInputError, setPreviewInputError } =
    useLivePreviewContext();
  const {
    activeAnswerKeys,
    correctAnswerKeys,
    addAnswer,
    allOfTheAboveKey,
    addAllOfTheAbove,
    noneOfTheAboveKey,
    addNoneOfTheAbove,
    resetAnswers,
  } = useQuizQuestionContext();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onCancel = useCallback(async () => {
    setPreviewContent(`${quizQuestionKeyString}/${question.questionId}`, question.text ?? '');
    resetAnswers();
    if (setEditing) {
      setEditing(undefined);
    }
  }, [question.questionId, question.text, resetAnswers, setEditing, setPreviewContent]);

  const removeQuestionWithGuard = useCallback(async () => {
    const previewQuestion = getPreviewContentByKey(
      `${quizQuestionKeyString}/${question.questionId}`,
    );
    if ((previewQuestion?.length ?? 0) === 0 && !question.questionId) {
      await onCancel();
    } else {
      setIsVisible(true);
    }
  }, [getPreviewContentByKey, onCancel, question.questionId]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      let missingInput = false;
      const questionError = getPreviewInputError(`${quizQuestionKeyString}/${question.questionId}`);
      const previewQuestion = getPreviewContentByKey(
        `${quizQuestionKeyString}/${question.questionId}`,
      );

      if (questionError) {
        missingInput = true;
      } else if ((previewQuestion?.length ?? 0) === 0) {
        setPreviewInputError(`${quizQuestionKeyString}/${question.questionId}`, 'Required');
        missingInput = true;
      }

      // validate answers
      activeAnswerKeys.forEach(key => {
        const answer = getPreviewContentByKey(key);
        const error = getPreviewInputError(key);
        if (error) {
          missingInput = true;
        } else if (typeof answer === 'undefined' || answer.length === 0) {
          missingInput = true;
          setPreviewInputError(key, 'Required');
        }
      });

      if (missingInput) return;
      if (correctAnswerKeys.size === 0) {
        setToast(
          new Toast({
            severity: 'error',
            open: true,
            message: 'At least one answer must be correct',
          }),
        );
        return;
      }

      const answers = [...activeAnswerKeys].map(key => {
        const text = getPreviewContentByKey(key);
        const isCorrect = correctAnswerKeys.has(key);
        const anchorBottomPosition =
          key === allOfTheAboveKey ? 1 : key === noneOfTheAboveKey ? 2 : undefined;
        if (typeof text === 'undefined' || text.length === 0) {
          setPreviewInputError(key, 'Required');
          missingInput = true;
        }
        const previewAnswer: QuizAnswer = {
          questionId: question.questionId,
          text,
          isCorrect,
          anchorBottomPosition,
        };
        return previewAnswer;
      });

      await commands.execute(AddOrUpdateQuizQuestion, {
        question: { ...question, text: previewQuestion, answers },
        lesson: { lessonId },
      });

      if (setEditing) {
        setEditing(undefined);
      }
    } catch (error) {
      console.log('error');
    } finally {
      setLoading(false);
    }
  }, [
    activeAnswerKeys,
    allOfTheAboveKey,
    commands,
    correctAnswerKeys,
    getPreviewContentByKey,
    getPreviewInputError,
    lessonId,
    noneOfTheAboveKey,
    question,
    setEditing,
    setLoading,
    setPreviewInputError,
    setToast,
  ]);

  return (
    <Grid>
      <RemoveQuestionModal
        open={isVisible}
        setOpen={setIsVisible}
        removeQuestion={question.questionId ? removeQuestion : onCancel}
      />
      <Card id={question.questionId?.toString()} variant='ghost' color='primary'>
        <CardContent className='pad20'>
          <Grid display='flex' flexDirection='column' rowGap='20px'>
            <Grid display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='p18SemiBold' color='secondary'>
                Question {index + 1}
              </Typography>
              <IconButton style={{ padding: '0px' }} onClick={removeQuestionWithGuard}>
                <Svgs.IconDelete />
              </IconButton>
            </Grid>
            <QuizQuestionInput
              editorKey={`${quizQuestionKeyString}/${question.questionId}`}
              content={question.text}
            />
            {[...activeAnswerKeys].map(x => (
              <QuizAnswerInput
                key={x}
                editorKey={x}
                content={getPreviewContentByKey(x)}
                questionId={question.questionId}
              />
            ))}
            <Grid
              onClick={() => addAnswer()}
              display='flex'
              columnGap='10px'
              sx={{ '&:hover': { cursor: 'pointer' } }}>
              <Svgs.IconAdd fill={theme.palette.secondary.hover} />
              <Link variant='p16SemiBold'>Add answer</Link>
            </Grid>
            {typeof addAllOfTheAbove !== 'undefined' && (
              <Grid
                onClick={() => addAllOfTheAbove()}
                display='flex'
                columnGap='10px'
                sx={{ '&:hover': { cursor: 'pointer' } }}>
                <Svgs.IconAdd fill={theme.palette.secondary.hover} />
                <Link variant='p16SemiBold'>Add "All of the above"</Link>
              </Grid>
            )}
            {typeof addNoneOfTheAbove !== 'undefined' && (
              <Grid
                onClick={() => addNoneOfTheAbove()}
                display='flex'
                columnGap='10px'
                sx={{ '&:hover': { cursor: 'pointer' } }}>
                <Svgs.IconAdd fill={theme.palette.secondary.hover} />
                <Link variant='p16SemiBold'>Add "None of the above"</Link>
              </Grid>
            )}
            <Grid display='flex' justifyContent='flex-end' flexGrow={1} columnGap='10px'>
              <Button
                color='secondary'
                variant='outlined'
                onClick={setEditing ? onCancel : undefined}>
                Cancel
              </Button>
              <Button color='primary' onClick={onSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuizInputEditable;
