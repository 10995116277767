import { inject } from '@aesop-fables/containr';
import { ISubject, DataCache, ScriniumServices } from '@aesop-fables/scrinium';
import type { IAppStorage } from '@aesop-fables/scrinium';
import { Observable } from 'rxjs';
import { LearningCompartments, learningStorageKey } from '..';
import { CategoryData } from '../../../models/CourseData';

export class LessonCategoriesSubject implements ISubject<CategoryData[]> {
  private readonly cache: DataCache<LearningCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly storage: IAppStorage) {
    this.cache = this.storage.retrieve<LearningCompartments>(learningStorageKey);
  }

  createObservable(): Observable<CategoryData[]> {
    return this.cache.observe$<CategoryData[]>('lessonCategories');
  }
}
