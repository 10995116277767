import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, UnitData } from '../../../models/CourseData';
import { UnitApi } from '../../../api/apis/UnitApi';

// update positions when removing
export class RemoveUnit implements IDataCommand<UnitData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Unit) private readonly api: UnitApi,
  ) {}

  async execute(unit: UnitData): Promise<void> {
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const newCourses = [...courses];
      if (unit.unitId) {
        await this.api.deleteUnit(unit.unitId);

        return newCourses.map(course => {
          course.units = course.units
            ?.filter(u => u.unitId !== unit.unitId)
            .map((u, i) => ({ ...u, position: i + 1 }));
          return course;
        });
      }

      return newCourses;
    });
  }
}
