import { DataCache, IDataCommand, ScriniumServices, injectDataCache } from '@aesop-fables/scrinium';
import type { ICommandExecutor } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import {
  AnswerTypeEnum,
  CourseData,
  LessonData,
  QuizData,
  QuizQuestion,
  UnitData,
} from '../../../models/CourseData';
import { findQuizQuestionSource, updateCourses } from './utils';
import { AddOrUpdateLessonQuiz } from './AddOrUpdateLessonQuiz';
import { QuizQuestionApi } from '../../../api/apis/QuizQuestionApi';

interface AddOrUpdateQuizQuestionParams {
  question: QuizQuestion;
  lesson: LessonData;
}

// batch submit for answers?
export class AddOrUpdateQuizQuestion
  implements IDataCommand<AddOrUpdateQuizQuestionParams, QuizQuestion>
{
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.QuizQuestion) private readonly api: QuizQuestionApi,
    @inject(ScriniumServices.CommandExecutor) private readonly commands: ICommandExecutor,
  ) {}

  async execute({ question, lesson }: AddOrUpdateQuizQuestionParams): Promise<QuizQuestion> {
    let response: QuizQuestion | undefined;

    if (typeof question.quizId === 'undefined') {
      const quiz = await this.commands.execute(AddOrUpdateLessonQuiz, {
        lessonId: lesson.lessonId,
      });
      question.quizId = quiz.quizId;
    }

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex, lessonIndex } = findQuizQuestionSource(question, courses);
      if (
        typeof courseIndex !== 'undefined' &&
        typeof unitIndex !== 'undefined' &&
        typeof lessonIndex !== 'undefined'
      ) {
        const newUnits = [...(courses[courseIndex].units as UnitData[])];
        const newUnit = newUnits[unitIndex];
        if (newUnit.lessons) {
          const newLessons = [...newUnit.lessons];
          const newLesson = newLessons[lessonIndex];
          const newQuiz = newLesson.quiz as QuizData;
          if (question.answers) {
            const answerType =
              question.answers.filter(x => x.isCorrect).length > 1
                ? AnswerTypeEnum.MULTIPLE
                : AnswerTypeEnum.SINGLE;
            if (question.questionId) {
              const { data: updatedQuestion } = await this.api.updateQuestion({
                ...question,
                answerType,
              });
              response = updatedQuestion;
              const questionIndex = newQuiz.questions?.findIndex(
                x => x.questionId === updatedQuestion.questionId,
              );
              if (
                newQuiz.questions &&
                typeof questionIndex !== 'undefined' &&
                questionIndex !== -1
              ) {
                newQuiz.questions[questionIndex] = updatedQuestion;
              }

              const newCourses = updateCourses(newUnit, courses);

              return newCourses;
            }

            const { data } = await this.api.createQuestion({ ...question, answerType });
            response = data;
            const newQuestions = [...(newQuiz?.questions ?? []), data];
            newQuiz.questions = newQuestions;

            const newCourses = updateCourses(newUnit, courses);

            return newCourses;
          }
        }
      }
      return courses;
    });

    return response as QuizQuestion;
  }
}
