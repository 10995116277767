import { StarterKitOptions } from '@tiptap/starter-kit';
import LivePreviewEditor, {
  LivePreviewEditorProps,
} from '../../../../../components/LivePreviewEditor';

const formatHtml = (html: string) => {
  const formattedHtml = `<div style="font-size: 22px; font-family: 'Roboto'; font-weight: bold; color: #1b7690; overflow-wrap: anywhere;">${html}</div>`;
  return formattedHtml;
};

const starterKitOptions: Partial<StarterKitOptions> = { bold: false };

const ContentTitleInput: React.FC<LivePreviewEditorProps> = ({ editorKey, content }) => {
  return (
    <LivePreviewEditor
      editorKey={editorKey}
      label='Content title'
      content={content}
      placeholder='Add a title'
      formatHtml={formatHtml}
      starterKitOptions={starterKitOptions}
      characterLimit={150}
    />
  );
};

export default ContentTitleInput;
