import { useState } from 'react';
import { LessonData } from '../../../../models/CourseData';
import { Card, CardContent, Grid, Link, Typography } from '@mui/material';
import LessonEditDrawer from './LessonEditDrawer';

export interface LessonSummaryNameCardProps {
  lesson?: LessonData;
  disabled?: boolean;
}

const LessonSummaryNameCard: React.FC<LessonSummaryNameCardProps> = ({ lesson, disabled }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Card variant='ghost' color='primary'>
      <CardContent className='pad10'>
        <LessonEditDrawer
          originalUnitId={lesson?.unitId ?? 0}
          lessonData={lesson}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
        <Grid display='flex' justifyContent='space-between' columnGap='20px'>
          <Grid display='flex' columnGap='20px' ml='40px' my='10px' minWidth={0}>
            <Typography>Lesson name</Typography>
            <Typography flexGrow={1} whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
              {lesson?.name}
            </Typography>
          </Grid>
          {!disabled && (
            <Link variant='p12' color='primary' onClick={() => setDrawerOpen(true)}>
              Edit
            </Link>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LessonSummaryNameCard;
