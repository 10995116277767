import { inject } from '@aesop-fables/containr';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { ApiServices } from '../ApiServices';
import { MediaData, MediaType } from '../../models/MediaData';

export class MediaApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  getAll(): Promise<AxiosResponse<MediaData[]>> {
    return this.axios.get('/media');
  }

  getById(id: number): Promise<AxiosResponse<MediaData>> {
    return this.axios.get(`/media/${id}`);
  }

  upload(data: FormData, mediaType: MediaType): Promise<AxiosResponse<MediaData>> {
    return this.axios.post(`media?mediaType=${mediaType}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
